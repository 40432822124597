import { useEffect, useState } from "react";
import APIRequest from "../../helpers/CreateRequest";
import CRUD from "../../templates/CRUD";
import { FieldValues } from "react-hook-form";
import GetObj from "../../helpers/GetObj";
import { field, mode } from "../../Types/FormTypes";
import Button from "../../components/atoms/Button";
import { useLocation } from "react-router-dom";

const name = {
    normal: 'Business Plan Concept',
    plural: 'Business Plan Concepts',
    alias: 'Concept Plan',
    aliasPlural: 'Concept Plans',
    data: 'BusinessPlanConcept',
    dataPlural: 'BusinessPlanConcepts',
    sectionTitle: 'Real Estate'
}

const fields: field[] = [
    { name: 'BusinessPlanConceptName', alias: 'Name', sendAs: 'Name', type: 'text', required: true, size: 'md' },
    { name: 'Description', type: 'text', size: 'md', },
    { name: 'Code', type: 'text', required: true, size: 'md' },
    { name: 'BusinessUnitID', type: 'select', SelectElement: { name: 'Business Unit', namePlural: 'Business Units' }, required: true, size: 'md', alias: 'Business unit' },
    { name: 'DivisionID', type: 'select', SelectElement: { name: 'Division', namePlural: 'Divisions' }, required: true, size: 'md', alias: 'Division' },
    { name: 'DevelopmentID', type: 'select', SelectElement: { name: 'Development', namePlural: 'Developments' }, required: true, size: 'md', alias: 'Development' },
    { name: 'UnitTotal', type: 'int', size: 'sm', alias: 'Unit total', required: true },
    { name: 'EstimatedHomeBuildingRevenue', alias: 'Revenue', supportingText: 'Estimated home building revenue', type: 'currency', size: 'sm', required: true },
    { name: 'EstimatedHomeBuildingCosts', alias: 'Cost', supportingText: 'Estimated home building costs', type: 'currency', size: 'sm', required: true },
    { name: 'EstimatedNextExpectedSaleDate', alias: 'Sale Date', supportingText: 'Estimated next expected sale date', type: 'date', size: 'md', required: true },
    { name: 'EstimatedSaleGoalPerMonth', alias: 'Sale Goal', supportingText: 'Estimated sale goal per month', type: 'int', size: 'sm', required: true },
    { name: 'EstimatedDaysSaleToStart', alias: 'Days-to-start', supportingText: 'Estimated days sale-to-start', type: 'int', size: 'sm', required: true },
    { name: 'EstimatedDaysStartToSettlement', alias: 'Start-to-settlement', supportingText: 'Estimated days start-to-settlement', type: 'int', size: 'sm', required: true },
]


export default function Concepts2() {
    const location = useLocation()
    const stateID = location.state?.id || undefined
    const [obj, setObj] = useState<any>()
    const [objID, setObjID] = useState(stateID)
    const [mode, setMode] = useState<mode>('view')
    const [refresh, setRefresh] = useState(false)

    const emptySet: FieldValues = Object.fromEntries(fields.map(field => [field.sendAs || field.name, null]));


    //Get object when the id changes or a refresh is called 
    useEffect(() => {
        const getObj = async () => {
            if (objID) {
                const obj = await GetObj(objID, name.dataPlural, fields)
                if (obj !== -1) {
                    setObj(obj)
                    setMode('view')
                }
            }
        }
        getObj()
    }, [objID, refresh])


    return (
        <>
            <div className="py-10 px-10 h-full overflow-y-auto">

                <CRUD
                    name={name}
                    fields={fields}
                    obj={obj}
                    id={objID}
                    setID={setObjID}
                    mode={mode}
                    setMode={setMode}
                    emptySet={emptySet}
                    refresh={refresh}
                    setRefresh={setRefresh}

                    extraButtons={[
                        // <DuplicateButton key='BusinessPlanConceptsDuplicateButton' objID={objID} setObjID={setObjID} obj={obj} />
                    ]}
                />

                {/* Details or sections */}
                <div className="flex-grow w-full m-auto"></div>
            </div>
        </>
    )
}