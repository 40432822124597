import { UseFormRegisterReturn, UseFormSetValue, UseFormWatch } from "react-hook-form";
import NumberField from "../../components/atoms/forms/Fields/NumberField";
import { useEffect } from "react";

export type UnitTotalType = {
    name: string,
    label: string,
    supportingText?: string,
    disabled?: boolean,
    required?: boolean,
    value: string | undefined,
    formRef: UseFormRegisterReturn<string>
    setValue: UseFormSetValue<any>
    watch: UseFormWatch<any>
}

export default function UnitTotal(props: UnitTotalType) {
    const watchAttachedUnitTotal = props.watch('AttachedUnitTotal')
    const watchDetchedUnitTotal = props.watch('DetachedUnitTotal')

    useEffect(() => {
        const attached = Number(watchAttachedUnitTotal) || 0
        const detatched = Number(watchDetchedUnitTotal) || 0
        const combinedUnitTotal = attached + detatched
        props.setValue(props.name, combinedUnitTotal || undefined)
    }, [watchAttachedUnitTotal, watchDetchedUnitTotal])


    return (
        <NumberField {...props} readonly={true} />
    )
}