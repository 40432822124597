import { Currency, CurrencyToNumber } from "../../../../helpers/InputValueConverter";
import { useEffect, useState } from "react";
import BaseField, { BaseFieldProps } from "./BaseField";

export default function CurrencyField(props: BaseFieldProps) {
    // const [tempValue, setTempValue] = useState<string>(Currency(props.value || '') || '')

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // setTempValue(e.target.value)
        if (props.onChange) props.onChange(e)
    }

    const handleFocus = () => {
        // if (!tempValue) setTempValue('$')
        if(!props.value) props.setValue((prev: any) => ({...prev, [props.name]: '$'}))
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        const changedValue = e.currentTarget.value === '$' ? '' : e.currentTarget.value
        // setTempValue(Currency(changedValue))
        props.setValue((prev: any) => ({...prev, [props.name]: Currency(changedValue)}))
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const EXCEPTION = e.ctrlKey || e.altKey || e.metaKey || e.key === 'Backspace' || e.key === 'Delete' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Tab' || (e.key === '.' && !e.currentTarget.value.includes('.'))

        //only digits and the exceptions above will work
        if (!/^\d$/.test(e.key) && (!EXCEPTION || e.key === ' ')) {
            e.preventDefault()
        }
    }

    // useEffect(() => setTempValue(Currency(props.value || '')), [props.value])

    return (
        <BaseField  {...props} value={props.value} type="currency" onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} onKeydown={handleKeyDown} />
    );
}
