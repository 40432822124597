import { useEffect, useState } from "react";
import APIRequest from "../../helpers/CreateRequest";
import SelectField, { SelectFieldProps } from "../../components/atoms/forms/SimpleFields/SelectField";
import { Currency } from "../../helpers/InputValueConverter";
import { mode } from "../../Types/FormTypes";
import { DERIVATIVEFORM } from "./SalesForecasts";

interface ModelElevationProps extends SelectFieldProps {
    mode: mode,
    formType: string
}

export default function ModelElevation(props: ModelElevationProps) {
    const [firstCall, setFirstCall] = useState(true)

    const getModelElevationData = async () => {
        try {
            const result = await new APIRequest('/modelElevations/unit', 'POST', null, { id: props.value }).GenerateRequest()
            if (result.status === 200) {
                const body = await result.json()
                if (body.status === 200) {
                    const recordset = body.recordset[0]
                    let values = {}

                    // if edit and first call, and derivative, only set readonly values (not total indirect either) the user could not have changed. ELSE set all values
                    if (props.mode === 'edit' && firstCall && props.formType === DERIVATIVEFORM) {
                        setFirstCall(false)
                        values = {
                            BaseDirectCost: Currency(recordset.BaseDirectCost),
                            IncludedOptionCosts: Currency(recordset.IncludedOptionsDirectCost),
                            SquareFootageFinancial: recordset.SquareFootageFinancial
                        }
                    } else {
                        values = {
                            BaseDirectCost: Currency(recordset.BaseDirectCost),
                            BasePrice: Currency(recordset.BaseSalePrice),
                            IncludedOptionCosts: Currency(recordset.IncludedOptionsDirectCost),
                            TransferStampCost: Currency(recordset.TransferStampCost),
                            SellingCommissionCost: Currency(recordset.SellingCommissionCost),
                            InternalCommissionCost: Currency(recordset.InternalCommissionCost),
                            ListingCommissionCost: Currency(recordset.ListingCommissionCost),
                            FinanceCost: Currency(recordset.FinanceCost),
                            IndirectOtherCosts: Currency(recordset.IndirectOtherCosts),
                            IndirectCostsTotal: Currency(recordset.IndirectCosts),
                            LotAcquisitionCost: Currency(recordset.LotAcquisitionCost),
                            LotAcquisitionTransactionCost: Currency(recordset.LotAcquisitionTransactionCost),
                            SquareFootageFinancial: recordset.SquareFootageFinancial
                        }
                    }

                    props.setValue((prev: any) => ({ ...prev, ...values }))
                } else throw body.message
            } else throw result.statusText

        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        if (props.value) getModelElevationData()
    }, [props.value])

    return (
        <SelectField {...props} value={props.value || ''}
            requestDetails={{
                name: 'ModelElevation',
                namePlural: 'ModelElevations',
                path: '/modelElevations/names/filtered',
                method: 'POST',
                body: { CommunityID: props.filteredValue }
            }}
        />
    )
}