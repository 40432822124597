import { useEffect, useMemo, useRef, useState } from "react";
import { UseFormRegister } from "react-hook-form";
import APIRequest from "../../../../helpers/CreateRequest";

export interface SelectFieldProps {
    name: string;
    label: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    type?: string;
    required?: boolean;
    size?: "sm" | "md" | "lg";
    options?: { name: string, value: string }[];
    requestDetails?: {
        name: string,
        namePlural: string,
        path?: string,
        method?: "GET" | "POST" | "PUT" | "DELETE"
        header?: any
        body?: any
        resultName?: string
        resultIDName?: string
    }
    filteredValue?: string;
    setValue: React.Dispatch<React.SetStateAction<any>>
};

export default function SelectField(props: SelectFieldProps) {
    const [options, setOptions] = useState(props.options)
    const filteredValue = useRef(props.filteredValue)

    const getOptions = async () => {
        let tempOptions: { name: string, value: any }[] = []
        try {
            if (!props.requestDetails) throw 'No request details provided'
            const path = props.requestDetails.path || `/${props.requestDetails.namePlural.replace(/\s+/g, '')}/names`
            const method = props.requestDetails.method || 'GET'
            const header = props.requestDetails.header || null
            const body = props.requestDetails.body || null
            const result = await (new APIRequest(path, method, header, body).GenerateRequest())
            if (result.status === 200) {
                const body = await result.json()
                const objs: any[] = body.recordset || []
                if (objs.length > 0) {
                    for (let i = 0; i < objs.length; i++) {
                        const tempName = props.requestDetails.resultName ? objs[i][props.requestDetails.resultName] : objs[i].Name || objs[i][`${props.requestDetails.name.replace(/\s+/g, '')}Name`] || objs[i][`${props.requestDetails.name.replace(/\s+/g, '')}NameCode`]
                        const tempID = props.requestDetails.resultIDName ? objs[i][props.requestDetails.resultIDName] : objs[i][`${props.requestDetails.name.replace(/\s+/g, '')}ID`]
                        tempOptions.push({ name: tempName, value: tempID })
                    }
                } else tempOptions = [{ name: 'No data found', value: '' }]
            } else throw result.statusText
        }
        catch (err) {
            tempOptions = [{ name: 'Error fetching data', value: 'error' }]
        }
        return tempOptions
    }

    useEffect(() => {
        if (!options || props.filteredValue !== filteredValue.current) {
            const fetchOptions = async () => { setOptions(await getOptions()) }
            fetchOptions()
        }
    }, [props.filteredValue])


    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (props.onChange) props.onChange(e)
        props.setValue((formValues: any) => ({ ...formValues, [props.name]: e.target.value }))
    }

    const handleInvalid = (e: React.FormEvent<HTMLSelectElement>) => {
        e.currentTarget.scrollIntoView({ behavior: "auto", block: "center", inline: "nearest" })
        e.currentTarget.setCustomValidity(`Please select a ${props.label.toLocaleLowerCase()}`)
    }

    useEffect(()=>{
        setOptions(props.options)
    }, [props.options])

    return (
        <div className="flex flex-col h-[48px] body-small">
            <label
                htmlFor={props.name}
                className={`w-max h-[20px] ${props.required ? "after:content-['*'] after:ml.5" : ""}`
                }
            >
                {props.label}
            </label>
            <select
                name={props.name}
                id={props.name}
                value={props.value || ''}
                onChange={onChange}
                required={props.required}
                onInvalid={handleInvalid}
                onInput={(e) => e.currentTarget.setCustomValidity('')}
                className={`py-1 px-2 border border-md-outline h-[28px] bg-md-background
                ${props.size === "md" ? "w-[250px]" : "w-[540px]"}
                `} >
                <option key={`selectOption${props.name}${props.label}`} value={''} >
                    Select a {props.label}
                </option>
                {
                    options ? options.map(option => (
                        <option key={`definedOptions${props.name}${props.label}${option.name}${option.value}`} value={option.value}>
                            {option.name}
                        </option>
                    ))
                        :
                        < option key={`loadingOptions${props.name}${props.label}`} value={'loading'} >
                            loading...
                        </option >
                }
            </select >
        </div>
    )
}