import BaseField, { BaseFieldProps } from "./BaseField";

export default function PercentField(props: BaseFieldProps) {

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const EXCEPTION = e.ctrlKey || e.altKey || e.metaKey || e.key === 'Backspace' || e.key === 'Delete' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Tab' || (e.key === '.' && !e.currentTarget.value.includes('.'))

        //only digits and the excepts above will work
        if (!/^\d$/.test(e.key) && !EXCEPTION || e.key === ' ') {
            e.preventDefault()
        }
    }

    return (
        <BaseField {...props} type="percent" onKeydown={handleKeyDown} />
    );
}
