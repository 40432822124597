import { Link, useLocation } from "react-router-dom"
import SalesForecasts from "./SalesForecasts"
import { useEffect, useState } from "react"
import APIRequest from "../../helpers/CreateRequest"
import { Cookies } from "react-cookie"
import Modal from "../../components/atoms/Modal"
import { FieldValues, UseFormGetValues, UseFormRegister, UseFormReset, UseFormSetValue, useForm } from "react-hook-form"
import { Currency, CurrencyToNumber } from "../../helpers/InputValueConverter"
import Button from "../../components/atoms/Button"
import SelectField from "../../components/atoms/forms/Fields/SelectField"
import TextField from "../../components/atoms/forms/Fields/TextField"
import DateField from "../../components/atoms/forms/Fields/DateField"
import CurrencyField from "../../components/atoms/forms/Fields/CurrencyField"
import FormatDate from "../../helpers/FormatDate"

const tableStructure: { name: string, type: string, alias?: string }[] = [
    { name: "HouseCode", type: 'false', alias: "House No." },
    { name: "SaleStatusName", type: 'false', alias: 'Sale Status' },
    { name: "SaleTypeName", type: 'select', alias: 'Sale Type' },
    { name: "SaleDate", type: 'date', alias: 'Sale Date' },
    { name: "StartDate", type: 'date', alias: "Start Date" },
    { name: "SettlementDate", type: 'date', alias: "Settlement Date" },
    { name: "Deposit", type: 'currency' },
    { name: "SalePrice", type: 'currency', alias: "Sale Price" },
    { name: "Cost", type: 'currency' },
    { name: "OverrideStatusName", type: 'false', alias: 'Override Status' }
]


export default function SalesForecastHouses() {
    const location = useLocation()
    const salesForecastID = location.state?.salesForecastID || undefined
    const [houses, setHouses] = useState<[] | undefined>()
    const [editing, setEditing] = useState(false)
    const [currentHouse, setCurrentHouse] = useState<any | false>(false)
    const [refresh, setRefresh] = useState(false)

    //get houses
    useEffect(() => {
        setCurrentHouse(false)
        // get houses
        const getHouses = async () => {
            try {
                const result = await new APIRequest('/salesForecasts/salesForecastHouses', 'GET', new Headers({
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "Access-Control-Allow": "true",
                    Authorization: `Bearer ${new Cookies().get('token')}`,
                    SalesForecastID: salesForecastID
                }), null
                ).GenerateRequest()

                if (result.status === 200) {
                    const body = await result.json()
                    console.log(body)
                    setHouses(body.recordset)
                }
            } catch (err) {
                console.log(err)
            }
        }
        getHouses()
    }, [refresh])

    useEffect(() => setEditing(!editing), [currentHouse])

    return !salesForecastID ? <SalesForecasts />
        :
        (
            <>
                {/* Header */}
                <div className="pt-10 px-20 title-large sticky top-0 bg-md-background z-10">
                    {/* Menu Section Title */}
                    <div className="font-bold text-xl mb-[10px]">
                        <span>Home Building</span>
                        <span> {">"} </span>
                        <Link to='/app/sales-forecasts' className=" hover:text-md-tertiary" state={{ id: salesForecastID }}>Sales Forecasts</Link>
                    </div>

                    {/* Title */}
                    <div className="grid grid-cols-12 mb-4">
                        <div className="font-bold text-lg mb-[10px] col-span-10">Sales Forecast Houses</div>
                        <div className="flex col-span-2 justify-end ">
                            <ResetButton id={salesForecastID} refresh={refresh} setRefresh={setRefresh} />
                        </div>
                    </div>

                    {/* table header */}
                    <div className="grid grid-cols-11  body-large ">
                        {tableStructure.map(header => <span key={`salesForecastHouseHeader${header.alias || header.name}`} className="flex font-bold text-center justify-center items-end sticky top-0 bg-md-background z-10 p-1 pb-2 border">{header.alias || header.name}</span>)}
                        <span className=" flex font-bold justify-center items-end sticky top-0 bg-md-background z-10 border"></span>

                    </div>

                </div>

                {/* Table body*/}
                <div className="grid grid-cols-11 px-20 body-large pb-4">
                    {
                        houses && houses.length > 0 ?

                            houses.map((house, index) => <Row key={`salesForecastHouseRow${index}${house}`} house={house} index={index} setCurrentHouse={setCurrentHouse} />)

                            :

                            <div className="col-span-7 text-center">No houses for current sales forecast</div>
                    }
                </div>

                <EditHouse setCurrentHouse={setCurrentHouse} house={currentHouse} refresh={refresh} setRefresh={setRefresh} salesForecastID={salesForecastID} />
            </>
        )
}

function Row({ house, index, setCurrentHouse }: { house: any, index: number, setCurrentHouse: Function }) {
    const [opacity, setOpacity] = useState(false)

    useEffect(() => {
        setTimeout(() => setOpacity(true), 500);
    }, [])

    return (
        <div className={`col-span-11 grid grid-cols-11 transition-all duration-500 ${opacity ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-0'}`} key={`salesForecastHousesRow${index}`}>
            {
                tableStructure.map(cell => (
                    <span className={`border-y overflow-clip py-2 ${cell.type === 'date' || cell.type === 'currency' ? 'text-right' : ''} border-x px-2`} key={`salesForecastHousesCell${cell.name}${cell.alias}${index}`}>
                        {cell.type === 'date' && house[cell.name] !== null ? FormatDate(house[cell.name], false)
                            : cell.type === 'currency' && house[cell.name] !== null && house[cell.name] !== undefined ? Currency(house[cell.name])
                                : cell.type === 'false' || cell.type === 'select' ? house[cell.name]
                                    : null}
                    </span>
                ))
            }
            <span className="border flex justify-center">
                <Button styleType="text" type="button" action={() => setCurrentHouse(house)} label='Edit' />
            </span>
        </div>
    )
}


function EditHouse({ setCurrentHouse, house, refresh, setRefresh, salesForecastID }: { setCurrentHouse: Function, house: any, refresh: boolean, setRefresh: Function, salesForecastID: string }) {
    const [isActualHouse, setIsActualHouse] = useState(false)
    const [isSpec, setIsSpec] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting, isLoading },
        reset,
        watch,
        getValues,
        setValue,
    } = useForm()
    const watchSalesType = watch("SaleTypeID")
    const watchHouseCode = watch("HouseCode")

    const onSubmit = async (e: FieldValues) => {
        try {
            e.id = house.SalesForecastHouseID
            e.HouseID = house.HouseID
            e.Deposit = CurrencyToNumber(e.Deposit)
            e.SalePrice = CurrencyToNumber(e.SalePrice)
            e.Cost = CurrencyToNumber(e.Cost)
            const result = await new APIRequest('/salesForecasts/salesForecastHouses', 'PUT', null, e).GenerateRequest()
            if (result.status === 200) {
                const body = await result.json()
                if (body.status === 200) {
                    setRefresh(!refresh)
                    reset()
                    setCurrentHouse(false)
                } else {
                    console.log(body)
                }
            }
        } catch (err) {
            console.log(err)
        }
    }


    // test for is actual house
    useEffect(() => {
        const getHouses = async () => {
            try {
                let isActualHouseTemp = false
                const result = await new APIRequest('/houses/ActualHouseCodesSelect', 'POST', null, { salesForecastID: salesForecastID }).GenerateRequest()
                if (result.status === 200) {
                    const body = await result.json()
                    if (body.status === 200) {
                        const recordset = body.recordset
                        for (let i = 0; i < recordset.length || 0; i++) {
                            if (recordset[i].HouseCode && recordset[i].HouseCode === house.HouseCode) {
                                isActualHouseTemp = true
                                break;
                            }
                        }
                    } else throw body.message
                } else throw result.statusText
                setIsActualHouse(isActualHouseTemp)
            } catch (err) {
                console.log(err)
            }
        }

        getHouses()
    }, [watchHouseCode])

    //test for spec
    useEffect(() => {
        const SPEC = '2'
        if (watchSalesType === SPEC) {
            setIsSpec(true)
        } else {
            setIsSpec(false)
            //set field as undefined 
            setValue("SpecHouse", undefined)
        }
    }, [watchSalesType])


    return (
        house && <Modal className="bg-white w-[640px] max-h-full h-[730px] overflow-auto rounded-xl py-5 px-10 body-large " onClose={() => { reset(); setIsActualHouse(false); setIsSpec(false); setCurrentHouse(false) }}>
            <form className="flex flex-col p-10 gap-y-10 gap-x-5 m-auto overflow-auto" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-span-2 m-auto  title-normal">
                    Sales Forecast House Edit
                </div>

                <TextField
                    name="HouseCode"
                    label="House No."
                    disabled={false}
                    required={true}
                    value={house.HouseCode}
                    formRef={register("HouseCode")}
                    setValue={setValue}
                />

                <span className="flex-row justify-between flex">
                    <SelectField
                        name="SaleTypeID"
                        label="Sale Type"
                        disabled={isActualHouse}
                        setValue={setValue}
                        value={house.SaleTypeID ? String(house.SaleTypeID) : undefined}
                        fieldNames={{
                            name: 'SaleType',
                            namePlural: 'SaleTypes'
                        }}
                        staticOptions={[{ name: 'Presold', value: '1' }, { name: 'Spec', value: '2' }]}

                    />

                    <SpecSelect setValue={setValue} setCurrentHouse={setCurrentHouse} salesForecastHouseID={house.SalesForecastHouseID} salesForecastID={salesForecastID} reset={reset} getValues={getValues} isSpec={isSpec} />
                </span>

                <SelectField
                    name="FinanceTypeID"
                    label="Finance Type"
                    disabled={false}
                    setValue={setValue}
                    value={house.FinanceTypeID}
                    fieldNames={{
                        name: 'FinanceTypeID',
                        namePlural: 'FinanceTypeIDs'
                    }}
                    staticOptions={[{ name: 'End Loan', value: '1' }, { name: 'Construction Loan', value: '2' }]}
                />

                <div className="flex-row justify-between flex">
                    <DateField
                        name="SaleDate"
                        label="Sale Date"
                        disabled={false}
                        required={true}
                        value={house.SaleDate !== null ? FormatDate(house.SaleDate, true) : undefined}
                        formRef={register("SaleDate")}
                        setValue={setValue}
                    />

                    <SelectField
                        name="SaleEventStatusID"
                        label="Status"
                        disabled={false}
                        setValue={setValue}
                        value={house.SaleEventStatusID}
                        fieldNames={{
                            name: 'SaleEventStatusID',
                            namePlural: 'SaleEventStatusIDs'
                        }}
                        staticOptions={[{ name: 'Actual', value: '1' }, { name: 'Scheduled', value: '2' }, { name: 'Estimated', value: '3' }]}
                    />
                </div>


                <div className="flex-row justify-between flex">
                    <DateField
                        name="StartDate"
                        label="Start Date"
                        disabled={false}
                        required={true}
                        value={house.StartDate !== null ? FormatDate(house.StartDate, true) : undefined}
                        formRef={register("StartDate")}
                        setValue={setValue}
                    />

                    <SelectField
                        name="StartEventStatusID"
                        label="Status"
                        disabled={false}
                        setValue={setValue}
                        value={house.StartEventStatusID ? String(house.StartEventStatusID) : undefined}
                        fieldNames={{
                            name: 'StartEventStatusID',
                            namePlural: 'StartEventStatusIDs'
                        }}
                        staticOptions={[{ name: 'Actual', value: '1' }, { name: 'Scheduled', value: '2' }, { name: 'Estimated', value: '3' }]}
                    />
                </div>

                <div className="flex-row justify-between flex">
                    <DateField
                        name="SettlementDate"
                        label="Settlement Date"
                        disabled={false}
                        required={true}
                        value={house.SettlementDate !== null ? FormatDate(house.SettlementDate, true) : undefined}
                        formRef={register("SettlementDate")}
                        setValue={setValue}
                    />

                    <SelectField
                        name="SettlementEventStatusID"
                        label="Status"
                        disabled={false}
                        setValue={setValue}
                        value={house.SettlementEventStatusID}
                        fieldNames={{
                            name: 'SettlementEventStatusID',
                            namePlural: 'SettlementEventStatusIDs'
                        }}
                        staticOptions={[{ name: 'Actual', value: '1' }, { name: 'Scheduled', value: '2' }, { name: 'Estimated', value: '3' }]}

                    />
                </div>



                <CurrencyField
                    name="Deposit"
                    label="Deposit"
                    disabled={false}
                    required={true}
                    value={house.Deposit ? String(house.Deposit) : undefined}
                    formRef={register("Deposit")}
                    setValue={setValue}
                />

                <CurrencyField
                    name="SalePrice"
                    label="Sale Price"
                    disabled={false}
                    required={true}
                    value={house.SalePrice ? String(house.SalePrice) : undefined}
                    formRef={register("SalePrice")}
                    setValue={setValue}
                />

                <CurrencyField
                    name="Cost"
                    label="Cost"
                    disabled={false}
                    required={true}
                    value={house.Cost ? String(house.Cost) : undefined}
                    formRef={register("Cost")}
                    setValue={setValue}
                />

                <span className="flex-row justify-between flex">

                    <span className="col-span-3 m-auto">
                        <Button
                            label="Cancel"
                            styleType="outlined"
                            action={() => { reset(); setCurrentHouse(false) }}
                            type="button"
                        />
                    </span>

                    <span className="col-span-3 m-auto">
                        <Button
                            label="Save"
                            styleType="tonal"
                            action={() => null}
                            type="submit"
                        />
                    </span>
                </span>
            </form>
        </Modal>
    )
}


function SpecSelect(props: { setValue: UseFormSetValue<any>, salesForecastID: string, reset: UseFormReset<FieldValues>, getValues: UseFormGetValues<FieldValues>, isSpec: boolean, salesForecastHouseID: string, setCurrentHouse: Function }) {
    const [selectedValue, setSelectedValue] = useState()

    const onChange = async () => {
        try {
            const result = await new APIRequest('/salesForecasts/specHouses/unit', "POST", null, { id: selectedValue, salesForecastHouseID: props.salesForecastHouseID }).GenerateRequest()


            if (result.status === 200) {
                const body = await result.json()

                if (body?.recordset.length > 0) {
                    const { SaleDate, StartDate, SettlementDate } = body.recordset[0]
                    body.recordset[0].SaleDate = SaleDate && SaleDate !== null ? FormatDate(SaleDate, true) : SaleDate
                    body.recordset[0].StartDate = StartDate && StartDate !== null ? FormatDate(StartDate, true) : StartDate
                    body.recordset[0].SettlementDate = SettlementDate && SettlementDate !== null ? FormatDate(SettlementDate, true) : SettlementDate
                    props.setCurrentHouse(body.recordset[0])
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        const temp = () => onChange();
        if (selectedValue) temp()
    }, [selectedValue])


    useEffect(() => {
        if (!props.isSpec) setSelectedValue(undefined)
    }, [props.isSpec])


    return (
        <SelectField
            name="SpecHouse"
            label="Spec"
            disabled={!props.isSpec}
            setValue={props.setValue}
            fieldNames={{ name: 'specHouse', namePlural: 'specHouses' }}
            setWrapperState={setSelectedValue}
            value={selectedValue}
            requestDetails={{
                path: "/salesForecasts/SpecHouses/names",
                method: "POST",
                body: { id: props.salesForecastID },
                resultName: 'HouseCode',
                resultIDName: 'HouseID'
            }}
        />
    )
}

function ResetButton({ id, refresh, setRefresh }: { id: string, refresh: boolean, setRefresh: Function }) {

    const handleClick = async () => {
        try {
            const result = await new APIRequest('/salesForecasts/reset', 'POST', null, { id: id }).GenerateRequest()
            if (result.status === 200) setRefresh(!refresh)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <Button
            label="Reset"
            type="button"
            styleType="tonal"
            action={handleClick}
        />
    )
}