import { useState, useEffect } from 'react'
import APIRequest from "../../../helpers/CreateRequest"
import { Currency } from '../../../helpers/InputValueConverter';
import { ResponsiveLine } from '@nivo/line';
import Button from '../../../components/atoms/Button';

type businessPlanLineItem = {
    Year: number;
    Description: string;
    ItemType: string;
    Amount: string;
    ListOrder: number;
};

type businessPlanItems = businessPlanLineItem[];

type businessPlanYears = number[]
type businessPlanValues = { yearOne?: string; yearTwo?: string; yearThree?: string; }
type businessPlanLines = { description: string; values: businessPlanValues; listOrder: number; }[]

type yearOverYearMetricItems = { EventPeriod: string; GrossProfitSum: number; GrossMarginAverage: number; }[];

type dataPoint = { x: string; y: number; };
type dataPoints = dataPoint[];
type dataSet = {
    id: string;
    color: string;
    data: dataPoints;
}[]

export default function BusinessPlanReport({ ScenarioID, triggerNewData }: { ScenarioID: string, triggerNewData: boolean }) {
    const [businessPlan, setBusinessPlan] = useState<businessPlanItems>([])
    const [yearOverYearMetrics, setYearOverYearMetrics] = useState<yearOverYearMetricItems>([])
    const [businessPlanLoading, setBusinessPlanLoading] = useState<boolean>(false)

    const getBusinessPlanItems = async () => {

        const tempBusinessPlanItems: businessPlanItems = []

        if (ScenarioID) {
            try {
                const result = await new APIRequest('/scenariosSalesForecastEvents/businessPlan', "POST", null, { scenarioID: ScenarioID }).GenerateRequest()

                if (result.status === 200) {
                    const body = await result.json()
                    if (body.status === 200) {

                        for (let i = 0; i < body.recordset.length; i++) {
                            tempBusinessPlanItems.push({
                                Year: body.recordset[i].Year,
                                Description: body.recordset[i].Description,
                                ItemType: body.recordset[i].ItemType,
                                Amount: body.recordset[i].Amount,
                                ListOrder: body.recordset[i].ListOrder
                            })
                        }

                        setBusinessPlan(tempBusinessPlanItems || []);
                        setBusinessPlanLoading(false);

                    } else throw body.message
                } else throw result.statusText

            } catch (err) {
                console.log(err)
            }
        }

    }

    // get Business Plan Items for the selected scenario
    useEffect(() => {
        getBusinessPlanItems()
    }, [ScenarioID, triggerNewData])

    // get YOY metrics from DB - set YOY metrics if success
    useEffect(() => {

        const tempYearOverYearMetricItems: yearOverYearMetricItems = []

        const getYearOverYearMetricItems = async () => {
            if (ScenarioID) {
                try {
                    const result = await new APIRequest('/scenariosSalesForecastEvents/yearOverYearFinances', "POST", null, { scenarioID: ScenarioID }).GenerateRequest()

                    if (result.status === 200) {
                        const body = await result.json()
                        if (body.status === 200) {

                            for (let i = 0; i < body.recordset.length; i++) {
                                tempYearOverYearMetricItems.push({
                                    EventPeriod: body.recordset[i].EventPeriod,
                                    GrossProfitSum: body.recordset[i].GrossProfitSum,
                                    GrossMarginAverage: body.recordset[i].GrossMarginAverage
                                })
                            }

                            setYearOverYearMetrics(tempYearOverYearMetricItems || [])

                        } else throw body.message
                    } else throw result.statusText

                } catch (err) {
                    console.log(err)
                }
            }
        }

        getYearOverYearMetricItems()
    }, [ScenarioID])

    return (
        <div className="flex w-full flex-col h-full gap-y-4 max-w-[1600px] mx-auto">
            {/* header */}
            <div className="grid grid-cols-12 w-full gap-4 h-[80px] items-center bep-shadow-xl rounded-lg p-4">
                <span className="col-span-2 headline-large">Business Plan</span>
                <span className='col-span-3 flex justify-center '>

                </span>
            </div>

            {/* body */}
            <div className="flex flex-grow flex-col font-bold">

                <div className=' flex w-full gap-y-2 gap-x-4'>
                    <div className='flex flex-col bep-shadow-xl p-4 rounded-lg gap-y-3 items-center w-[60%]'>
                        <span className="pb-4 border-b-2 text-center text-xl">Business Plan</span>
                        <div>
                            {
                                businessPlanLoading ?
                                    (
                                        <Spinner />
                                    ) :
                                    <BusinessPlan businessPlan={businessPlan} />
                            }
                        </div>
                    </div>

                    <div className='flex flex-col bep-shadow-xl p-4 rounded-lg gap-y-3 items-center w-[40%] '>
                        <span className="pb-4 border-b-2 text-center text-xl">YOY Metrics</span>
                        <YearOverYearMetrics yearOverYearMetrics={yearOverYearMetrics} />
                    </div>
                </div>

            </div>
        </div>
    )
}

function BusinessPlan({ businessPlan }: { businessPlan: businessPlanItems }) {
    const [businessPlanLines, setBusinessPlanLines] = useState<businessPlanLines>([])
    const [businessPlanYears, setBusinessPlanYears] = useState<businessPlanYears>([])

    useEffect(() => {
        if (businessPlan) {
            //const uniqueDescriptions = businessPlan.map(item => item.Description).filter((value, index, self) => self.indexOf(value) === index)
            const businessPlanLinesTemp: businessPlanLines = []

            const uniqueYears = businessPlan
                .map(item => item.Year) // get all years
                .filter((value, index, self) => self.indexOf(value) === index) // remove duplicates
                .sort((a, b) => a - b) // sort in ascending order

            const yearOne: number = uniqueYears[0]
            const yearTwo: number = uniqueYears[1]
            const yearThree: number = uniqueYears[2]

            setBusinessPlanYears(uniqueYears);

            //let totalRevenueTemp = 0;
            for (let i = 0; i < businessPlan.length; i++) {

                let descriptionFound = false

                for (let j = 0; j < businessPlanLinesTemp.length; j++) {
                    if (businessPlan[i].Description === businessPlanLinesTemp[j].description) {

                        if (businessPlan[i].Year === yearOne) {
                            businessPlanLinesTemp[j].values.yearOne = businessPlan[i].Amount
                        } else if (businessPlan[i].Year === yearTwo) {
                            businessPlanLinesTemp[j].values.yearTwo = businessPlan[i].Amount
                        } else if (businessPlan[i].Year === yearThree) {
                            businessPlanLinesTemp[j].values.yearThree = businessPlan[i].Amount
                        }

                        descriptionFound = true
                        break;
                    }
                }

                if (!descriptionFound) {
                    if (businessPlan[i].Year === yearOne) {
                        businessPlanLinesTemp.push({
                            description: businessPlan[i].Description
                            , values: {
                                yearOne: businessPlan[i].Amount
                            }
                            , listOrder: businessPlan[i].ListOrder
                        })
                    } else if (businessPlan[i].Year === yearTwo) {
                        businessPlanLinesTemp.push({
                            description: businessPlan[i].Description
                            , values: {
                                yearTwo: businessPlan[i].Amount
                            }
                            , listOrder: businessPlan[i].ListOrder
                        })
                    } else if (businessPlan[i].Year === yearThree) {
                        businessPlanLinesTemp.push({
                            description: businessPlan[i].Description
                            , values: {
                                yearThree: businessPlan[i].Amount
                            }
                            , listOrder: businessPlan[i].ListOrder
                        })
                    }
                }
            }
            setBusinessPlanLines(businessPlanLinesTemp)
        }
    }, [businessPlan])

    return (
        <div>


            {/* table */}
            <div className="w-full h-full overflow-auto">
                {/* headers */}
                <div className="grid grid-cols-12 pt-2 pb-2 h-min w-full border-2 border-black border-solid bg-gray-300 body-medium sticky top-0 text-xl text-end">

                    <span className="col-span-6 text-center">Description</span>
                    <span className={`col-span-2 text-center ${!businessPlanYears[0] ? `text-start pr-4 pl-4` : ``}`}>{businessPlanYears[0] ? businessPlanYears[0] : 'Year One'}</span>
                    <span className={`col-span-2 text-center ${!businessPlanYears[1] ? `text-start pr-4 pl-4` : ``}`}>{businessPlanYears[1] ? businessPlanYears[1] : 'Year Two'}</span>
                    <span className={`col-span-2 text-center ${!businessPlanYears[2] ? `text-start pr-8 pl-4` : ``}`}>{businessPlanYears[2] ? businessPlanYears[2] : 'Year Three'}</span>

                </div>

                {/* body */}
                <div className="w-full border-r-2 border-l-2 border-b-2 border-black whitespace-nowrap">
                    {
                        businessPlanLines[0] ?

                            <div>
                                {
                                    businessPlanLines
                                        .sort((a, b) => {
                                            if (a.listOrder !== b.listOrder) {
                                                return a.listOrder - b.listOrder;
                                            }
                                            if (a.description < b.description) {
                                                return -1;
                                            }
                                            if (a.description > b.description) {
                                                return 1;
                                            }
                                            return 0;
                                        }) // sort by list order, then by description (alphabetically)
                                        .map((businessPlanLine) => (
                                            <div key={`businessPlan${businessPlanLine.description}`}>
                                                <div className={`w-full grid grid-cols-12 border-b-2 border-gray-300 z-10 ${businessPlanLine.description === "Net Income" || businessPlanLine.description === "Net Margin" ? `bg-blue-100` : ``}`}>
                                                    <span className='my-auto pl-2 pr-2 col-span-6 border-r-2 border-black z-20'>{businessPlanLine.description}</span>
                                                    <span className={`my-auto pl-2 pr-2 col-span-2 border-r-2 border-gray-300 text-end ${Number(businessPlanLine.values.yearOne) < 0 ? `text-red-700` : `text-black`} `}>
                                                        {businessPlanLine.values.yearOne ? (

                                                            businessPlanLine.description === "Gross Margin" || businessPlanLine.description === "Net Margin"
                                                                ? (Number(businessPlanLine.values.yearOne) < 0
                                                                    ? `(${(-1 * Number(businessPlanLine.values.yearOne)).toFixed(1)}%)`
                                                                    : `${Number(businessPlanLine.values.yearOne).toFixed(1)}%`
                                                                )
                                                                : Currency(String(businessPlanLine.values.yearOne), true)
                                                        ) : '-'

                                                        }
                                                    </span>
                                                    <span className={`my-auto pl-2 pr-2 col-span-2 border-r-2 border-gray-300 text-end ${Number(businessPlanLine.values.yearTwo) < 0 ? `text-red-700` : `text-black`} `}>
                                                        {businessPlanLine.values.yearTwo ? (

                                                            businessPlanLine.description === "Gross Margin" || businessPlanLine.description === "Net Margin"
                                                                ? (Number(businessPlanLine.values.yearTwo) < 0
                                                                    ? `(${(-1 * Number(businessPlanLine.values.yearTwo)).toFixed(1)}%)`
                                                                    : `${Number(businessPlanLine.values.yearTwo).toFixed(1)}%`
                                                                )
                                                                : Currency(String(businessPlanLine.values.yearTwo), true)
                                                        ) : '-'

                                                        }
                                                    </span>
                                                    <span className={`my-auto pl-2 pr-2 col-span-2 border-r-2 border-gray-300 text-end ${Number(businessPlanLine.values.yearThree) < 0 ? `text-red-700` : `text-black`} `}>
                                                        {businessPlanLine.values.yearThree ? (

                                                            businessPlanLine.description === "Gross Margin" || businessPlanLine.description === "Net Margin"
                                                                ? (Number(businessPlanLine.values.yearThree) < 0
                                                                    ? `(${(-1 * Number(businessPlanLine.values.yearThree)).toFixed(1)}%)`
                                                                    : `${Number(businessPlanLine.values.yearThree).toFixed(1)}%`
                                                                )
                                                                : Currency(String(businessPlanLine.values.yearThree), true)
                                                        ) : '-'

                                                        }
                                                    </span>

                                                </div>

                                            </div>
                                        ))
                                }
                            </div>

                            :

                            <div className='flex text-center p-4'>
                                <div className='m-auto label-large'>
                                    Please select a scenario
                                </div>
                            </div>
                    }
                </div>

            </div>
        </div>
    )
}

function YearOverYearMetrics({ yearOverYearMetrics }: { yearOverYearMetrics: yearOverYearMetricItems }) {

    const grossProfitData: dataSet = [
        {
            id: 'Gross Profit',
            color: "hsl(301, 70%, 50%)",
            data: yearOverYearMetrics && yearOverYearMetrics.length > 0
                ? yearOverYearMetrics.map(item => ({ x: item.EventPeriod, y: item.GrossProfitSum }))
                : [{ x: 'n/a', y: 0 }]
        }
    ];

    const grossMarginData: dataSet = [
        {
            "id": 'Gross Margin',
            "color": 'hsl(001, 70%, 50%)',
            "data": yearOverYearMetrics && yearOverYearMetrics.length > 0
                ? yearOverYearMetrics.map(item => ({ x: item.EventPeriod, y: Number(item.GrossMarginAverage) * 100 }))
                : [{ x: 'n/a', y: 0 }]
        }
    ];

    return (
        <>
            <div className=" bep-shadow-xl h-[350px] w-[400px] border-2 border-black body-small py-2 px-4 flex flex-col items-center justify-center">
                <div>Total Gross Income</div>
                <div>By Year and Quarter</div>
                <MyResponsiveLine data={grossProfitData} axisLeftLegend='Sum of Gross Income ($)' yFormat='>-$,.2f' />
            </div>
            <div className=" bep-shadow-xl h-[350px] w-[400px] border-2 border-black body-small py-2 px-4 flex flex-col items-center justify-center">
                <div>Average Gross Margin</div>
                <div>By Year and Quarter</div>
                <MyResponsiveLine data={grossMarginData} axisLeftLegend="Average Gross Margin (%)" yFormat='>-.2f' />
            </div>
        </>
    )
}

function MyResponsiveLine({ data, axisLeftLegend, yFormat }: { data: dataSet, axisLeftLegend: string, yFormat: string }) {

    console.log(data)

    return (

        <ResponsiveLine
            data={data}
            margin={{ top: 30, right: 20, bottom: 70, left: 80 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: true,
                reverse: false
            }}
            yFormat={yFormat}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 90,
                legend: 'Period',
                legendOffset: 65,
                legendPosition: 'middle',
                truncateTickAt: 0
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: axisLeftLegend,
                legendOffset: -70,
                legendPosition: 'middle',
                truncateTickAt: 0
            }}
            colors={{ scheme: 'category10' }}
            pointSize={10}
            pointColor={{ from: 'color', modifiers: [] }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="data.yFormatted"
            pointLabelYOffset={-12}
            enableTouchCrosshair={true}
            useMesh={true}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: true,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    )
}
const Spinner = () => (
    <div className="p-2 h-[80px] w-[80px]">
        <svg className="animate-spin inline-block m-auto w-full h-full border-[3px] border-current border-t-transparent text-md-inverse-primary rounded-full" viewBox="0 0 16 16" />
    </div>
);
