import { useEffect, useState } from "react";
import Search from "../../components/molecules/Search";
import Button from "../../components/atoms/Button";
import APIRequest from "../../helpers/CreateRequest";
import { Currency, CurrencyToNumber } from "../../helpers/InputValueConverter";
import SnackBar from "../../components/molecules/SnackBar";
import Modal from "../../components/atoms/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import GetFormData from "../../helpers/GetFormData";
import { SalesForecast, SalesForecastDefaultValues, SalesForecastModel } from "../../Types/SalesForecast";
import HBreak from "../../components/atoms/forms/HBreak";
import CommunityID from "./CommunityID";
import RefreshButton from "./RefreshButton";
import FormSection from "./FormSection";
import { mode } from "../../Types/FormTypes";
import TextField from "../../components/atoms/forms/SimpleFields/TextField";
import SelectField from "../../components/atoms/forms/SimpleFields/SelectField";
import NumberField from "../../components/atoms/forms/SimpleFields/NumberField";
import PercentField from "../../components/atoms/forms/SimpleFields/PercentField";
import CurrencyField from "../../components/atoms/forms/SimpleFields/CurrencyField";
import DateField from "../../components/atoms/forms/SimpleFields/DateField";
import LastRefresh from "./LastRefresh";
import ModelElevation from "./ModelElevation";
import FormTypeField from "./FormTypeField";
import { GetCalculatedFields } from "./SalesForecastCalculations";

const name = {
    normal: 'Sales Forecast',
    plural: 'Sales Forecasts',
    alias: 'SalesForecast',
    aliasPlural: 'SalesForecasts',
    data: 'SalesForecast',
    dataPlural: 'SalesForecasts',
    sectionTitle: 'Home building > Sales Forecasts'
}

export const USERFORM = '1'
export const DERIVATIVEFORM = '2'
export const CATALOGFORM = '3'

const ATTACHED = '1'
const DETACHED = '2'



export default function SalesForecasts() {
    const location = useLocation()
    const stateID = location.state?.id
    const [triggerCollapse, setTriggerCollapse] = useState(true)
    const [options, setOptions] = useState<{ name: string, value: number }[]>([])
    const [id, setID] = useState(stateID)
    const [mode, setMode] = useState<mode>('add')
    const [snackbarMessage, setSnackbarMessage] = useState<{ message: string, refresh: boolean }>({ message: '', refresh: false })
    const [form, setForm] = useState<SalesForecast>(SalesForecastDefaultValues)
    const [formBackup, setFormBackup] = useState(SalesForecastDefaultValues)
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()
    const calculatedFields = GetCalculatedFields(form)

    // handles cancel and clear
    const handleCancel = () => {
        if ((mode === 'add' && id) || mode === 'edit') {
            setForm(formBackup)
            setMode('edit')
        } else {
            setForm(SalesForecastDefaultValues)
        }
    }

    // get sales forecast 
    const getObj = async (id: string) => {
        try {
            setIsLoading(true)
            const obj = await GetFormData(id, name.dataPlural, SalesForecastModel)
            setFormBackup(obj)
            setForm(obj)
            setMode('edit')
        } catch (err) {
            setID(undefined)
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }

    // get options for search
    const getOptions = async () => {
        try {
            const result = await ((await new APIRequest(`/${name.dataPlural}/names`, "GET", null, null).GenerateRequest()).json())
            const recordset: any[] = result.recordset
            const newOptions = recordset.map(record => ({ name: String(record[`${name.data}Name`] || record[`${name.data}NameCode`] || record[`${name.data}FullName`]), value: Number(record[`${name.data}ID`]) }))
            setOptions(newOptions)
        } catch (err) {
            console.error(err)
            setOptions([])
        }
    }

    const saveChanges = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        let message = "Changes saved"

        const data: any = form
        try {
            setIsLoading(true)
            let method;
            if (mode === 'add' || mode === 'edit' || mode === 'duplicate') {

                //filter and modify values to match type 
                SalesForecastModel.forEach(field => {
                    if (field.type === 'number') data[field.name] = parseFloat(data[field.name])
                    else if (field.type === 'currency') data[field.name] = CurrencyToNumber(data[field.name])
                    else if (field.type === 'percent') data[field.name] = (parseFloat(data[field.name]) / 100)
                    else if (field.type === 'checkbox') data[field.name] = data[field.name] === true ? true : undefined
                })

                data["CostsTotal"] = form.SalesForecastTypeID == USERFORM ? data["CostsTotal"] : CurrencyToNumber(calculatedFields.totalCost) || 0
                data["IndirectCostsTotal"] = CurrencyToNumber(data["IndirectCostsTotal"]) || CurrencyToNumber(calculatedFields.totalIndirectCost) || 0

                if (mode === 'duplicate') {
                    method = 'POST'
                    data['Name'] = `${data['Name']} - Copy`
                    data['Code'] = `${data['Code']} - Copy`
                } else {
                    method = mode === 'add' ? 'POST' : 'PUT'
                }
            } else {
                method = 'DELETE'
            }
            if (method !== 'POST') data['id'] = id

            console.log(data)

            const result = await new APIRequest(`/${name.dataPlural}`, method, null, data).GenerateRequest()
            if (result.status !== 200) throw result.statusText

            const record = await result.json()
            if (record.status !== 200) throw record.message

            if (method === 'DELETE') {
                setMode('add')
                setID(undefined)
                setFormBackup(SalesForecastDefaultValues)
                setForm(SalesForecastDefaultValues)
                message = 'Record deleted'
            } else if (mode === 'edit') {
                await getObj(id)
            }
            else {
                setID(record.id)
            }
            getOptions()

        } catch (err) {
            console.error(err)
            setForm((prev: SalesForecast) => ({ ...prev, }))
        } finally {
            setIsLoading(false)
            setSnackbarMessage({ message: message, refresh: !snackbarMessage.refresh })
        }
    }

    // get object data
    useEffect(() => {
        if (id) getObj(id)
    }, [id])

    // get options for search
    useEffect(() => { getOptions() }, [])

    return (
        // container
        <div className="flex flex-col xl:h-full w-full h-auto">
            <div className="min-w-full flex-row h-9 title-medium py-1 px-2 border-b-2 border-md-on-background sticky top-0 bg-md-background z-20">
                Home Building {'>'} Sales Forecasts
            </div>

            <div className="flex flex-wrap xl:flex-nowrap flex-grow h-[calc(100%_-_36px)]">
                {/* form */}
                <form className="px-4 flex xl:flex-col xl:overflow-auto xl:h-full flex-grow-[2] h-[100%_-_200px] flex-col" onSubmit={saveChanges} >
                    {/* Form control */}
                    <div className="flex flex-col w-full min-w-full gap-y-6 py-4">

                        {/* header */}
                        <div className="gap-x-4 gap-y-3 items-center  flex flex-wrap">
                            <div className="flex flex-row w-full items-center gap-x-4 justify-center">
                                <Search options={options} setID={setID} />
                            </div>
                            {
                                mode !== 'add' ?
                                    <>
                                        <Button label="Add" styleType="tonal" type="button" action={() => { setMode('add'); setForm(SalesForecastDefaultValues) }} />
                                        <Button label="Delete" styleType="tonal" type="button" action={() => setMode('delete')} />
                                        <Button label="Duplicate" styleType="tonal" type="button" action={() => setMode('duplicate')} />
                                        <Button label="Houses" styleType="text" type="button" action={() => navigate('/app/sales-forecasts/houses', { state: { salesForecastID: id } })} />
                                        <RefreshButton id={id} override={form.Override} refreshData={getObj} />
                                    </> :
                                    <></>
                            }

                            <Button label={id ? 'Cancel' : 'Clear'} styleType="tonal" type="button" action={handleCancel} />
                            <Button label="Save" styleType="tonal" type="submit" action={() => null} />
                            <span className="flex m-auto mr-0 ">
                                <Button label={triggerCollapse ? 'Collapse  all' : 'Expand all'} styleType="tonal" type="button" action={() => setTriggerCollapse(!triggerCollapse)} />
                            </span>
                        </div>
                    </div>

                    {/* fields */}
                    <div className="flex flex-col flex-grow h-full overflow-auto even:gap-y-1">

                        <FormSection name="General" triggerCollapse={triggerCollapse}>
                            <FormTypeField name="SalesForecastTypeID" label="Type of Sales Forecast" value={form.SalesForecastTypeID || "1"} setValue={setForm} resetTo={form} size="md" options={[{ label: 'User', value: USERFORM }, { label: 'Derivative', value: DERIVATIVEFORM }, { label: 'Catalog', value: CATALOGFORM }]} />
                            <TextField name="Name" label="Name" value={form.Name} setValue={setForm} required />
                            <TextField name="Description" label="Description" value={form.Description} setValue={setForm} />
                            <TextField name="Code" label="Code" value={form.Code} size="md" setValue={setForm} required />
                            <CommunityID name="CommunityID" label="Community" value={form.CommunityID} setValue={setForm} unitTypeID={form.UnitTypeID} size="md" required requestDetails={{ name: 'Community', namePlural: 'Communities' }} />
                            <LastRefresh name="LastRefreshTimestamp" label="Last Refresh" size="md" readonly value={form.LastRefreshTimeStamp} setValue={setForm} />
                        </FormSection>

                        <FormSection name="Product Offering" triggerCollapse={triggerCollapse} >
                            <SelectField name="UnitTypeID" label="Unit Type" value={form.UnitTypeID} setValue={setForm} size="md" required options={[{ name: 'Attached', value: ATTACHED }, { name: 'Detached', value: DETACHED }]} />
                            <SelectField name="SaleTypeID" label="Default Sale Type" value={form.SaleTypeID} setValue={setForm} size="md" options={[{ name: "Pre-sold", value: '1' }, { name: 'Spec', value: '2' }]} required />

                            {form.UnitTypeID === ATTACHED ?
                                <>
                                    <NumberField name="BuildingUnitCountAverage" label="Average Units Per Building" value={form.BuildingUnitCountAverage} setValue={setForm} size="md" required />
                                    <NumberField name="BuildingUnitCountMax" label="Max Building Unit Count" value={form.BuildingUnitCountMax} setValue={setForm} size="md" required />
                                    <PercentField name="BuildingFractionSoldToStart" label="Attached Percent of Building to Start" value={form.BuildingFractionSoldToStart} setValue={setForm} size="md" required />
                                    <NumberField name="UnsoldUnitCountMax" label="Max Unsold Unit Count" value={form.UnsoldUnitCountMax} setValue={setForm} size="md" required />
                                </>
                                :
                                <></>
                            }

                            {
                                form.SalesForecastTypeID !== USERFORM ?
                                    <>
                                        <SelectField name="ModelElevationCommunityID" label="Model Elevation Community" value={form.ModelElevationCommunityID} setValue={setForm} size="md" requestDetails={{ name: 'Community', namePlural: 'Communities', path: '/modelElevations/communities' }} />
                                        <ModelElevation name="ModelElevationID" label="Model Elevation" value={form.ModelElevationID} filteredValue={form.ModelElevationCommunityID} mode={mode} formType={form.SalesForecastTypeID} setValue={setForm} size="md" required />
                                        <NumberField name="SquareFootageFinancial" label="Model/Elevation SF" value={form.SquareFootageFinancial} setValue={setForm} size="md" readonly required />
                                    </>
                                    :
                                    <></>
                            }
                        </FormSection>

                        <FormSection name="Pricing" triggerCollapse={triggerCollapse} headers={[{ label: 'Sales Price', value: form.SalesForecastTypeID === USERFORM ? Currency(form.SalePriceTotal) || '$0.00' : calculatedFields.finalSalePrice || '$0.00' }, { label: 'Gross Profit', value: form.SalesForecastTypeID === USERFORM ? calculatedFields.baseGrossProfit : calculatedFields.finalGrossProfit }, { label: 'Gross Margin', value: `${form.SalesForecastTypeID === USERFORM ? calculatedFields.baseGrossMargin : calculatedFields.finalGrossMargin}%` }]}>
                            {
                                form.SalesForecastTypeID !== USERFORM ?
                                    <>
                                        <CurrencyField name="LotPremium" label="Average Lot Premium" value={form.LotPremium} setValue={setForm} size="md" required />
                                        <HBreak />
                                        <CurrencyField name="BasePrice" label="Model/Elevation Base Price" value={form.BasePrice} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} required />
                                        <span></span>
                                        <CurrencyField name="BaseGrossProfit" label="Base Gross Profit" value={calculatedFields.baseGrossProfit} setValue={setForm} size="md" readonly />
                                        <PercentField name="BaseGrossMargin" label="Base Gross Margin" value={calculatedFields.baseGrossMargin} setValue={setForm} size="md" readonly />
                                        <CurrencyField name="BasePricePerSF" label="Base Price $ Per SF" value={calculatedFields.basePricePerSF} setValue={setForm} size="md" readonly />
                                        <HBreak />
                                        <PercentField name="OptionSalesToBasePriceRatio" label="Options Sales % of Base Price" value={form.OptionSalesToBasePriceRatio} setValue={setForm} size="md" required />
                                        <CurrencyField name="OptionSales" label="Options Sales" value={calculatedFields.optionsSales} setValue={setForm} size="md" readonly />
                                        <HBreak />
                                        <CurrencyField name="Incentives" label="Incentives" value={form.Incentives} setValue={setForm} size="md" />
                                        <TextField name="IncentivesDescription" label="Incentives Description" value={form.IncentivesDescription} setValue={setForm} size="md" />
                                        <HBreak />
                                        <CurrencyField name="FinalCalculatedSalePrice" label="Final Calculated Sale Price" value={calculatedFields.finalSalePrice} setValue={setForm} size="md" readonly />
                                        <NumberField name="SquareFootageFinancial" label="Model/Elevation SF" value={form.SquareFootageFinancial} setValue={setForm} size="md" readonly />
                                        <CurrencyField name="SalePriceTotal" label="Final Sale Price $ Per SF" value={calculatedFields.finalSalePricePerSF} setValue={setForm} size="md" readonly />
                                        <span></span>
                                        <CurrencyField name="FinalGrossProfit" label="Final Gross Profit" value={calculatedFields.finalGrossProfit} setValue={setForm} size="md" readonly />
                                        <PercentField name="FinalGrossMargin" label="Final Gross Margin" value={calculatedFields.finalGrossMargin} setValue={setForm} size="md" readonly />
                                    </>

                                    :
                                    <>
                                        <CurrencyField name="SalePriceTotal" label="Total Average Sales Per Unit" value={form.SalePriceTotal} setValue={setForm} size="md" required />
                                        <CurrencyField name="GrossProfit" label="Gross Profit" value={calculatedFields.baseGrossProfit} setValue={setForm} size='md' readonly />
                                        <PercentField name="GrossMargin" label="Gross Margin" value={calculatedFields.baseGrossMargin} setValue={setForm} size='md' readonly />
                                    </>
                            }
                        </FormSection>

                        <FormSection name="Costing" triggerCollapse={triggerCollapse} hasSubSection={form.SalesForecastTypeID !== USERFORM} headers={[{ label: 'Total Cost', value: form.SalesForecastTypeID === USERFORM ? Currency(form.CostsTotal || '0') : calculatedFields.totalCost }]}>
                            {
                                form.SalesForecastTypeID !== USERFORM ?
                                    <>
                                        <FormSection name="Lot Costs" triggerCollapse={triggerCollapse} headers={[{ label: 'Total', value: calculatedFields.totalLotCost }]}>
                                            <CurrencyField name="LotAcquisitionCost" label="Lot Acquisition Cost" value={form.LotAcquisitionCost} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} required />
                                            <span></span>
                                            <PercentField name="TransactionCostToLotAcquisitionCostRatio" label="Transaction Cost % of Lot Cost" value={form.SalesForecastTypeID === CATALOGFORM ? calculatedFields.transactionCostPercentageofLotCost : form.TransactionCostToLotAcquisitionCostRatio} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} />
                                            <CurrencyField name="LotAcquisitionTransactionCost" label="Transaction Cost" value={form.SalesForecastTypeID === CATALOGFORM ? form.LotAcquisitionTransactionCost : calculatedFields.transactionCost} setValue={setForm} size="md" readonly />
                                            <CurrencyField name="OtherLotItemCost" label="Other Cost" value={form.OtherLotItemCost} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} />
                                            <TextField name="OtherLotItemDescription" label="Other Cost Description" value={form.OtherLotItemDescription} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} />
                                            <PercentField name="OtherLotItemCostRatio" label="Other Cost % of Lot Cost" value={calculatedFields.otherCostPercentageofLostCost} setValue={setForm} size="md" readonly />
                                            <span></span>
                                            <CurrencyField name="TotalLotAcquisitionCost" label="Total Lot Acquisition Cost" value={form.SalesForecastTypeID === CATALOGFORM ? calculatedFields.totalLotCostCatalog : calculatedFields.totalLotCost} setValue={setForm} size="md" readonly />
                                        </FormSection>

                                        <FormSection name="Site Costs" triggerCollapse={triggerCollapse} headers={[{ label: 'Total', value: calculatedFields.totalSiteWork }]}>
                                            <CurrencyField name="SiteWorkItemOneCost" label="Site Work Item #1 Cost" value={form.SiteWorkItemOneCost} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} />
                                            <TextField name="SiteWorkItemOneDescription" label="Site Work Item #1 Description" value={form.SiteWorkItemOneDescription} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} />
                                            <CurrencyField name="SiteWorkItemTwoCost" label="Site Work Item #2 Cost" value={form.SiteWorkItemTwoCost} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} />
                                            <TextField name="SiteWorkItemTwoDescription" label="Site Work Item #2 Description" value={form.SiteWorkItemTwoDescription} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} />
                                            <CurrencyField name="TotalSiteWork" label="Total Site Work" value={calculatedFields.totalSiteWork} setValue={setForm} size="md" readonly />
                                        </FormSection>

                                        <FormSection name="Direct Costs" triggerCollapse={triggerCollapse} headers={[{ label: 'Total', value: calculatedFields.totalDirectCost }]}>
                                            <CurrencyField name="BaseDirectCost" label="Base Direct Cost (Model/Elevation)" value={form.BaseDirectCost} setValue={setForm} size="md" readonly required />
                                            <span></span>
                                            <CurrencyField name="OtherDirectOneCost" label="Other Direct Cost #1" value={form.OtherDirectOneCost} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} />
                                            <TextField name="OtherDirectOneDescription" label="Other Direct Cost #1 Description" value={form.OtherDirectOneDescription} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} />
                                            <CurrencyField name="OtherDirectTwoCost" label="Other Direct Cost #2" value={form.OtherDirectTwoCost} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} />
                                            <TextField name="OtherDirectTwoDescription" label="Other Direct Cost #2 Description" value={form.OtherDirectTwoDescription} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} />
                                            <CurrencyField name="IncludedOptionCosts" label="Included Options" value={form.IncludedOptionCosts} setValue={setForm} size="md" readonly required />
                                            <span></span>
                                            <PercentField name="OptionSalesMarginFraction" label="Average Margin Option %" value={form.OptionSalesMarginFraction} setValue={setForm} size="md" required />
                                            <CurrencyField name="OptionDirectCost" label="Option Direct Cost" value={calculatedFields.optionDirectCost} setValue={setForm} size="md" readonly />
                                            <CurrencyField name="TotalDirectCost" label="Total Direct Cost" value={calculatedFields.totalDirectCost} setValue={setForm} size="md" readonly />
                                            <CurrencyField name="CostPerSFSumofDirectCostPerSF" label="Cost Per SF (Sum of Direct Cost per SF)" value={calculatedFields.costPerSF} setValue={setForm} size="md" readonly />
                                        </FormSection>

                                        <FormSection name="Indirect Costs" triggerCollapse={triggerCollapse} headers={[{ label: 'Total', value: form.SalesForecastTypeID === CATALOGFORM ? form.IndirectCostsTotal : calculatedFields.totalIndirectCost }]}>
                                            <PercentField name="ListingCommissionCostToBasePriceRatio" label="Listing Commission % of Base Price" value={form.SalesForecastTypeID === CATALOGFORM ? calculatedFields.listingCommissionPercentageOfBasePrice : form.ListingCommissionCostToBasePriceRatio} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} />
                                            <CurrencyField name="ListingCommission" label="Listing Commission" value={form.SalesForecastTypeID === CATALOGFORM ? form.ListingCommissionCost : calculatedFields.listingCommission} setValue={setForm} size="md" readonly />
                                            <CurrencyField name="InternalCommissionCost" label="Internal Commission" value={form.InternalCommissionCost} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} />
                                            <PercentField name="InternalCommissionPercentofSalesPrice" label="Internal Commission % of Sales Price" value={calculatedFields.internalCommissionPercentageOfSalePrice} setValue={setForm} size="md" readonly />
                                            <PercentField name="SellingCommissionCostToSalePriceRatio" label="Selling Commission % of Sales Price" value={form.SalesForecastTypeID === CATALOGFORM ? calculatedFields.sellingCommissionPercentageOfSalePrice : form.SellingCommissionCostToSalePriceRatio} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} />
                                            <CurrencyField name="SellingCommission" label="Average Selling Commission" value={form.SalesForecastTypeID === CATALOGFORM ? form.SellingCommissionCost : calculatedFields.sellingCommission} setValue={setForm} size="md" readonly />
                                            <PercentField name="SellerAgentSalesFraction" label="Sales % With Seller Agent" value={form.SellerAgentSalesFraction} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} />
                                            <HBreak />
                                            <PercentField name="TransferStampCostToSalePriceRatio" label="Transfer Stamp % of Sales Price" value={form.SalesForecastTypeID === CATALOGFORM ? calculatedFields.transferStampPercentageOfSalePrice : form.TransferStampCostToSalePriceRatio} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} />
                                            <CurrencyField name="TransferStamp" label="Transfer Stamp" value={form.SalesForecastTypeID === CATALOGFORM ? form.TransferStampCost : calculatedFields.transferStamp} setValue={setForm} size="md" readonly />
                                            <PercentField name="FinanceCostToLotSiteDirectCostsSumRatio" label="Finance Cost % of Lot, Site, & Direct" value={form.SalesForecastTypeID === CATALOGFORM ? calculatedFields.financeCostPercentageOfLotSiteAndDirect : form.FinanceCostToLotSiteDirectCostsSumRatio} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} />
                                            <CurrencyField name="FinanceCost" label="Finance Cost" value={form.SalesForecastTypeID === CATALOGFORM ? form.FinanceCost : calculatedFields.financeCost} setValue={setForm} size="md" readonly />
                                            <PercentField name="DispositionTransactionCostToSalePriceRatio" label="Disposition Trans. Cost % of Sale Price" value={form.DispositionTransactionCostToSalePriceRatio} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} />
                                            <CurrencyField name="DispositionTransactionCost" label="Disposition Transaction Cost" value={calculatedFields.dispositionTransactionCost} setValue={setForm} size="md" readonly />
                                            <PercentField name="IndirectOtherCostToSalePriceRatio" label="Indirect Other Cost % of Sales Price" value={form.SalesForecastTypeID === CATALOGFORM ? calculatedFields.indirectOtherCostsPercentageOfSalePrice : form.IndirectOtherCostToSalePriceRatio} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} />
                                            <TextField name="IndirectOtherDescription" label="Indirect Other Cost Description" value={form.IndirectOtherDescription} setValue={setForm} size="md" readonly={form.SalesForecastTypeID === CATALOGFORM} />
                                            <CurrencyField name="IndirectOtherCost" label="Indirect Other Costs" value={form.SalesForecastTypeID === CATALOGFORM ? form.IndirectOtherCosts : calculatedFields.indirectOtherCosts} setValue={setForm} size="md" readonly />
                                            <span></span>
                                            <CurrencyField name="IndirectCostsTotal" label="Total Indirect" value={form.SalesForecastTypeID === CATALOGFORM ? form.IndirectCostsTotal : calculatedFields.totalIndirectCost} setValue={setForm} size="md" readonly />
                                        </FormSection>
                                    </>
                                    :
                                    <CurrencyField name="CostsTotal" label="Total Average Costs Per Unit" value={form.CostsTotal} setValue={setForm} size="md" required />
                            }
                        </FormSection>

                        <FormSection name="Absorption" triggerCollapse={triggerCollapse} headers={[{ label: 'Sales per Year', value: calculatedFields.salesPerYear }]}>
                            <DateField name="NextSaleDate" label="Next Expected Sale Date" value={form.NextSaleDate} setValue={setForm} size="md" />
                            <NumberField name="UnitsSoldPerMonth" label="Absorption Rate (Sales per Month)" value={form.UnitsSoldPerMonth} setValue={setForm} required size="md" />
                            <NumberField name="UnitsRemaining" label="Units Remaining" value={form.UnitsRemaining} setValue={setForm} size="md" readonly />
                        </FormSection>

                        <FormSection name="Timing" triggerCollapse={triggerCollapse} headers={[{ label: 'Days Under Contract', value: calculatedFields.daysFromContractToSettlement }]}>
                            <NumberField name="SaleStartIntervalDays" label="Average Days Sale to Start" value={form.SaleStartIntervalDays} setValue={setForm} size="md" required />
                            <NumberField name="StartIntervalDays" label="Start Interval Days" value={form.StartIntervalDays} setValue={setForm} size="md" required />
                            <NumberField name="StartSettlementIntervalDays" label="Average Days Start to Settlement" value={form.StartSettlementIntervalDays} setValue={setForm} size="md" required />
                            <NumberField name="SettlementIntervalDays" label="Settlement Interval Days" value={form.SettlementIntervalDays} setValue={setForm} size="md" required />
                            <NumberField name="DaysFromContractToSettlement" label="Days From Contract to Settlement" value={calculatedFields.daysFromContractToSettlement} setValue={setForm} size="md" readonly />
                        </FormSection>

                        <FormSection name="Financing" triggerCollapse={triggerCollapse} headers={[{ label: 'Deposit', value: `${form.DepositFraction || 0}%` }]}>
                            <SelectField name="FinanceTypeID" label="Default Finance Type" value={form.FinanceTypeID} setValue={setForm} size="md" options={[{ name: 'End Loan', value: '1' }, { name: 'Construction Loan', value: '2' }]} />
                            <PercentField name="DepositFraction" label="Home Building Deposit % of Total Sale Price" value={form.DepositFraction} setValue={setForm} size="md" required />
                        </FormSection>

                        {/* spacing placeholder */}
                        <div className=" min-w-full w-full  min-h-[0px] pb-2"></div>
                    </div>

                    {/* delete / duplicate modal */}
                    {
                        (mode === 'delete' || mode === 'duplicate') && <Modal className="bg-md-background rounded-lg" onClose={() => setMode('edit')}>
                            <div className=" w-[340px] h-[140px] flex flex-col gap-y-4 p-4 rounded-lg ">
                                <span className="text-lg font-bold">{mode === 'delete' ? 'Are you sure you want to delete this record?' : ' Are you sure you want to duplicate this record?'}</span>
                                <div className="flex gap-x-4 justify-end">
                                    <Button label="Cancel" styleType="text" action={() => setMode('edit')} type="button" />
                                    <Button label={mode === 'delete' ? 'Delete' : 'Duplicate'} styleType="text" action={() => null} type="submit" />
                                </div>
                            </div>
                        </Modal>
                    }
                </form>


                {/* insights and impact */}
                <div className="flex border z-10 border-y-0 border-md-on-background bg-md-background xl:flex-col xl:flex-grow xl:h-full xl:min-w-[400px] xl:max-w-[600px]  min-h-[200px] w-full sticky bottom-0 xl:sticky xl:bottom-0">
                    {/* insights */}
                    <div className="flex flex-col xl:border border-y border-md-on-background xl:border-t-0 w-full xl:h-1/2">
                        <div className="flex flex-row bg-md-primary-container h-min w-full px-2 py-1 title-small">Insights</div>
                        <span className="flex flex-row title-large m-auto">Coming soon</span>
                    </div>

                    {/* impact */}
                    <div className="flex flex-col  xl:border border-y border-l border-md-on-background w-full xl:h-1/2 ">
                        <div className="flex flex-row bg-md-primary-container h-min w-full px-2 py-1 title-small">Impacts</div>
                        <span className="flex flex-row title-large m-auto">Coming soon</span>
                    </div>

                </div>
            </div >

            {
                isLoading ?
                    <>
                        <div className="font-bold  absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 " >
                            <svg className=" animate-spin inline-block w-20 h-20 border-[3px] border-current border-t-transparent text-blue-600 rounded-full " viewBox="0 0 24 24" />
                        </div>

                        <div className="h-full w-full flex flex-col flex-grow fixed z-30 bg-gray-400 opacity-20 items-center"></div>
                    </>
                    :
                    <></>
            }
            <SnackBar message={snackbarMessage} />
        </div >
    )
}



