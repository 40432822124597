import { Outlet } from "react-router-dom";
import HeaderTop from "./HeaderTop";
import HelpOffcanvas from "./HelpOffcanvas";
import { useState } from "react";
import HeaderNavigation from "./HeaderNavigation";

export default function Layout() {
  const [helpOffcanvasOpen, setHelpOffcanvasOpen] = useState(false)

  return (
    <>
      <div className=" h-screen overflow-hidden">
        <div className="flex-row">
          <HeaderTop helpOffcanvasOpen={helpOffcanvasOpen} setHelpOffcanvasOpen={setHelpOffcanvasOpen} />
          <HeaderNavigation />
        </div>
        <div className={`grid h-[calc(100%_-_80px)] ${helpOffcanvasOpen ? 'grid-cols-[auto_400px]' : 'grid-cols-[auto_0px]'}  `}>
          <div className="overflow-y-auto overflow-x-hidden bg-md-background h-full">
            <Outlet />
          </div>
          <HelpOffcanvas isOpen={helpOffcanvasOpen} setIsOpen={setHelpOffcanvasOpen} />
        </div>
      </div>
    </>
  );
}
