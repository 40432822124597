import { BaseFieldProps } from "../../components/atoms/forms/SimpleFields/BaseField"

interface FormTypeFieldProps extends BaseFieldProps {
    options: { label: string, value: string }[]
    resetTo: any
}

export default function FormTypeField(props: FormTypeFieldProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setValue({ ...props.resetTo, [props.name]: e.target.value })

    }

    return (
        <>
            <fieldset className={`flex gap-x-3   ${props.size === "md" ? "w-[250px]" : "w-[540px]"} h-[48px] body-small`}>
                <legend className={`w-max h-[20px] ${props.required ? "after:content-['*'] after:ml.5" : ""}`}>{props.label}</legend>
                {
                    props.options.map((option, index) => {
                        return (
                            <div key={index} className="flex gap-x-2  h-[28px] py-1 w-max" >
                                <input
                                    name={props.name}
                                    type="radio"
                                    id={`${props.name}${option.label}`}
                                    checked={props.value === option.value}
                                    value={option.value.toString()}
                                    readOnly={props.readonly}
                                    disabled={props.disabled}
                                    onChange={handleChange}
                                    onBlur={props.onBlur}
                                    onKeyDown={props.onKeydown}
                                    onKeyUp={props.onKeyUp}
                                    onFocus={props.onFocus}
                                />
                                <label htmlFor={`${props.name}${option.label}`} className="my-auto mr-1">{option.label}</label>
                            </div>
                        )
                    })
                }
            </fieldset>
        </>
    )
}