import { UseFormRegisterReturn, UseFormSetValue, UseFormWatch } from "react-hook-form"
import NumberField from "../../components/atoms/forms/Fields/NumberField"
import { useEffect, useState } from "react"

type DaystoStartType = {
    name: string,
    label: string,
    supportingText?: string,
    disabled?: boolean,
    required?: boolean,
    value: string | undefined,
    formRef: UseFormRegisterReturn<string>
    setValue: UseFormSetValue<any>
    watch: UseFormWatch<any>
}

export default function SaletoStart(props: DaystoStartType){
    const [isAttached, setIsAttached] = useState(false)
    const watchAttached = props.watch("UnitTypeID")
    //see if attached is selected and siable if so 

    useEffect(()=> setIsAttached(watchAttached === '1' ? true : false), [watchAttached])

    return(<NumberField {...props} disabled={props.disabled || isAttached}/>)
}