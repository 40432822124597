import { useEffect, useMemo, useState } from "react";
import Button from "../../components/atoms/Button";
import React from "react";
import TextField from "../../components/atoms/forms/SimpleFields/TextField";
import CurrencyField from "../../components/atoms/forms/SimpleFields/CurrencyField";
import PercentField from "../../components/atoms/forms/SimpleFields/PercentField";
import NumberField from "../../components/atoms/forms/SimpleFields/NumberField";
import Modal from "../../components/atoms/Modal";
import Report from "./Report";
import RequestAI from "./RequestAI";


export type JVSpec = {
    // General
    DealName: string,
    Description: string,
    // Revenue
    HouseEstimatedSellPrice: string,
    // Costing
    LandPurchase: string,
    SiteImprovements: string,
    Engineering: string,
    DirectConstruction: string,

    AcquisitionAndFinanceTransactionCostsPercent: string,
    CommercialBorrowingInterestRatePercent: string,

    BuildersFeePercent: string,
    ListingCommissionPercent: string,
    SellingCommisionPercent: string,

    // schedule
    MonthsHoldBedoreAcquisition: string,
    MonthsHoldBeforeConstruction: string,
    MonthsConstruction: string,
    MonthsHoldUntilConstruction: string
    // capital
    LoanGuaranteeFeeToInvestorPercent: string,
    InterestOnInvestmentCashToInvestorPercent: string,
    InvestorCash: string,
    BuilderCash: string,
    MinimumIRR: string,
    MaximumIRR: string

}

const JVSpecDefaultValues: JVSpec = {
    DealName: "",
    Description: "",

    HouseEstimatedSellPrice: "",

    LandPurchase: "",
    SiteImprovements: "",
    Engineering: "",
    DirectConstruction: "",

    AcquisitionAndFinanceTransactionCostsPercent: "",
    CommercialBorrowingInterestRatePercent: "",

    BuildersFeePercent: "",
    ListingCommissionPercent: "",
    SellingCommisionPercent: "",

    MonthsHoldBedoreAcquisition: "",
    MonthsHoldBeforeConstruction: "",
    MonthsConstruction: "",
    MonthsHoldUntilConstruction: "",

    LoanGuaranteeFeeToInvestorPercent: "",
    InterestOnInvestmentCashToInvestorPercent: "",
    InvestorCash: "",
    BuilderCash: "",
    MinimumIRR: "",
    MaximumIRR: ""
}

type PageNumberType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9

const progessBarNextThreshholds = [2, 3, 6, 7, 9]
const progressBarPreviousThreshholds = [1, 2, 5, 6, 8]

type ProgressType = {
    section: number,
    page: PageNumberType
}

export default function JVSpec() {
    const [progress, setProgress] = useState<ProgressType>({ section: 0, page: 0 });
    const [isNextValid, setIsNextValid] = useState(false);
    const [form, setForm] = useState<JVSpec>(JVSpecDefaultValues)

    const [isReportOpen, setIsReportOpen] = useState(false)
    const componentsMap = useMemo(() => {
        return {
            0: <GeneralPage1 setIsNextValid={setIsNextValid} />,
            1: <GeneralPage2 form={form} setForm={setForm} setIsNextValid={setIsNextValid} />,
            2: <RevenuePage form={form} setForm={setForm} setIsNextValid={setIsNextValid} />,
            3: <CostingPage1 form={form} setForm={setForm} setIsNextValid={setIsNextValid} />,
            4: <CostingPage2 form={form} setForm={setForm} setIsNextValid={setIsNextValid} />,
            5: <CostingPage3 form={form} setForm={setForm} setIsNextValid={setIsNextValid} />,
            6: <SchedulePage form={form} setForm={setForm} setIsNextValid={setIsNextValid} />,
            7: <CapitalPage1 form={form} setForm={setForm} setIsNextValid={setIsNextValid} />,
            8: <CapitalPage2 form={form} setForm={setForm} setIsNextValid={setIsNextValid} />,
            9: <CompletePage />
        }
    }, [form, setForm, progress]);

    const handleNext = () => {
        setProgress((prev: ProgressType) => {
            const newPage = (prev.page + 1) as PageNumberType;
            const newSection = progessBarNextThreshholds.includes(newPage) ? prev.section + 1 : prev.section;
            return { section: newSection, page: newPage };
        });
    }

    const handlePrevious = () => {
        setProgress((prev: ProgressType) => {
            const newPage = (prev.page - 1) as PageNumberType;
            const newSection = progressBarPreviousThreshholds.includes(newPage) ? prev.section - 1 : prev.section;
            return { section: newSection, page: newPage };
        })
    }

    return (
        <div className="flex w-full h-full ">
            <main className=" h-full flex-grow m-auto max-w-[1140px] overflow-auto py-10 ">
                <header className="grid grid-cols-2 w-[580px] mx-auto h-[144px] flex-wrap">
                    <h1 className="flex title-large items-center ">
                        Joint Venture Spec Tool
                    </h1>

                    <aside className="flex w-full 2xl:hidden justify-end">
                        <Button label="Report" action={() => setIsReportOpen(true)} styleType="tonal" type="button" />
                    </aside>

                    <div className="w-full col-span-2 justify-center flex ">
                        <CheckMarkProgress key='CheckMarkProgress' progress={progress.section} steps={["General", "Revenue", "Costing", "Schedule", "Capital"]} />
                    </div>

                </header>

                <form className="w-[580px] mx-auto" >
                    {componentsMap[progress.page] || <div>Error</div>}
                </form>

                <section className=" flex mx-auto w-[580px] pt-10">
                    <span className="m-auto ml-0">
                        {progress.page > 0 && <Button label="Previous" action={handlePrevious} styleType="tonal" type="button" />}
                    </span>

                    <span className="m-auto mr-0">
                        {progress.page < 9 && <Button label="Next" action={handleNext} styleType="tonal" disabled={!isNextValid} type="button" />}
                    </span>
                </section>

            </main>


            <aside className=" hidden 2xl:flex flex-grow h-full w-full max-w-[700px] overflow-auto p-8 bg-gray-100">
                <Report form={form} />
            </aside>

            {
                isReportOpen && (
                    <Modal
                        className="w-full max-w-[720px] p-10 m-20 max-h-[700px] h-full bg-gray-100 overflow-auto rounded-lg"
                        onClose={() => setIsReportOpen(false)}>
                        <Report form={form} />
                    </Modal>)
            }
        </div>
    )
}

function CheckMarkProgress(props: { progress: number, steps: string[] }) {
    const width = `${props.steps.length * 122 + 5 - 30}px`; //steps * (circle width + line width) + padding - line width

    return (
        <span className="flex flex-wrap">
            <svg width={width} height="100px" viewBox={`0 0 ${props.steps.length * 122 + 5 - 80} 100`} xmlns="http://www.w3.org/2000/svg">
                {

                    // Generate lines first
                    props.steps.map((step, index) => (
                        props.steps.length > index + 1
                        &&
                        <line
                            x1={40 + index * 122 + 5}
                            y1="30"
                            x2={42 + index * 122 + 80 + 5}
                            y2="30"
                            stroke={index + 1 > props.progress ? '#d3d3d3' : '#66bb6a'}
                            strokeWidth="2"
                            key={`line-${index}`}
                        />
                    ))
                }
                {
                    // Generate circles and text
                    props.steps.map((step, index) => (
                        <React.Fragment key={`circle-text-${index}`}>
                            <circle
                                cx={index * 122 + 20 + 5}
                                cy="30"
                                r="20"
                                fill={index > props.progress ? '#d3d3d3' : '#66bb6a'}
                                stroke={index > props.progress ? '#d3d3d3' : '#66bb6a'}
                                strokeWidth="2" fillOpacity={props.progress > index ? 1 : 0}
                                key={`circle-${index}`}

                            />
                            {props.progress > index && <path
                                d={`M${12 + index * 122 + 5},30 L${18 + index * 122 + 5},36 L${30 + index * 122 + 5},24`}
                                stroke="white"
                                strokeWidth="2"
                                fill="none"
                                key={`check-${index}`}
                            />}
                            <text
                                x={index * 122 + 20 + 5}
                                y="70"
                                textAnchor="middle"
                                fontSize="15"
                                fill="#000"
                                key={`text-${index}`}
                            >

                                {step}
                            </text>
                        </React.Fragment>
                    ))
                }
            </svg>
        </span>
    )
}

function InputDescription({ text }: { text: string }) {
    return <p className="body-small">{text}</p>
}

function GeneralPage1({ setIsNextValid }: { setIsNextValid: React.Dispatch<React.SetStateAction<boolean>> }) {

    useEffect(() => setIsNextValid(true), [])

    return (
        <div className="flex flex-grow flex-wrap text-wrap overflow-auto  w-[580px] min-w-[580px] body-small">

            <div className="text-wrap">
                <p>
                    The Joint Venture Spec Tool simplifies builder-investor collaboration for the financing and construction of a spec home by providing a comprehensive view of crucial financial data and anticipated outcomes. It equips home builders to attract and engage investors by collecting key inputs such as sales price, construction costs, project schedule, capital contributions, and profit distribution. By promoting transparency, the tool helps both parties gain a clear understanding of capital requirements and potential returns.
                </p>
                <br />
                <p>
                    To ensure a smooth experience, please gather the following information before you begin:
                </p>

                <ul className="list-disc p-5 grid grid-cols-2 gap-x-4 text-nowrap">
                    <li>Deal Name</li>
                    <li>Description</li>
                    <li>House Estimated Sell Price</li>
                    <li>Land Purchase</li>
                    <li>Site Improvements</li>
                    <li>Engineering</li>
                    <li>Direct Construction</li>
                    <li>Builders Fee %</li>
                    <li>Listing Commission %</li>
                    <li>Selling Commission %</li>
                    <li>Months Hold Before Construction</li>
                    <li>Months Construction</li>
                    <li>Months Hold Until Construction</li>
                    <li>Investor Cash</li>
                    <li>Builder Cash</li>
                    <li>Minimum IRR</li>
                    <li>Maximum IRR</li>
                    <li className="col-span-2">Acquisition and Finance Transaction Costs %</li>
                    <li className="col-span-2">Commercial Borrowing Interest Rate %</li>
                    <li className="col-span-2">Interest On Investment Cash to Investor %</li>

                </ul>

            </div>
        </div>
    )
}

function GeneralPage2({ form, setForm, setIsNextValid }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, setIsNextValid: React.Dispatch<React.SetStateAction<boolean>> }) {

    useEffect(() => {
        if (form.DealName && form.Description) {
            setIsNextValid(true);
        } else {
            setIsNextValid(false);
        }
    }, [form]);

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <TextField name="DealName" label="Deal Name" size="md" value={form.DealName} setValue={setForm} autoFocus />
            <InputDescription text="Enter a unique and descriptive name for the joint venture project. This name should help you and the investor easily identify and reference the project in all communications and documents." />
            <TextField name="Description" label="Description" size="md" value={form.Description} setValue={setForm} />
            <InputDescription text="Provide a brief overview of the project, including details such as the type of spec home, its location, key features, and overall objectives. This description will help both you and the investor understand the scope and vision of the venture." />
        </div>
    );
}

function RevenuePage({ form, setForm, setIsNextValid }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, setIsNextValid: React.Dispatch<React.SetStateAction<boolean>> }) {
    useEffect(() => {
        if (form.HouseEstimatedSellPrice) {
            setIsNextValid(true);
        } else {
            setIsNextValid(false);
        }
    }, [form]);

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <CurrencyField name="HouseEstimatedSellPrice" label="House Estimated Sell Price" size="md" value={form.HouseEstimatedSellPrice} setValue={setForm} autoFocus />
            <InputDescription text="Input the projected selling price of the home once it's completed. This estimate should reflect current market conditions and expected value based on similar properties in the area." />
        </div>
    );
}

function CostingPage1({ form, setForm, setIsNextValid }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, setIsNextValid: React.Dispatch<React.SetStateAction<boolean>> }) {
    useEffect(() => {
        if (form.LandPurchase && form.SiteImprovements && form.Engineering && form.DirectConstruction) {
            setIsNextValid(true);
        } else {
            setIsNextValid(false);
        }
    }, [form]);

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <h5 className="col-span-2 border-b-2 font-bold">Land Acquisition</h5>
            <CurrencyField name="LandPurchase" label="Land Purchase" size="md" value={form.LandPurchase} setValue={setForm} autoFocus />
            <InputDescription text="Enter the estimated cost of acquiring the land where the spec home will be built. This includes the price of the land itself and any associated purchase fees." />
            <h5 className="col-span-2 border-b-2 font-bold">Construction Costs</h5>
            <CurrencyField name="SiteImprovements" label="Site Improvements" size="md" value={form.SiteImprovements} setValue={setForm} />
            <InputDescription text="Provide the estimated costs for any improvements required on the land before construction can begin. This might include grading, utility hookups, or other preparatory work." />
            <CurrencyField name="Engineering" label="Engineering" size="md" value={form.Engineering} setValue={setForm} />
            <InputDescription text="Input the estimated costs for engineering services needed for the project. This can include site surveys, structural plans, and other technical assessments." />
            <CurrencyField name="DirectConstruction" label="Direct Construction" size="md" value={form.DirectConstruction} setValue={setForm} />
            <InputDescription text="Enter the estimated costs directly related to the construction of the home, including materials and labor. This should cover all aspects of building the house." />
        </div>
    );
}

function CostingPage2({ form, setForm, setIsNextValid }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, setIsNextValid: React.Dispatch<React.SetStateAction<boolean>> }) {

    const systemPromt = "You are an expert in modern home building investments. You have vast and insightful knowledge about how joint venture deals between investors and home builders work. The user will ask what a good commercial borrowing interest rate is based on the current market conditions. Provide a recommendation based on the current market conditions. Keep this response very short."
    const userPrompt = `What is a good commercial borrowing interest rate for a joint venture deal between an investor and a home builder right now?`


    useEffect(() => {
        if (form.AcquisitionAndFinanceTransactionCostsPercent && form.CommercialBorrowingInterestRatePercent) {
            setIsNextValid(true);
        } else {
            setIsNextValid(false);
        }
    }, [form]);

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <h5 className="col-span-2 border-b-2 mb-1 font-bold">Financing</h5>
            <PercentField name="AcquisitionAndFinanceTransactionCostsPercent" label="Acquisition and Finance Transaction Costs %" size="md" value={form.AcquisitionAndFinanceTransactionCostsPercent} setValue={setForm} autoFocus />
            <InputDescription text="Specify the percentage of the total project cost that will be allocated to acquisition and financing transactions. This percentage includes costs related to securing the land and financing the project." />
            <PercentField name="CommercialBorrowingInterestRatePercent" label="Commercial Borrowing Interest Rate %" size="md" value={form.CommercialBorrowingInterestRatePercent} setValue={setForm} aiAction={async () => RequestAI(systemPromt, userPrompt)} />
            <InputDescription text="Input the interest rate for any commercial loans or lines of credit used to finance the project. This rate affects the cost of borrowing and the overall financial performance of the venture." />
        </div>
    );
}

function CostingPage3({ form, setForm, setIsNextValid }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, setIsNextValid: React.Dispatch<React.SetStateAction<boolean>> }) {
    useEffect(() => {
        if (form.BuildersFeePercent && form.ListingCommissionPercent && form.SellingCommisionPercent) {
            setIsNextValid(true);
        } else {
            setIsNextValid(false);
        }
    }, [form]);

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <h5 className="col-span-2 border-b-2 font-bold">Soft Costs</h5>
            <PercentField name="BuildersFeePercent" label="Builders Fee %" size="md" value={form.BuildersFeePercent} setValue={setForm} autoFocus />
            <InputDescription text="Enter the percentage of the project's gross revenue that will be paid to the builder as a fee. This fee compensates the builder for managing and executing the project." />
            <PercentField name="ListingCommissionPercent" label="Listing Commission %" size="md" value={form.ListingCommissionPercent} setValue={setForm} />
            <InputDescription text="Specify the percentage of the selling price that will be paid to the listing agent for marketing and selling the property." />
            <PercentField name="SellingCommisionPercent" label="Selling Commission %" size="md" value={form.SellingCommisionPercent} setValue={setForm} />
            <InputDescription text="Input the percentage of the selling price that will be paid to the selling agent. This commission covers the cost of securing the buyer and closing the sale." />
        </div>
    );
}

function SchedulePage({ form, setForm, setIsNextValid }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, setIsNextValid: React.Dispatch<React.SetStateAction<boolean>> }) {
    useEffect(() => {
        if (form.MonthsHoldBeforeConstruction && form.MonthsConstruction && form.MonthsHoldUntilConstruction) {
            setIsNextValid(true);
        } else {
            setIsNextValid(false);
        }
    }, [form]);

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <h5 className="col-span-2 border-b-2 font-bold">Project Schedule</h5>
            {/* <NumberField name="MonthsHoldBedoreAcquisition" label="Months Hold Before Acquisition" size="md" value={form.MonthsHoldBedoreAcquisition} setValue={setForm} />
            <PlaceholderText /> */}
            <NumberField name="MonthsHoldBeforeConstruction" label="Months Hold Before Construction" size="md" value={form.MonthsHoldBeforeConstruction} setValue={setForm} autoFocus />
            <InputDescription text="Enter the number of months you anticipate holding the property before beginning construction. This period accounts for any delays or preparation time needed before starting the build." />
            <NumberField name="MonthsConstruction" label="Months Construction" size="md" value={form.MonthsConstruction} setValue={setForm} />
            <InputDescription text="Input the estimated duration of the construction phase in months. This should include all time required to complete the building from start to finish." />
            <NumberField name="MonthsHoldUntilConstruction" label="Months Hold Until Construction" size="md" value={form.MonthsHoldUntilConstruction} setValue={setForm} />
            <InputDescription text="Specify the number of months you expect to hold the property after construction is completed but before it is sold. This period may include time for finalizing details or waiting for the right market conditions." />
        </div>
    );
}

function CapitalPage1({ form, setForm, setIsNextValid }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, setIsNextValid: React.Dispatch<React.SetStateAction<boolean>> }) {
    useEffect(() => {
        if (form.InterestOnInvestmentCashToInvestorPercent && form.InvestorCash && form.BuilderCash) {
            setIsNextValid(true);
        } else {
            setIsNextValid(false);
        }
    }, [form]);

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <h5 className="col-span-2 border-b-2 font-bold">Capital Stack</h5>
            {/* <PercentField name="LoanGuaranteeFeeToInvestorPercent" label="Loan Guarantee Fee To Investor %" size="md" value={form.LoanGuaranteeFeeToInvestorPercent} setValue={setForm} />
            <PlaceholderText /> */}
            <PercentField name="InterestOnInvestmentCashToInvestorPercent" label="Interest On Investment Cash To Investor %" size="md" value={form.InterestOnInvestmentCashToInvestorPercent} setValue={setForm} autoFocus />
            <InputDescription text="Enter the percentage of the investment cash that will be paid to the investor as interest. This rate reflects the return on the investor's capital." />
            <CurrencyField name="InvestorCash" label="Investor Cash" size="md" value={form.InvestorCash} setValue={setForm} />
            <InputDescription text="Input the total amount of cash the investor is contributing to the project. This represents the investor's financial commitment to the venture." />
            <CurrencyField name="BuilderCash" label="Builder Cash" size="md" value={form.BuilderCash} setValue={setForm} />
            <InputDescription text="Specify the total amount of cash the builder is contributing to the project. This represents the builder's financial stake in the venture." />
        </div>
    );
}

function CapitalPage2({ form, setForm, setIsNextValid }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, setIsNextValid: React.Dispatch<React.SetStateAction<boolean>> }) {
    useEffect(() => {
        if (form.MinimumIRR && form.MaximumIRR) {
            setIsNextValid(true);
        } else {
            setIsNextValid(false);
        }
    }, [form]);

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <h5 className="col-span-2 border-b-2 font-bold">Capital Stack</h5>
            <PercentField name="MinimumIRR" label="Minimum IRR" size="md" value={form.MinimumIRR} setValue={setForm} autoFocus />
            <InputDescription text="Enter the minimum internal rate of return (IRR) expected for the project. This is the lowest return rate that the project needs to achieve to be considered viable or attractive to investors." />
            <PercentField name="MaximumIRR" label="Maximum IRR" size="md" value={form.MaximumIRR} setValue={setForm} />
            <InputDescription text="Input the maximum internal rate of return (IRR) anticipated for the project. This represents the highest return rate expected, reflecting the best-case financial outcome of the venture." />
        </div>
    );
}

function CompletePage() {
    return (
        <div className="flex flex-grow flex-wrap text-wrap p-5 ">
            <p className="m-auto"> Congratulations, you are done!</p>
        </div>
    )
}

