import { Link } from "react-router-dom";
import productMainPhoto from '../../assets/productMainPhoto.jpg'

export default function ProductMain(): JSX.Element {

  return (
    <>
      <div className="w-screen h-screen overflow-hidden ">

        {/* Nav Bar */}
        <div className="w-full border-b-2 flex justify-center">
          <nav className=" max-w-[2400px] w-full grid grid-cols-6 py-3 px-10">
            <span className="col-span-1 font-bold text-4xl ">BuildExecPro</span>
            <span className="col-span-4 "></span>
            <span className="col-span-1 flex items-center justify-end text-lg "><Link className=" text-blue-500 underline" to="/signin">Sign in</Link></span>
          </nav>
        </div>

        {/* Content */}
        <div className=" min-h-full max-w-[2400px] mx-auto items-center justify-center flex flex-wrap px-10">

          {/* text and image */}
          <div className="w-100 lg:grid grid-cols-2 items-center gap-8">
            {/* text */}
            <div className="w-full c">
              <p className="text-3xl font-bold mb-3">
              BuildExecPro: Empowering Home Builders with Precision and Profitability
              </p>
              <p >
              BuildExecPro is a powerful web-based application designed to empower Production Home Builders to achieve sustainable growth and profitability. Our comprehensive suite of modules addresses the unique challenges faced by home builders, streamlining operations and enhancing decision-making processes through advanced data management and predictive analytics.               </p>
            </div>

            {/*  image */}
            <img src={productMainPhoto} alt="business man" className="hidden lg:block rounded-xl" />

          </div>
        </div>
      </div>

    </>
  );
}
