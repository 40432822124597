import { useEffect, useState } from "react"
import APIRequest from "../../helpers/CreateRequest"
import Button from "../atoms/Button"
import { Link } from "react-router-dom"


type ComplexSelectProps = {
    name: string
    namePlural: string,
    alias?: string
    aliasPlural?: string
    objID?: string
    // mode: 'add' | 'edit' | 'delete' | 'view'
}


//Initially was suppose to be generic but not fully implemented, also might not be how we perform multi-selects going forward
export default function ComplexSelect(props: ComplexSelectProps) {

    const [backup, setBackup] = useState<{ Selected: string, [key: string]: any }[]>([])
    const [runningMap, setRunningMap] = useState(new Map<string, { development: string, concept: string, isSelected: boolean }>())
    const [availableBuffer, setAvailableBuffer] = useState(new Map<string, { development: string, concept: string }>())
    const [selectedBuffer, setSelectedBuffer] = useState(new Map<string, { development: string, concept: string }>())
    const [available, setAvailable] = useState<JSX.Element[]>([])
    const [selected, setSelected] = useState<JSX.Element[]>([])
    const [message, setMessage] = useState({ className: 'opacity-0', message: '' })

    //set the runnning Map in accordance with the backup on first render and when the backup (selectedObj) changes
    useEffect(() => {
        const tempMap = new Map<string, { development: string, concept: string, isSelected: boolean }>()

        for (let i = 0; i < backup.length; i++) {
            tempMap.set(backup[i].BusinessPlanConceptID, { development: backup[i].DevelopmentName, concept: backup[i].BusinessPlanConceptNameCode, isSelected: backup[i].Selected === "False" ? false : true })
        }
        setRunningMap(tempMap)
    }, [backup])


    //whne runningMap changes the availalble and selected lists of options changes
    useEffect(() => {
        const tempSelected: JSX.Element[] = []
        const tempAvailable: JSX.Element[] = []
        const keys = runningMap.keys()

        for (const key of keys) {
            const value = runningMap.get(key) || { development: 'error', concept: 'error', isSelected: false }
            if (value.isSelected) {
                tempSelected.push(
                    <ComplexSelectOption key={`ComplexSelect${key}`} development={value.development} concept={value.concept} id={key} isSelected={true} />
                )
            } else {
                tempAvailable.push(
                    <ComplexSelectOption key={`ComplexSelect${key}`} development={value.development} concept={value.concept} id={key} isSelected={false} />
                )
            }
        }

        setAvailable(tempAvailable)
        setSelected(tempSelected)
    }, [runningMap])

    //get available/selected options and set backup when id changes but not first load (objID will be null)
    useEffect(() => {
        const GetObjs = async () => {
            if (props.objID) {
                try {
                    const result = await ((await new APIRequest(`/${props.namePlural}/availableSelected`, "POST", null, { id: props.objID }).GenerateRequest()).json())
                    setBackup(result.recordset)

                    console.log(result)
                }
                catch (err) {
                    console.log(err)
                }
            }
        }
        GetObjs()

    }, [props.objID])

    //set focused available options in running map to selected. 
    function SendAvailableToSelected() {
        const tempMap = new Map(runningMap)
        const keys = availableBuffer.keys()
        for (const key of keys) {
            const value = runningMap.get(key) || { development: 'error', concept: 'error', isSelected: false }
            tempMap.set(key, { development: value.development, concept: value.concept, isSelected: true })
        }
        setRunningMap(tempMap)
        setAvailableBuffer(new Map())
        setSelectedBuffer(new Map())
    }

    //set focused selected options in running map to available. Reset buffers
    function SendSelectedToAvailable() {
        const tempMap = new Map(runningMap)
        const keys = selectedBuffer.keys()
        for (const key of keys) {
            const value = runningMap.get(key) || { development: 'error', concept: 'error', isSelected: false }
            tempMap.set(key, { development: value.development, concept: value.concept, isSelected: false })
        }
        setRunningMap(tempMap)
        setSelectedBuffer(new Map())
        setAvailableBuffer(new Map())
    }


    //Option component
    function ComplexSelectOption({ development, concept, id, isSelected }: { development: string, concept: string, id: string, isSelected: boolean }) {
        const [focused, setFocused] = useState(false)

        //When clicked - highlight and send to buffer or unhighlight and remove from buffer
        const handleClick = () => {
            if (focused && isSelected) {
                setFocused(false)
                selectedBuffer.delete(id)
            } else if (!focused && isSelected) {
                setFocused(true)
                selectedBuffer.set(id, { development: development, concept: concept })
            } else if (focused && !isSelected) {
                setFocused(false)
                availableBuffer.delete(id)
            } else {
                setFocused(true)
                availableBuffer.set(id, { development: development, concept: concept })
            }
        }

        return (
            <div className={`grid grid-cols-2 cursor-pointer py-1 ${focused ? 'bg-blue-300' : 'hover:bg-blue-200'}`} onClick={handleClick}>
                <span className="px-2">{development}</span>
                <span className="px-2 hover:text-indigo-500 "><Link to='/app/conceptPlans' state={{id: id}}>{concept}</Link></span>
            </div>
        )
    }

    //Submit the ids of the options with isSelected === true. Ids sent as follows '1,2,3'
    async function SubmitChanges() {

        let ids = ''
        const keys = runningMap.keys()
        let i = 0
        for (const key of keys) {
            if (runningMap.get(key)?.isSelected) {
                ids = (i++ === 0 ? '' : ids.concat(','))
                ids = ids.concat(`${key}`)
            }
        }

        const result = ((await new APIRequest(`/${props.namePlural}/insertSelected`, "POST", null, { id: props.objID, ids: ids }).GenerateRequest()))

        if (result.status === 200) {
            setTimeout(() => setMessage({ className: 'text-green-300 opacity-0', message: 'Success' }), 2000)
            setTimeout(() => setMessage({ className: 'opacity-0', message: '' }), 4000)
            setMessage({ className: 'text-green-300 opacity-100', message: 'Success' })
        } else {
            setTimeout(() => setMessage({ className: 'text-red-300 opacity-0', message: 'Error' }), 2000)
            setTimeout(() => setMessage({ className: 'opacity-0', message: '' }), 4000)
            setMessage({ className: 'text-red-300 opacity-100', message: 'Error' })
        }
    }


    return (
        <>

            {/* container */}
            <div className="h-full text-center border-t-4 border-black pt-5 transition-all">
                <div className=" grid grid-cols-2 text-justify "> <span>Concept Plans</span><span className={`text-end transition-opacity cursor-default duration-300 text-md ${message.className}`}>{message.message}</span></div>

                <div className="border-2 border-black p-4 pb-0">
                    {/* top */}
                    <div className="grid grid-cols-[45%_10%_45%] h-1/6 text-center  items-center ">
                        <div>Available</div>
                        <div></div>
                        <div>Selected</div>
                    </div>


                    {/* Boxes */}

                    <div className="grid grid-cols-[45%_10%_45%] h-4/6 bg-white">
                        {/* box 1  */}
                        <div className=" border-2 border-black overflow-auto text-left max-h-96">
                            {/* table header */}
                            <div className="grid grid-cols-2 sticky top-0 bg-gray-100 ">
                                <span className="px-2">Development</span>
                                <span className="px-2">Concept</span>
                            </div>

                            {/* table body */}
                            <div className="overflow-y-auto ">
                                {available}
                            </div>

                        </div>

                        <div className="flex flex-col items-center w-4/5  text-xl">
                            <span className="w-8">
                                <Button
                                    label=">"
                                    styleType="outlined"
                                    type="button"
                                    action={SendAvailableToSelected}
                                />
                            </span>

                            <span className="w-8 ">
                                <Button
                                    label="<"
                                    styleType="outlined"
                                    type="button"
                                    action={SendSelectedToAvailable}
                                />
                            </span>

                        </div>

                        <div className="border-2 border-black overflow-auto text-left max-h-96">
                            {/* table header */}
                            <div className="grid grid-cols-2 sticky top-0 bg-gray-100">
                                <span className="px-2">Development</span>
                                <span className="px-2">Concept</span>
                            </div>

                            {/* table body */}
                            <div className="overflow-y-auto">
                                {selected}
                            </div>
                        </div>
                    </div>

                    {/* reset/save */}
                    <div className="flex h-1/6 items-center justify-center p-3">
                        <div className="flex align-middle w-1/4">
                            <Button
                                label="Reset"
                                styleType="outlined"
                                type="button"
                                action={() => setBackup([...backup])}
                                disabled={backup.length < 1}
                            />
                            <Button
                                label="Save"
                                styleType="filled"
                                type="button"
                                action={SubmitChanges}
                                disabled={backup.length < 1}
                            />
                        </div>


                    </div>

                </div>


            </div>



        </>
    )
}