import { Currency, CurrencyToNumber } from "../../helpers/InputValueConverter";
import { JVSpec } from "./JVSpec";

export default function ReportCalculations(form: JVSpec) {
  const HouseEstimatedSellPrice = CurrencyToNumber(form.HouseEstimatedSellPrice) || 0;

  const LandPurchase = CurrencyToNumber(form.LandPurchase) || 0;
  const SiteImprovements = CurrencyToNumber(form.SiteImprovements) || 0;
  const Engineering = CurrencyToNumber(form.Engineering) || 0;
  const DirectConstruction = CurrencyToNumber(form.DirectConstruction) || 0;

  const AcquisitionAndFinanceTransactionCostsPercent = parseFloat(form.AcquisitionAndFinanceTransactionCostsPercent) / 100 || 0;
  const CommercialBorrowingInterestRate = parseFloat(form.CommercialBorrowingInterestRatePercent) / 100 || 0;
  const BuilersFeePercent = parseFloat(form.BuildersFeePercent) / 100 || 0;
  const ListingCommissionPercent = parseFloat(form.ListingCommissionPercent) / 100 || 0;
  const SellingCommisionPercent = parseFloat(form.SellingCommisionPercent) / 100 || 0;

  const MonthsHoldBeforeConstruction = parseInt(form.MonthsHoldBeforeConstruction) || 0;
  const MonthsConstruction = parseInt(form.MonthsConstruction) || 0;
  const MonthsHoldUntilConstruction = parseInt(form.MonthsHoldUntilConstruction) || 0;
  const TotalMonths = MonthsHoldBeforeConstruction + MonthsConstruction + MonthsHoldUntilConstruction;

  const LoanGuaranteeFeeToInvestorPercent = parseFloat(form.LoanGuaranteeFeeToInvestorPercent) / 100 || 0;
  const InterestOnInvestmentCashToInvestorPercent = parseFloat(form.InterestOnInvestmentCashToInvestorPercent) / 100 || 0;
  const InvestorCash = CurrencyToNumber(form.InvestorCash) || 0;
  const BuilderCash = CurrencyToNumber(form.BuilderCash) || 0;
  const MinimumIRR = parseFloat(form.MinimumIRR) / 100 || 0
  const MaximumIRR = parseFloat(form.MaximumIRR) / 100 || 0

  const TotalConstructionFunds = LandPurchase + SiteImprovements + Engineering + DirectConstruction;
  const BuildersFee = BuilersFeePercent * (SiteImprovements + Engineering + DirectConstruction);
  const AcquisitionAndFinance = AcquisitionAndFinanceTransactionCostsPercent * DirectConstruction;
  const ListingCommision = ListingCommissionPercent * HouseEstimatedSellPrice;
  const SellingCommision = SellingCommisionPercent * HouseEstimatedSellPrice;
  const TotalCash = InvestorCash + BuilderCash;
  const LoanAmount = TotalConstructionFunds - TotalCash;
  const LoanGuaranteeFee = LoanAmount * LoanGuaranteeFeeToInvestorPercent;
  const InterestOnInvestmentAbovePrime = ((InterestOnInvestmentCashToInvestorPercent - CommercialBorrowingInterestRate) * 100).toFixed(2);
  const InterestOnInvestmentCashToInvestor = (InterestOnInvestmentCashToInvestorPercent * (MonthsHoldBeforeConstruction + MonthsConstruction + MonthsHoldUntilConstruction) * InvestorCash) / 12;
  const LoanToValuePercent = LoanAmount / TotalConstructionFunds || 0;
  const BuilderEquity = BuilderCash + BuildersFee;
  const TotalEquity = InvestorCash + BuilderEquity;
  const BuilderEquityPercent = BuilderEquity / TotalEquity || 0;



  const getInterestBalance = (month: number, previousMonthsTotal: number) => {
    let balance = 0;
    if (month === 0) {
      balance =
        LandPurchase + AcquisitionAndFinanceTransactionCostsPercent - TotalCash;
    } else if (month <= MonthsHoldBeforeConstruction) {
      balance = previousMonthsTotal;
    } else if (
      month <=
      MonthsHoldBeforeConstruction +
      MonthsConstruction +
      MonthsHoldUntilConstruction
    ) {
      balance =
        Math.min(
          ((TotalConstructionFunds - TotalCash) / MonthsConstruction) *
          (month - MonthsHoldBeforeConstruction - 1),
          LoanAmount
        ) || 0;
    } else if (
      month >
      MonthsHoldBeforeConstruction +
      MonthsConstruction +
      MonthsHoldUntilConstruction
    ) {
      balance = 0;
    }

    if (balance < 0) balance = 0;

    return balance;
  };

  const getTotalInterest = () => {
    let total = 0;
    let previousMonthsTotal = 0;
    for (let i = 0; i < 24; i++) {
      const balance = getInterestBalance(i, previousMonthsTotal);
      const monthlyInterest =
        (balance * CommercialBorrowingInterestRate) / 12 || 0;
      total += monthlyInterest;
      previousMonthsTotal = balance;
    }

    return total;
  };

  const ConstructionInterest = getTotalInterest();
  const TotalSoftCosts = BuildersFee + AcquisitionAndFinance + ListingCommision + SellingCommision + ConstructionInterest;
  const TotalCosting = TotalConstructionFunds + TotalSoftCosts;
  const GrossProfit = HouseEstimatedSellPrice - TotalCosting;
  const NetIncome = GrossProfit - InterestOnInvestmentCashToInvestor - LoanGuaranteeFee;

  const GetInvestorReturnsByIRR = (InvestorCash: number, IRR: number, months: number) => {
    return InvestorCash * Math.pow(1 + IRR, months / 12) - (InvestorCash + InterestOnInvestmentCashToInvestor + LoanGuaranteeFee)
  };

  const MinimumInvestorReturns = GetInvestorReturnsByIRR(InvestorCash, MinimumIRR, TotalMonths);
  const MaximumInvestorReturns = GetInvestorReturnsByIRR(InvestorCash, MaximumIRR, TotalMonths);

  // Distribution 1: 25% of the netincome or the netincome if it is less than 25% of the netincome
  const DistributionOne = NetIncome * 0.25 < NetIncome ? NetIncome * 0.25 : NetIncome;
  const BalanceAfterDistributionOne = NetIncome - DistributionOne;

  // Distribution 2: 2x Distribution 1 or the netincome if it is less than 50% of the netincome
  const DistributionTwo = DistributionOne * 2 < NetIncome ? DistributionOne * 2 : NetIncome;
  const BalanceAfterDistributionTwo = BalanceAfterDistributionOne - DistributionTwo;

  // Distribution 3
  const DistributionThree = BalanceAfterDistributionTwo;

  // the desired percent decrease
  const percentDecrease = 0.05;

  // Investor Distributions
  const InvestorDistributionOnePercent = Math.round((MinimumInvestorReturns / (3 * DistributionOne) + (2 * percentDecrease) / 3) * 100 || 0) / 100;
  const InvestorDistributionTwoPercent = InvestorDistributionOnePercent - percentDecrease;

  const BuilderDistributionOnePercent = 1 - InvestorDistributionOnePercent;
  const BuilderDistributionTwoPercent = 1 - InvestorDistributionTwoPercent;

  const InvestorDistributionOne = InvestorDistributionOnePercent * DistributionOne;
  const BuilderDistributionOne = DistributionOne - InvestorDistributionOne

  const InvestorDistributionTwo = InvestorDistributionTwoPercent * DistributionTwo;
  const BuilderDistributionTwo = DistributionTwo - InvestorDistributionTwo;

  // Get the remaining amount the investor must get from the final distribution (max returns - min)
  const InvestorDistributionThree = MaximumInvestorReturns - MinimumInvestorReturns;
  const BuilderDistributionThree = DistributionThree - InvestorDistributionThree;


  const InvestorDistributionThreePercent = Math.round(InvestorDistributionThree / DistributionThree * 100) / 100;
  const BuilderDistributionThreePercent = 1 - InvestorDistributionThreePercent

  const InvestorTotal = InvestorDistributionOne + InvestorDistributionTwo + InvestorDistributionThree + InterestOnInvestmentCashToInvestor + LoanGuaranteeFee;
  const InvestorROE = GrossProfit > 0 ? Math.round((InvestorTotal / GrossProfit) * 100) : 0;

  const BuilderTotal = BuilderDistributionOne + BuilderDistributionTwo + BuilderDistributionThree;
  const BuilderROE = GrossProfit > 0 ? Math.round((BuilderTotal / GrossProfit) * 100) : 0;

  const GetIRR = (initialCash: number = 0, finalCash: number = 0, months: number = 0) => {
    if (!initialCash) return 0;
    const IRR = (finalCash / initialCash) ** (12 / months) - 1;

    return Math.round((IRR * 100) || 0);
  };

  const BaseInvestorReturns = InvestorCash + InterestOnInvestmentCashToInvestor + LoanGuaranteeFee;

  const IRRAfterDistributionOne = GetIRR(InvestorCash, BaseInvestorReturns + InvestorDistributionOne, TotalMonths);

  const IRRAfterDistributionTwo = GetIRR(InvestorCash, BaseInvestorReturns + InvestorDistributionOne + InvestorDistributionTwo, TotalMonths);

  const InvestorFinalIRR = GetIRR(InvestorCash, BaseInvestorReturns + InvestorDistributionOne + InvestorDistributionTwo + InvestorDistributionThree, TotalMonths);

  return {
    TotalConstructionFunds: Currency(TotalConstructionFunds),
    BuildersFee: Currency(BuildersFee),
    AcquisitionAndFinance: Currency(AcquisitionAndFinance),
    ListingCommision: Currency(ListingCommision),
    SellingCommision: Currency(SellingCommision),
    TotalCash: Currency(TotalCash),
    LoanAmount: Currency(LoanAmount),
    LoanGuaranteeFee: Currency(LoanGuaranteeFee),
    InterestOnInvestmentAbovePrime: InterestOnInvestmentAbovePrime,
    InterestOnInvestmentCashToInvestor: Currency(InterestOnInvestmentCashToInvestor),
    LoanToValuePercent: (LoanToValuePercent * 100).toFixed(2),
    BuilderEquity: Currency(BuilderEquity),
    TotalEquity: Currency(TotalEquity),
    BuilderEquityPercent: (BuilderEquityPercent * 100).toFixed(2),
    ConstructionInterest: Currency(ConstructionInterest),
    TotalSoftCosts: Currency(TotalSoftCosts),
    TotalCosting: Currency(TotalCosting),
    GrossProfit: Currency(GrossProfit),
    NetIncome: Currency(NetIncome),

    DistributionOne: Currency(DistributionOne),
    BalanceAfterDistributionOne: Currency(BalanceAfterDistributionOne),
    DistributionTwo: Currency(DistributionTwo),
    BalanceAfterDistributionTwo: Currency(BalanceAfterDistributionTwo),
    DistributionThree: Currency(DistributionThree),
    InvestorDistributionOne: Currency(InvestorDistributionOne),
    BuilderDistributionOne: Currency(BuilderDistributionOne),
    InvestorDistributionTwo: Currency(InvestorDistributionTwo),
    BuilderDistributionTwo: Currency(BuilderDistributionTwo),
    InvestorDistributionThree: Currency(InvestorDistributionThree),
    BuilderDistributionThree: Currency(BuilderDistributionThree),
    InvestorDistributionOnePercent: ((InvestorDistributionOnePercent || 0) * 100).toFixed(0),
    InvestorDistributionTwoPercent: ((InvestorDistributionTwoPercent || 0) * 100).toFixed(0),
    BuilderDistributionOnePercent: ((BuilderDistributionOnePercent || 0) * 100).toFixed(0),
    BuilderDistributionTwoPercent: ((BuilderDistributionTwoPercent || 0) * 100).toFixed(0),
    InvestorDistributionThreePercent: ((InvestorDistributionThreePercent || 0) * 100).toFixed(0),
    BuilderDistributionThreePercent: ((BuilderDistributionThreePercent || 0) * 100).toFixed(0),
    InvestorTotal: Currency(InvestorTotal),
    InvestorROE: InvestorROE,
    BuilderROE: BuilderROE,
    BuilderTotal: Currency(BuilderTotal),

    IRRAfterDistributionOne: IRRAfterDistributionOne,
    IRRAfterDistributionTwo: IRRAfterDistributionTwo,
    InvestorFinalIRR: InvestorFinalIRR || 0,
  };
}
