import APIRequest from "../helpers/CreateRequest";
import { useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import SnackBar from "../components/molecules/SnackBar";
import { useState } from "react";
import Button from "../components/atoms/Button";


export default function HeaderTop({ helpOffcanvasOpen, setHelpOffcanvasOpen }: { helpOffcanvasOpen: boolean, setHelpOffcanvasOpen: React.Dispatch<React.SetStateAction<boolean>> }): JSX.Element {
  const navigate = useNavigate();
  const [, , removeCookie] = useCookies();
  const [message, setMessage] = useState({ message: '', refresh: false })
  const tenantName = new Cookies().get("settings").TenantName || ''

  async function signOut() {
    try {
      const result = await new APIRequest(
        "/signOut",
        "GET",
        null,
        null
      ).GenerateRequest();
      if (result.status === 200) {
        removeCookie("token");
        navigate("/signin");
      } else {
        setMessage({ message: 'Server error. Unable to sign out', refresh: !message.refresh })
      }

    } catch (err) {
      setMessage({ message: 'Server error. Unable to sign out', refresh: !message.refresh })
    }
  }
  return (
    <>
      <div className="bg-md-background w-full h-[40px] flex justify-center align-middle text-left px-2 ">
        <span className="m-auto ml-0 title-small">
          {tenantName}: {process.env.REACT_APP_ENV}
        </span>

        <span className="flex flex-row gap-x-4 m-auto mr-0 items-center justify-center h-full">
          <Button
            label="Help"
            styleType="text"
            type="button"
            action={() => setHelpOffcanvasOpen(!helpOffcanvasOpen)}
          />

          <Button
            label="Sign out"
            styleType="text"
            type="button"
            action={() => signOut()}
          />
        </span>
      </div>

      <SnackBar message={message} />
    </>

  );
}
