import { UseFormGetValues, UseFormRegisterReturn, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import NumberField from "../../components/atoms/forms/Fields/NumberField";
import { GetMonthIndexByNameSubstring } from "./GetMonth";

type EstimatedUnitsSoldType = {
    name: string,
    label: string,
    supportingText?: string,
    disabled?: boolean,
    required?: boolean,
    value: string | undefined,
    formRef: UseFormRegisterReturn<string>
    setValue: UseFormSetValue<any>
    parentState?: any
    setParentState?: Function
    getValues?: UseFormGetValues<any>
    watch: UseFormWatch<any>
}

export default function EstimatedUnitsSold(props: EstimatedUnitsSoldType) {
    const [disabledByTimeNull, setDisabledByTimeNull] = useState(false)
    const [disabledByCommunityNull, setDisabledByCommunityNull] = useState(false)
    const watchYearOne = props.watch("YearOne")
    const watchYearTwo = props.watch("YearTwo")
    const watchCommunityID = props.watch('CommunityID')
    const watchCurrentMonthActualSalesUnitCount = props.watch("CurrentMonthActualSalesUnitCount")

    const fieldYear: number = props.name.includes('YearOne') ? Number(watchYearOne) : Number(watchYearTwo)
    const isCurrentMonth = useMemo(CurrentMonthCheck, [props.name, watchYearOne, watchYearTwo, props.getValues])
    const supportingText = useMemo(CheckSupportingText, [watchCurrentMonthActualSalesUnitCount, isCurrentMonth, props.value])


    function CurrentMonthCheck() {
        if (props.getValues) {
            const currentYear = new Date().getFullYear()
            const currentMonth = new Date().getMonth()
            const fieldMonthIndex = GetMonthIndexByNameSubstring(props.name)
            const isCurrentMonth = currentMonth === fieldMonthIndex && currentYear == fieldYear

            return isCurrentMonth
        }
    }

    function CheckSupportingText() {
        if (isCurrentMonth && !props.disabled && watchCurrentMonthActualSalesUnitCount && Number(props.value) < watchCurrentMonthActualSalesUnitCount) {
            return `Minimum value: ${watchCurrentMonthActualSalesUnitCount}`
        } else {
            return ''
        }
    }

    ///disable fields that are in the past
    useEffect(() => {
        const currentYear = new Date().getFullYear()
        const currentMonth = new Date().getMonth()

        let shouldDisable = false

        // disable if no parentState
        if (props.getValues && fieldYear) {

            const fieldMonthIndex = GetMonthIndexByNameSubstring(props.name)
            if (!shouldDisable) {
                const isFieldPast = fieldYear < currentYear || (fieldYear === currentYear && fieldMonthIndex < currentMonth)
                const isFieldCurrent = (fieldYear === currentYear) && (fieldMonthIndex === currentMonth)
                const isFieldFuture = fieldYear > currentYear



                if (isFieldPast && !isFieldCurrent && !isFieldFuture) {
                    shouldDisable = true
                } else if (!watchCommunityID) {
                    shouldDisable = true
                }
            }

            setDisabledByTimeNull(shouldDisable)
        } else {
            setDisabledByTimeNull(true)
        }
    }, [props.getValues, isCurrentMonth, fieldYear, watchCommunityID])

    // disble fields if community is null
    useEffect(() => {
        if (watchCommunityID) setDisabledByCommunityNull(false)
        else setDisabledByCommunityNull(true)
    }, [watchCommunityID])

    // if current month actual value changes and this is the current month (with additional exceptions), then change it to the current month value 
    useEffect(() => {
        if ((props.value || props.value === '') && isCurrentMonth && (watchCurrentMonthActualSalesUnitCount || watchCurrentMonthActualSalesUnitCount === undefined || watchCurrentMonthActualSalesUnitCount === '') && !(Number(props.value || 0) >= Number(watchCurrentMonthActualSalesUnitCount || 0))) {
            props.setValue(props.name, Number(watchCurrentMonthActualSalesUnitCount || 0))
        }
    }, [watchCurrentMonthActualSalesUnitCount])


    return (
        <NumberField {...props}
            disabled={disabledByTimeNull || props.disabled || disabledByCommunityNull}
            supportingText={supportingText || props.supportingText}
        />
    )
}