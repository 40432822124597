import { Currency, CurrencyToNumber } from "../../helpers/InputValueConverter";
import { SalesForecast } from "../../Types/SalesForecast";
import { USERFORM } from "./SalesForecasts";

export function GetCalculatedFields(form: SalesForecast) {
  const salesPerYear = getSalesPerYear(form.UnitsSoldPerMonth);
  const optionsSales = getOptionsSales(
    form.BasePrice,
    form.OptionSalesToBasePriceRatio
  );

  const basePricePerSF = getBasePricePerSF(
    form.BasePrice,
    form.SquareFootageFinancial
  );
  const finalSalePrice = getFinalSalePrice(
    form.LotPremium,
    form.BasePrice,
    form.OptionSalesToBasePriceRatio,
    form.Incentives
  );
  const finalSalePricePerSF = getFinalSalePricePerSF(
    finalSalePrice,
    form.SquareFootageFinancial
  );

  const transactionCost = getTransactionCost(
    form.TransactionCostToLotAcquisitionCostRatio,
    form.LotAcquisitionCost
  );
  const transactionCostPercentageofLotCost = getTransactionCostPercentageofLotCost(
    form.LotAcquisitionTransactionCost,
    form.LotAcquisitionCost
  );
  const otherCostPercentageofLostCost = getOtherCostPercentageofLostCost(
    form.OtherLotItemCost,
    form.LotAcquisitionCost
  );
  const totalLotCost = getTotalLotCost(
    form.LotAcquisitionCost,
    transactionCost,
    form.OtherLotItemCost
  );
  const totalLotCostCatalog = getTotalLotCost(
    form.LotAcquisitionCost,
    form.LotAcquisitionTransactionCost,
    form.OtherLotItemCost
  );
  const totalSiteWork = getTotalSiteWork(
    form.SiteWorkItemOneCost,
    form.SiteWorkItemTwoCost
  );
  const optionDirectCost = getOptionDirectCost(
    optionsSales,
    form.OptionSalesMarginFraction
  );
  const totalDirectCost = getTotalDirectCost(
    optionDirectCost,
    form.IncludedOptionCosts,
    form.BaseDirectCost,
    form.OtherDirectOneCost,
    form.OtherDirectTwoCost
  );
  const costPerSF = getCostPerSF(totalDirectCost, form.SquareFootageFinancial);
  
  const listingCommission = getListingCommission(
    form.ListingCommissionCostToBasePriceRatio,
    form.BasePrice
  );
  
  const listingCommissionPercentageOfBasePrice = getListingCommissionPercentageOfBasePrice(
    form.ListingCommissionCost,
    form.BasePrice
  );

  const transferStamp = getTransferStamp(
    form.TransferStampCostToSalePriceRatio,
    finalSalePrice
  );

  const transferStampPercentageOfSalePrice = getTransferStampPercentageOfSalePrice(
    form.TransferStampCost,
    finalSalePrice
  );

  const financeCost = getFinanceCost(
    form.FinanceCostToLotSiteDirectCostsSumRatio,
    totalLotCost,
    totalSiteWork,
    totalDirectCost
  );
  const financeCostPercentageOfLotSiteAndDirect = getFinanceCostPercentageOfLotSiteAndDirect(
    form.FinanceCost,
    totalLotCost,
    totalSiteWork,
    totalDirectCost
  );

  const internalCommissionPercentageOfSalePrice =
    getInternalCommissionPercentageOfSalePrice(
      form.InternalCommissionCost,
      finalSalePrice
    );
  const sellingCommission = getSellingCommission(
    form.SellingCommissionCostToSalePriceRatio,
    finalSalePrice
  );
  const sellingCommissionPercentageOfSalePrice = getSellingCommissionPercentageOfSalePrice(
    form.SellingCommissionCost,
    finalSalePrice
  );

  const dispositionTransactionCost = getDispositionTransactionCost(
    form.DispositionTransactionCostToSalePriceRatio,
    finalSalePrice
  );
  const indirectOtherCosts = getIndirectOtherCost(
    form.IndirectOtherCostToSalePriceRatio,
    finalSalePrice
  );

  const indirectOtherCostsPercentageOfSalePrice = getIndirectOtherCostsPercentageOfSalePrice(  
    form.IndirectOtherCosts,
    finalSalePrice
  );

  const totalIndirectCosts = getTotalIndirectCost(
    listingCommission,
    transferStamp,
    financeCost,
    form.InternalCommissionCost,
    sellingCommission,
    form.SellerAgentSalesFraction,
    form.DispositionTransactionCostToSalePriceRatio,
    form.IndirectOtherCosts
  );

  const totalCost = getTotalCost(
    totalLotCost,
    totalSiteWork,
    totalDirectCost,
    form.IndirectCostsTotal
  );
  const daysFromContractToSettlement = getDaysFromContractToSettlement(
    form.SaleStartIntervalDays,
    form.StartSettlementIntervalDays
  );

  const baseGrossProfit =
    form.SalesForecastTypeID === USERFORM
      ? getBaseGrossProfit(form.SalePriceTotal, form.CostsTotal)
      : getBaseGrossProfit(form.BasePrice, totalCost, optionDirectCost);

  const baseGrossMargin =
    form.SalesForecastTypeID === USERFORM
      ? getBaseGrossMargin(form.SalePriceTotal, form.CostsTotal)
      : getBaseGrossMargin(form.BasePrice, totalCost, optionDirectCost);

  const finalGrossProfit = getFinalGrossProfit(finalSalePrice, totalCost);
  const finalGrossMargin = getFinalGrossMargin(
    finalSalePrice,
    finalGrossProfit
  );

  return {
    salesPerYear,
    optionsSales,
    baseGrossProfit,
    baseGrossMargin,
    basePricePerSF,
    finalSalePrice,
    finalSalePricePerSF,
    finalGrossProfit,
    finalGrossMargin,
    transactionCost,
    transactionCostPercentageofLotCost,
    otherCostPercentageofLostCost,
    totalLotCost,
    totalLotCostCatalog,
    totalSiteWork,
    optionDirectCost,
    totalDirectCost,
    costPerSF,
    listingCommission,
    listingCommissionPercentageOfBasePrice,
    transferStamp,
    transferStampPercentageOfSalePrice,
    financeCost,
    financeCostPercentageOfLotSiteAndDirect,
    internalCommissionPercentageOfSalePrice,
    sellingCommission,
    sellingCommissionPercentageOfSalePrice,
    dispositionTransactionCost,
    indirectOtherCostsPercentageOfSalePrice,
    indirectOtherCosts,
    totalIndirectCost: totalIndirectCosts,
    totalCost: totalCost,
    daysFromContractToSettlement: daysFromContractToSettlement,
  };
}

export const getSalesPerYear = (salesPerMonthStr: string) => {
  const salesPerMonth = Number(salesPerMonthStr) || 0;
  return Math.floor(salesPerMonth * 12);
};

export const getOptionsSales = (
  basePriceStr: string,
  optionsSalesPercentofBasePriceStr: string
) => {
  const basePrice = CurrencyToNumber(basePriceStr) || 0;
  const optionsSalesPercentofBasePrice =
    (Number(optionsSalesPercentofBasePriceStr) || 0) / 100;
  return Currency((basePrice * optionsSalesPercentofBasePrice).toString());
};

export const getBaseGrossProfit = (
  basePriceStr: string,
  totalCostStr: string,
  optionDirectCostStr?: string
) => {
  const basePriceNum = CurrencyToNumber(basePriceStr) || 0;
  const totalCostNum = CurrencyToNumber(totalCostStr) || 0;

  if (optionDirectCostStr) {
    const optionDirectCost = CurrencyToNumber(optionDirectCostStr) || 0;
    return Currency((basePriceNum - totalCostNum + optionDirectCost).toString());
  }

  return Currency((basePriceNum - totalCostNum).toString());
};

export const getBaseGrossMargin = (
  basePriceStr: string,
  totalCostStr: string,
  optionDirectCostStr?: string
) => {
  const basePrice = CurrencyToNumber(basePriceStr) || 0;
  const totalCost = CurrencyToNumber(totalCostStr) || 0;

  if (optionDirectCostStr) {
    const optionDirectCost = CurrencyToNumber(optionDirectCostStr) || 0;
    return parseFloat(
      (((basePrice - totalCost + optionDirectCost) / basePrice) * 100).toFixed(4)
    ).toString();
  }

  const grossProfit = basePrice - totalCost;
  let calcGrossMargin = 0;
  if (grossProfit !== 0 && basePrice > 0) {
    calcGrossMargin = (grossProfit / basePrice) * 100;
  }

  return parseFloat(calcGrossMargin.toFixed(4)).toString();
};

export const getBasePricePerSF = (
  basePriceStr: string,
  squareFootageStr: string
) => {
  const basePrice = CurrencyToNumber(basePriceStr) || 0;
  const squareFootage = Number(squareFootageStr) || 0;

  return Currency((basePrice / squareFootage).toString() || "0");
};

export const getFinalSalePrice = (
  lotPremiumStr: string,
  modelElevationBasePriceStr: string,
  optionSalesPercentofBasePriceStr: string,
  incentivesStr: string
) => {
  const lotPremium = CurrencyToNumber(lotPremiumStr) || 0;
  const modelElevationBasePrice =
    CurrencyToNumber(modelElevationBasePriceStr) || 0;
  const optionSalesPercentofBasePrice =
    (Number(optionSalesPercentofBasePriceStr) || 0) / 100;
  const incentives = CurrencyToNumber(incentivesStr) || 0;
  return Currency(
    (
      lotPremium +
      modelElevationBasePrice +
      modelElevationBasePrice * optionSalesPercentofBasePrice -
      incentives
    ).toString()
  );
};

export const getFinalSalePricePerSF = (
  finalSalePriceStr: string,
  squareFootageFinancialStr: string
) => {
  const finalSalePrice = CurrencyToNumber(finalSalePriceStr) || 0;
  const squareFootageFinancial = Number(squareFootageFinancialStr) || 0;
  return Currency((finalSalePrice / squareFootageFinancial).toString() || "0");
};

export const getFinalGrossProfit = (
  finalCalculatedSalePriceStr: string,
  totalCostsStr: string
) => {
  const finalCalculatedSalePrice =
    CurrencyToNumber(finalCalculatedSalePriceStr) || 0;
  const totalCosts = CurrencyToNumber(totalCostsStr) || 0;
  return Currency((finalCalculatedSalePrice - totalCosts).toString());
};

export const getFinalGrossMargin = (
  finalCalculatedSalePriceStr: string,
  finalGrossProfitStr: string
) => {
  const finalCalculatedSalePrice =
    CurrencyToNumber(finalCalculatedSalePriceStr) || 0;
  const finalGrossProfit = CurrencyToNumber(finalGrossProfitStr) || 0;
  return parseFloat(
    ((finalGrossProfit / finalCalculatedSalePrice || 0) * 100).toFixed(4)
  ).toString();
};

// Costing
export const getTransactionCost = (
  transactionCostToLotAcquisitionCostRatioStr: string,
  lotAcquisitionCostStr: string
) => {
  const transactionCostToLotAcquisitionCostRatio =
    (Number(transactionCostToLotAcquisitionCostRatioStr) || 0) / 100;
  const lotAcquisitionCost = CurrencyToNumber(lotAcquisitionCostStr) || 0;
  return Currency(
    (lotAcquisitionCost * transactionCostToLotAcquisitionCostRatio).toString()
  );
};

export const getTransactionCostPercentageofLotCost = (
  transactionCostStr: string,
  lotAcquisitionCostStr: string
) => {
  const transactionCost = CurrencyToNumber(transactionCostStr) || 0;
  const lotAcquisitionCost = CurrencyToNumber(lotAcquisitionCostStr) || 0;
  if (lotAcquisitionCost === 0) return "0";
  return ((transactionCost / lotAcquisitionCost) * 100).toFixed(4).toString();
};

export const getOtherCostPercentageofLostCost = (
  otherCostStr: string,
  lotAcquisitionCostStr: string
) => {
  const otherCost = CurrencyToNumber(otherCostStr) || 0;
  const lotAcquisitionCost = CurrencyToNumber(lotAcquisitionCostStr) || 0;
  if (lotAcquisitionCost === 0) return "0";
  return ((otherCost / lotAcquisitionCost) * 100).toFixed(4).toString();
};

export const getTotalLotCost = (
  lotAcquisitionCostStr: string,
  transactionCostStr: string,
  otherCostsStr: string
) => {
  const lotAcquisitionCost = CurrencyToNumber(lotAcquisitionCostStr) || 0;
  const transactionCost = CurrencyToNumber(transactionCostStr) || 0;
  const otherCosts = CurrencyToNumber(otherCostsStr) || 0;
  return Currency(
    (lotAcquisitionCost + transactionCost + otherCosts).toString()
  );
};

export const getTotalSiteWork = (
  siteWorkItemOneCostStr: string,
  siteWorkItemTwoCostStr: string
) => {
  const siteWorkItemOneCost = CurrencyToNumber(siteWorkItemOneCostStr) || 0;
  const siteWorkItemTwoCost = CurrencyToNumber(siteWorkItemTwoCostStr) || 0;

  return Currency((siteWorkItemOneCost + siteWorkItemTwoCost).toString());
};

export const getOptionDirectCost = (
  optionsSalesStr: string,
  averageMarginOptionRatioStr: string
) => {
  const includedOptionsCost = CurrencyToNumber(optionsSalesStr) || 0;
  const averageMarginOptionRatio =
    (Number(averageMarginOptionRatioStr) || 0) / 100;
  const costMargin = 1 - averageMarginOptionRatio;
  return Currency((includedOptionsCost * costMargin).toString());
};

export const getTotalDirectCost = (
  optionDirectCostStr: string,
  includedOptionsStr: string,
  baseDirectCostStr: string,
  otherCostOneStr: string,
  otherCostTwoStr: string
) => {
  const optionDirectCost = CurrencyToNumber(optionDirectCostStr) || 0;
  const includedOptions = CurrencyToNumber(includedOptionsStr) || 0;
  const baseDirectCost = CurrencyToNumber(baseDirectCostStr) || 0;
  const otherCostOne = CurrencyToNumber(otherCostOneStr) || 0;
  const otherCostTwo = CurrencyToNumber(otherCostTwoStr) || 0;
  return Currency(
    (
      optionDirectCost +
      includedOptions +
      baseDirectCost +
      otherCostOne +
      otherCostTwo
    ).toString()
  );
};

export const getCostPerSF = (
  totalDirectCostStr: string,
  squareFootageFinancialStr: string
) => {
  const totalDirectCost = CurrencyToNumber(totalDirectCostStr) || 0;
  const squareFootageFinancial = Number(squareFootageFinancialStr) || 0;
  return Currency((totalDirectCost / squareFootageFinancial).toString());
};

export const getListingCommission = (
  listingCommissionCostToBasePriceRatioStr: string,
  basePriceStr: string
) => {
  const listingCommissionCostToBasePriceRatio =
    (Number(listingCommissionCostToBasePriceRatioStr) || 0) / 100;
  const basePrice = CurrencyToNumber(basePriceStr) || 0;
  return Currency(
    (basePrice * listingCommissionCostToBasePriceRatio).toString()
  );
};

export const getListingCommissionPercentageOfBasePrice = (
  listingCommissionCostStr: string,
  basePriceStr: string
) => {
  const listingCommissionCost = (CurrencyToNumber(listingCommissionCostStr) || 0);
  const basePrice = CurrencyToNumber(basePriceStr) || 0;
  if (basePrice === 0) return "0";
  return ((listingCommissionCost / basePrice) * 100).toFixed(4).toString();
};

export const getTransferStamp = (
  transferStampCostToSalePriceRatioStr: string,
  salePriceStr: string
) => {
  const transferStampCostToSalePriceRatio =
    (Number(transferStampCostToSalePriceRatioStr) || 0) / 100;
  const salePrice = CurrencyToNumber(salePriceStr) || 0;
  return Currency((salePrice * transferStampCostToSalePriceRatio).toString());
};

export const getTransferStampPercentageOfSalePrice = (
  transferStampCostStr: string,
  salePriceStr: string
) => {
  const transferStampCost = (CurrencyToNumber(transferStampCostStr) || 0);
  const salePrice = CurrencyToNumber(salePriceStr) || 0;
  if (salePrice === 0) return "0";
  return ((transferStampCost / salePrice) * 100).toFixed(4).toString();
};

export const getFinanceCost = (
  financeCostToLotSiteDirectCostsSumRatioStr: string,
  lostCostsStr: string,
  siteCostsStr: string,
  directCostsStr: string
) => {
  const financeCostToLotSiteDirectCostsSumRatio =
    (Number(financeCostToLotSiteDirectCostsSumRatioStr) || 0) / 100;
  const lostCosts = CurrencyToNumber(lostCostsStr) || 0;
  const siteCosts = CurrencyToNumber(siteCostsStr) || 0;
  const directCosts = CurrencyToNumber(directCostsStr) || 0;
  return Currency(
    (
      (lostCosts + siteCosts + directCosts) *
      financeCostToLotSiteDirectCostsSumRatio
    ).toString()
  );
};

export const getFinanceCostPercentageOfLotSiteAndDirect = (
  financeCostStr: string,
  lotCostsStr: string,
  siteCostsStr: string,
  directCostsStr: string
) => {
  const lotSiteDirectCosts = (CurrencyToNumber(lotCostsStr) || 0) + (CurrencyToNumber(siteCostsStr) || 0) + (CurrencyToNumber(directCostsStr) || 0);
  const financeCost = CurrencyToNumber(financeCostStr) || 0;
  if (lotSiteDirectCosts === 0) return "0";
  return ((financeCost / lotSiteDirectCosts) * 100).toFixed(4).toString();
};

export const getInternalCommissionPercentageOfSalePrice = (
  internalCommissionStr: string,
  salePriceStr: string
) => {
  const internalCommission = CurrencyToNumber(internalCommissionStr) || 0;
  const salePrice = CurrencyToNumber(salePriceStr) || 0;
  if (salePrice === 0) return "0"
  return ((internalCommission / salePrice) * 100).toFixed(4).toString();
};

export const getSellingCommission = (
  sellingCommissionCostToSalePriceRatioStr: string,
  salePriceStr: string
) => {
  const sellingCommissionCostToSalePriceRatio =
    (Number(sellingCommissionCostToSalePriceRatioStr) || 0) / 100;
  const salePrice = CurrencyToNumber(salePriceStr) || 0;
  return Currency(
    (salePrice * sellingCommissionCostToSalePriceRatio).toString()
  );
};

export const getSellingCommissionPercentageOfSalePrice = (
  sellingCommissionCostStr: string,
  salePriceStr: string
) => {
  const sellingCommissionCost = (CurrencyToNumber(sellingCommissionCostStr) || 0);
  const salePrice = CurrencyToNumber(salePriceStr) || 0;
  if (salePrice === 0) return "0";
  return ((sellingCommissionCost / salePrice) * 100).toFixed(4).toString();
};

export const getDispositionTransactionCost = (
  dispositionTransactionCostToSalePriceRatioStr: string,
  salePriceStr: string
) => {
  const dispositionTransactionCostToSalePriceRatio =
    (Number(dispositionTransactionCostToSalePriceRatioStr) || 0) / 100;
  const salePrice = CurrencyToNumber(salePriceStr) || 0;
  return Currency(
    (salePrice * dispositionTransactionCostToSalePriceRatio).toString()
  );
};

export const getIndirectOtherCost = (
  indirectOtherCostToSalePriceRatioStr: string,
  salePriceStr: string
) => {
  const indirectOtherCostToSalePriceRatio =
    (Number(indirectOtherCostToSalePriceRatioStr) || 0) / 100;
  const salePrice = CurrencyToNumber(salePriceStr) || 0;
  return Currency(
    (salePrice * indirectOtherCostToSalePriceRatio).toString()
  );
};

export const getIndirectOtherCostsPercentageOfSalePrice = (
  indirectOtherCostsStr: string,
  salePriceStr: string
) => {
  const indirectOtherCosts = CurrencyToNumber(indirectOtherCostsStr) || 0;
  const salePrice = CurrencyToNumber(salePriceStr) || 0;
  if (salePrice === 0) return "0";
  return ((indirectOtherCosts / salePrice) * 100).toFixed(4).toString();
};



export const getTotalIndirectCost = (
  listingCommissionStr: string,
  transferStampStr: string,
  financeCostStr: string,
  internalCommissionCostStr: string,
  sellingCommissionStr: string,
  percentOfSalesWithSellerAgentStr: string,
  dispositionTransactionCostStr: string,
  dispositionOtherCostStr: string
) => {
  const listingCommission = CurrencyToNumber(listingCommissionStr) || 0;
  const transferStamp = CurrencyToNumber(transferStampStr) || 0;
  const financeCost = CurrencyToNumber(financeCostStr) || 0;
  const internalCommissionCost =
    CurrencyToNumber(internalCommissionCostStr) || 0;
  const sellingCommission = CurrencyToNumber(sellingCommissionStr) || 0;
  const percentOfSalesWithSellerAgent =
    (Number(percentOfSalesWithSellerAgentStr) || 0) / 100;
  const dispositionTransactionCost =
    CurrencyToNumber(dispositionTransactionCostStr) || 0;
  const dispositionOtherCost = CurrencyToNumber(dispositionOtherCostStr) || 0;
  return Currency(
    (
      listingCommission +
      transferStamp +
      financeCost +
      internalCommissionCost +
      sellingCommission * percentOfSalesWithSellerAgent +
      dispositionTransactionCost +
      dispositionOtherCost
    ).toString()
  );
};

export const getTotalCost = (
  totalLotCostsStr: string,
  totalSiteCostsStr: string,
  totalDirectCostsStr: string,
  totalIndirectCostsStr: string
) => {
  const totalLotCosts = CurrencyToNumber(totalLotCostsStr) || 0;
  const totalSiteCosts = CurrencyToNumber(totalSiteCostsStr) || 0;
  const totalDirectCosts = CurrencyToNumber(totalDirectCostsStr) || 0;
  const totalIndirectCosts = CurrencyToNumber(totalIndirectCostsStr) || 0;
  return Currency(
    (
      totalLotCosts +
      totalSiteCosts +
      totalDirectCosts +
      totalIndirectCosts
    ).toString()
  );
};

export const getDaysFromContractToSettlement = (
  saleStartIntervalDaysStr: string,
  startSettlementIntervalDaysStr: string
) => {
  const saleStartIntervalDays = Number(saleStartIntervalDaysStr) || 0;
  const startSettlementIntervalDays =
    Number(startSettlementIntervalDaysStr) || 0;
  return (saleStartIntervalDays + startSettlementIntervalDays).toString();
};
