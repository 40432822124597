import APIRequest from "../../helpers/CreateRequest";


export default async function RequestAI(systemPrompt: string, userPrompt: string) {
    try {
        const result = await new APIRequest('/openai', 'POST', null, { systemPrompt: systemPrompt, userPrompt: userPrompt }).GenerateRequest()

        if (result.status === 200) {
            const body = await result.json()
            if (body.status === 200) {
                return String(body.message)
            } else throw body.message
        } else throw result.statusText
    } catch (err) {
        console.log(err)
        return false
    }
}
