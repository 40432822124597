import { useEffect, useState } from "react"
import { UseFormRegisterReturn, UseFormSetValue } from "react-hook-form"
import BaseField from "./BaseField"
import { Currency, CurrencyToNumber } from "../../../../helpers/InputValueConverter"

export type CurrencyFieldType = {
    name: string,
    label: string,
    supportingText?: string,
    disabled?: boolean,
    required?: boolean,
    value: string | undefined,
    formRef: UseFormRegisterReturn<string>
    setValue: UseFormSetValue<any>
    setWrapperState?: Function,
    readonly?: boolean
}

export default function CurrencyField(props: CurrencyFieldType) {
    const [isFocused, setIsFocused] = useState(false)
    const [tempValue, setTempValue] = useState('')

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const EXCEPTION = e.ctrlKey || e.altKey || e.metaKey || e.key === 'Backspace' || e.key === 'Delete' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Tab' || (e.key === '.' && !e.currentTarget.value.includes('.'))
        // const DECIMALINVALID = e.currentTarget.value.includes('.') ? (e.currentTarget.value.split('.')[1].split('').length + 1 === 3 ? true : false) : false

        //only digits and the exceptions above will work
        if (!/^\d$/.test(e.key) && (!EXCEPTION || e.key === ' ')) {
            e.preventDefault()
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const changedValue = (e.target.value)
        setTempValue(changedValue)
        props.formRef.onChange(e)
    }

    const handleFocus = () => {
        if (!tempValue) setTempValue('$')
        setIsFocused(true)
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        const changedValue = (e.target.value)
        setIsFocused(false)
        props.setValue(props.name, CurrencyToNumber(changedValue), { shouldValidate: true })
        props.formRef.onBlur(e)
        if (props.setWrapperState) props.setWrapperState(changedValue)
    }

    useEffect(() => setTempValue(Currency(props.value || '')), [props.value])

    return (
        <BaseField
            {...props}
            handleChange={handleChange}
            type="currency"
            handleFocus={handleFocus}
            handleKeyDown={handleKeyDown}
            handleBlur={handleBlur}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            tempValue={tempValue}
            setTempValue={setTempValue}
        />
    )
}