import { UseFormRegisterReturn, UseFormSetValue, UseFormWatch } from "react-hook-form";
import CurrencyField from "../../components/atoms/forms/Fields/CurrencyField";
import { useEffect, useState } from "react";
import { Currency, CurrencyToNumber } from "../../helpers/InputValueConverter";

type GrossProfitType = {
    name: string,
    label: string,
    supportingText?: string,
    disabled?: boolean,
    required?: boolean,
    value: string | undefined,
    formRef: UseFormRegisterReturn<string>
    setValue: UseFormSetValue<any>
    watch: UseFormWatch<any>
}

export default function GrossProfit(props: GrossProfitType) {
    const [grossProfit, setGrossProfit] = useState<string | undefined>()
    const watchSalePrice = props.watch('EstimatedHomeBuildingSalePrice')
    const watchCost = props.watch('EstimatedHomeBuildingCosts')

    useEffect(() => {
        if(!props.disabled || watchSalePrice || watchCost){
            const salePrice = CurrencyToNumber(watchSalePrice) || 0
            const cost = CurrencyToNumber(watchCost) || 0

            const calcGrossProfit = salePrice - cost

            setGrossProfit(Currency(String(calcGrossProfit)))
        } else{
            setGrossProfit(undefined)
        }
    }, [watchSalePrice, watchCost])

    return (
        <CurrencyField {...props} value={grossProfit} />
    )
}