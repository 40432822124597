import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { BusinessUnitReport } from "./BusinessUnits";

export default function GenerateBusinessUnitReport(
  title: string,
  colNames: RowInput[],
  recordset: BusinessUnitReport[]
) {
  const font = "Helvetica";
  const docWidth = 170;
  const docHeight = 270;
  const yMargin = 20;
  const xMargin = 20;

  //track current y position. init as ymargin
  let currentY = yMargin;

  //init pdf and font
  const doc = new jsPDF();
  doc.setFont(font);

  //add title
  doc.setFontSize(20);
  doc.text(title, xMargin, currentY);

  //gets current y position after ymargin and title and some padding to begin the page content
  currentY = doc.getTextDimensions(title).h + yMargin + 10;

  //loop through each record and shows all procedures (with parent values) of all business units

  let i = 0;
  for (i; i < recordset.length; i++) {
    // font size of role parents
    doc.setFontSize(12);

    const parentNamesHeight = currentY;
    const labelMargin = 6;
    const valueMargin = 5;

    // Business Unit
    doc.setFont(font, "bold");
    doc.text("Business Unit:", xMargin, currentY);

    //business units will be widest label so it will be used as the base label width
    const labelWidth = doc.getTextWidth("Business Unit:");

    doc.setFont(font, "normal");
    doc.text(
      recordset[i].BusinessUnitNameCode,
      labelWidth + xMargin + labelMargin,
      currentY
    );

    //set the new y position with the value and margin for spacing
    currentY =
      doc.getTextDimensions(recordset[i].BusinessUnitNameCode).h +
      currentY +
      valueMargin;

    // Department
    doc.setFont(font, "bold");
    doc.text("Department:", xMargin, currentY);

    doc.setFont(font, "normal");
    doc.text(
      recordset[i].DepartmentNameCode,
      labelWidth + xMargin + labelMargin,
      currentY
    );

    currentY =
      doc.getTextDimensions(recordset[i].DepartmentNameCode).h +
      currentY +
      valueMargin;

    //Role
    doc.setFont(font, "bold");
    doc.text("Role:", xMargin, currentY);

    doc.setFont(font, "normal");
    doc.text(
      recordset[i].RoleNameCode,
      labelWidth + xMargin + labelMargin,
      currentY
    );

    // Right side
    currentY = parentNamesHeight;

    const leftSideWidth = docWidth / 2 + 20;

    // Division
    doc.setFont(font, "bold");
    doc.text("Division:", leftSideWidth, currentY);

    //largest so it will be used as the base label width
    const divisionLabelWidth = doc.getTextWidth("Division:");

    doc.setFont(font, "normal");
    doc.text(
      recordset[i].DivisionNameCode,
      leftSideWidth + divisionLabelWidth + labelMargin,
      currentY
    );

    currentY =
      doc.getTextDimensions(recordset[i].DivisionNameCode).h +
      currentY +
      valueMargin;

    //Position
    doc.setFont(font, "bold");
    doc.text("Position:", leftSideWidth, currentY);

    doc.setFont(font, "normal");
    doc.text(
      recordset[i].PositionNameCode,
      leftSideWidth + divisionLabelWidth + labelMargin,
      currentY
    );

    //now set current y back to end of role label-value with some padding
    currentY =
      doc.getTextDimensions(recordset[i].RoleNameCode).h + currentY + 10;

    const row: string[][] = [[recordset[i].ProcedureName]];

    // Next record from the same role?
    while (
      recordset[i].RoleNameCode ===
        (i + 1 < recordset.length ? recordset[i + 1].RoleNameCode : false) &&
      recordset[i].RoleNameCode !== "<undefined>"
    ) {
      i++;
      row.push([recordset[i].ProcedureName]);
    }

    // Create the table
    autoTable(doc, {
      head: colNames,
      body: row,
      startY: currentY,
    });

    //get y position at end of the table
    currentY = (doc as any).lastAutoTable.finalY + valueMargin;

    // Next record from the same position?
    while (
      recordset[i].PositionNameCode ===
        (i + 1 < recordset.length
          ? recordset[i + 1].PositionNameCode
          : false) &&
      recordset[i].PositionNameCode !== "<undefined>"
    ) {
      i++;

      currentY = currentY + valueMargin;

      //Role
      doc.setFont(font, "bold");
      doc.text("Role:", xMargin, currentY);

      doc.setFont(font, "normal");
      doc.text(
        recordset[i].RoleNameCode,
        labelWidth + xMargin + labelMargin,
        currentY
      );

      currentY = doc.getTextDimensions(recordset[i].RoleNameCode).h + currentY;

      const row: string[][] = [[recordset[i].ProcedureName]];

      // Next record from the same role?
      while (
        recordset[i].RoleNameCode ===
          (i + 1 < recordset.length ? recordset[i + 1].RoleNameCode : false) &&
        recordset[i].RoleNameCode !== "<undefined>"
      ) {
        i++;
        row.push([recordset[i].ProcedureName]);
      }

      // Create the table
      autoTable(doc, {
        head: colNames,
        body: row,
        startY: currentY,
      });

      //get y position at end of the table
      currentY = (doc as any).lastAutoTable.finalY + valueMargin;
    }

    //if limited space left on page then set up for a new page
    if (currentY + 40 > docHeight - 2 * yMargin && i + 1 < recordset.length) {
      doc.addPage();
      currentY = yMargin;
    } else {
      currentY = currentY + 20;
    }
  }

  doc.save("BusinessUnitPocedures.pdf");
}
