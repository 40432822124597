import { field } from "../Types/FormTypes"
import APIRequest from "./CreateRequest"
import FormatDate from "./FormatDate"
import { Currency } from "./InputValueConverter"

export default async function GetObj(id: string, name: string, fields: field[] ) {
    if (id === undefined) return
    try {
        const result = (await new APIRequest(`/${name}/unit`, "POST", null, { id: id }).GenerateRequest())
        if(result.status === 200){
            const body = await result.json()
            if (body.status === 200) {
                const record = body.recordset[0]
                const formattedObj: any = {}
                fields.forEach(field => {
                    formattedObj[field.sendAs ||field.name] = field.type === 'date' && record[field.name] !== null ?
                     FormatDate(record[field.name], true) : 
                     field.type === 'currency' && record[field.name] !== null ? Currency(record[field.name]) :
                     field.type === 'percent' && record[field.name] !== null && !isNaN(record[field.name] * 100) ? record[field.name] * 100 :
                     field.type === 'checkbox' ? record[field.name] === 'True' ? true : false :
                     record[field.name]
                })
                return formattedObj
        } else throw body.message
        } else throw result.statusText
    }
    catch (err) {
        console.log(err)
        return -1
    }
}