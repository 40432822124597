import { useEffect, useState } from "react"
import APIRequest from "../../helpers/CreateRequest"
import { FieldValues, useForm } from "react-hook-form"
import Button from "../../components/atoms/Button"
import { Currency, CurrencyToNumber } from "../../helpers/InputValueConverter"
import { Cookies } from "react-cookie"

type table = {
    BudgetDetailID: string
    Description: string,
    ItemType: string,
    BudgetType: string,
    BudgetAmount: number,
    ActualAmount: number,
    Exclude: boolean,
}[]

const INCOME = '3'
const EXPENSE = '4'


export default function BudgetsTable({ objID }: { objID: string }) {
    const [data, setdata] = useState<{ incomeData: table, expenseData: table }>()
    const [dataBackUp, setDataBackUp] = useState<Map<string, any>>(new Map())
    const [showData, setShowData] = useState<{ income: boolean, expense: boolean }>({ income: false, expense: false })
    const [loading, setLoading] = useState({ isLoading: false, progress: 0 })
    const [totals, setTotals] = useState({ incomeBudgetTotal: '$0.00', incomeActualTotal: '$0.00', expenseBudgetTotal: '$0.00', expenseActualTotal: '$0.00', netBudget: '$0.00', netActual: '$0.00', variance: '$0.00' })
    const [isAdding, setIsAdding] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { isDirty, isSubmitting },
        setValue
    } = useForm()


    async function getBudgetDetails() {
        try {
            const headers: HeadersInit = new Headers({
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow": "true",
                Authorization: `Bearer ${new Cookies().get('token')}`,
                'BudgetID': objID || ''
            })
            const result = await (new APIRequest(`/budgetDetails`, 'GET', headers, null).GenerateRequest())


            if (result.status === 200) {
                const body = await result.json()
                if (body.status === 200) {
                    const tempIncomeData: table = []
                    const tempExpenseData: table = []
                    const tempDataBackUp = new Map<string, any>()

                    // loop through and place each value into map based on income vs expense. also set the original value in the backup amp
                    for (let i = 0; i < body.recordset.length; i++) {
                        const record = body.recordset[i]
                        if (record.ItemType === INCOME) tempIncomeData.push({ ...record })
                        else if (record.ItemType === EXPENSE) tempExpenseData.push({ ...record })
                        else throw Error('Invalid Item Type')

                        for (const name in record) {
                            tempDataBackUp.set(`${record.BudgetDetailID}_${name}`, !record[name] && name === 'Exclude' ? false : record[name])
                        }
                    }
                    setdata({ incomeData: tempIncomeData, expenseData: tempExpenseData })
                    setDataBackUp(tempDataBackUp)

                } else throw result.statusText
            } else throw result.statusText
        } catch (err) {
            console.log(err)
        }
    }


    const handleShowData = (target: 'income' | 'expense') => {
        if (target === 'income') {
            setShowData({ income: !showData.income, expense: showData.expense })
        } else {
            setShowData({ income: showData.income, expense: !showData.expense })
        }
    }


    const onSubmit = async (e: FieldValues) => {
        setLoading({ isLoading: true, progress: 0 })

        const toUpdate: string[] = []
        const dataMap = new Map<string, any>()
        for (const name in e) {
            const id = name.split('_')[0]
            const dataName = name.split('_')[1]
            if (id !== 'Add') {
                if (dataName === 'BudgetAmount' || dataName === 'ActualAmount') e[name] = CurrencyToNumber(e[name])
                if (dataBackUp.get(name) !== e[name] && !toUpdate.includes(id)) toUpdate.push(id)

                //group values from form by id
                dataMap.set(id, { ...dataMap.get(id), [dataName]: e[name] })
            }
        }

        // now call api for each value to update
        let didFail = 0
        const toUpdateLength = isAdding ? toUpdate.length + 1 : toUpdate.length
        for (let i = 0; i < toUpdate.length; i++) {
            try {
                const requestBody = { id: toUpdate[i], ...dataMap.get(toUpdate[i]) }
                const result = await (new APIRequest('/budgetDetails/', 'PUT', null, requestBody).GenerateRequest())
                if (result.status === 200) {
                    const body = await result.json()
                    if (body.status !== 200) throw body.message
                } else throw result.statusText
            } catch (err) {
                console.log(err)
                didFail++
            }
            setLoading({ isLoading: true, progress: ((i + 1) / toUpdateLength) * 100 })
        }

        if (isAdding) {
            try {
                const body = {
                    BudgetID: objID,
                    Description: e.Add_Description,
                    ItemType: e.Add_ItemType,
                    BudgetAmount: CurrencyToNumber(e.Add_BudgetAmount),
                    ActualAmount: CurrencyToNumber(e.Add_ActualAmount),
                    Exclude: e.Add_Exclude
                }

                const result = await (new APIRequest('/budgetDetails/', 'POST', null, body).GenerateRequest())
                if (result.status === 200) {
                    const body = await result.json()
                    if (body.status !== 200) throw body.message
                } else throw result.statusText
            } catch (err) {
                console.log(err)
                didFail++
            }
        }

        await getBudgetDetails()

        //make sure progress is set to 100
        if (didFail === 0) {
            setLoading({ isLoading: true, progress: 100 })
            setIsAdding(false)
        }

        setTimeout(() => {
            setLoading({ isLoading: false, progress: 0 })
        }, 2000);
    }


    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setValue(e.target.name, Currency(e.target.value || ''))
    }

    // This only works since all are curreny fields
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const EXCEPTION = e.ctrlKey || e.altKey || e.metaKey || e.key === 'Backspace' || e.key === 'Delete' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Tab' || (e.key === '.' && !e.currentTarget.value.includes('.'))
        const DECIMALINVALID = e.key === '.' ? (e.currentTarget.value.includes('.') ? (e.currentTarget.value.split('.')[1].split('').length + 1 === 3 ? true : false) : false) : false

        //only digits and the exceptions above will work
        if ((!/^\d$/.test(e.key) && (!EXCEPTION || e.key === ' ')) || (DECIMALINVALID && !EXCEPTION)) {
            e.preventDefault()
        }
    }

    useEffect(() => { getBudgetDetails() }, [objID])

    // handle total changes
    useEffect(() => {
        let incomeBudgetTotal = 0
        let incomeActualTotal = 0
        let expenseBudgetTotal = 0
        let expenseActualTotal = 0

        data?.incomeData.forEach(budgetDetail => {
            if (!budgetDetail.Exclude) {
                incomeBudgetTotal = incomeBudgetTotal + budgetDetail.BudgetAmount
                incomeActualTotal = incomeActualTotal + budgetDetail.ActualAmount
            }
        })

        data?.expenseData.forEach(budgetDetail => {
            if (!budgetDetail.Exclude) {
                expenseBudgetTotal = expenseBudgetTotal + budgetDetail.BudgetAmount
                expenseActualTotal = expenseActualTotal + budgetDetail.ActualAmount
            }
        })

        const netBudget = incomeBudgetTotal - expenseBudgetTotal
        const netActual = incomeActualTotal - expenseActualTotal
        const variance = netActual - netBudget

        setTotals({ incomeBudgetTotal: Currency(String(incomeBudgetTotal)), incomeActualTotal: Currency(String(incomeActualTotal)), expenseBudgetTotal: Currency(String(expenseBudgetTotal)), expenseActualTotal: Currency(String(expenseActualTotal)), netBudget: Currency(String(netBudget)), netActual: Currency(String(netActual)), variance: Currency(String(variance)) })
    }, [data])


    return (
        <>
            <div className="py-10 ">

                {/* table headers */}
                <div className="text-center border  body-large">
                    <div className="grid grid-cols-12 h-[56px] text-center items-center font-bold">
                        <span className="col-span-2 border-r">Description</span>
                        <span className="col-span-2 border-x">Item Type</span>
                        <span className="col-span-2 border-x">Budget Amount</span>
                        <span className="col-span-2 border-x">Actual Amount</span>
                        <span className="col-span-2 border-x">Exclude</span>
                        <form onSubmit={handleSubmit(onSubmit)} className={`grid grid-cols-1 gap-x-3 m-auto ${loading.isLoading ? 'w-full px-4' : ''} col-span-2`}>
                            {
                                loading.isLoading ?

                                    <div className=" w-ful m-0">
                                        <ProgressBar progress={loading.progress} isRefreshing={loading.isLoading} />

                                    </div>


                                    :
                                    <span>
                                        <span> {!isAdding ? <Button label="Add" styleType="text" action={() => setIsAdding(true)} type="button" /> : <Button label="Cancel" styleType="text" action={() => setIsAdding(false)} type="button" />}</span>
                                        <span > <Button label="Save" styleType="text" action={() => null} type="submit" disabled={!isDirty && !isAdding} /></span>

                                    </span>


                            }
                        </form>
                    </div>
                </div>

                {/* Table body */}
                <div className=" text-center  body-large border border-t-0">
                    {
                        <div>

                            {/* add row */}
                            {
                                isAdding &&

                                <div className={`grid grid-cols-12 border`} >
                                    <input {...register("Add_Description")} className="border-x hover:bg-md-primary-container outline-none p-1 col-span-2 text-start" />
                                    <select {...register("Add_ItemType")} className=" bg-md-background border-x hover:bg-md-primary-container outline-none p-1 col-span-2 text-right" >
                                        <option value={INCOME}>Income</option>
                                        <option value={EXPENSE}>Expense</option>
                                    </select>
                                    <input {...register("Add_BudgetAmount")} onKeyDown={handleKeyDown} onBlur={handleBlur} className="border-x hover:bg-md-primary-container outline-none p-1 col-span-2 text-right" />
                                    <input {...register("Add_ActualAmount")} onKeyDown={handleKeyDown} onBlur={handleBlur} className="border-x hover:bg-md-primary-container outline-none p-1 col-span-2 text-right" />
                                    <input {...register("Add_Exclude")} type="checkbox" className="m-auto border-x hover:bg-md-primary-container outline-none p-1 col-span-2 text-right" />
                                    <span></span>
                                </div>
                            }


                            {/* income header */}
                            <div className="border p-3 grid grid-cols-12 cursor-pointer" onClick={() => handleShowData('income')}>
                                <span >
                                    <svg className={`flex m-auto transition-transform ${showData.income ? 'rotate-180' : ''} `} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" /></svg>
                                </span>
                                <span className="col-span-3 text-start">Income</span>
                                <span className="col-span-2 text-end">{totals.incomeBudgetTotal}</span>
                                <span className="col-span-2 text-end">{totals.incomeActualTotal}</span>
                            </div>

                            {/* income data */}
                            <div className={`grid text-center transition-all ${showData.income ? `grid-rows-[1fr]` : 'grid-rows-[0fr]'} overflow-hidden`}>
                                <div className="overflow-hidden">
                                    {
                                        data?.incomeData?.map(detail => (
                                            <form key={`income${detail.BudgetDetailID}`} className={`grid grid-cols-12 border`} >
                                                <input {...register(`${detail.BudgetDetailID}_Description`)} className="border-x hover:bg-md-primary-container outline-none p-1 col-span-2 text-start" defaultValue={detail.Description} />
                                                <select {...register(`${detail.BudgetDetailID}_ItemType`)} className=" bg-md-background border-x hover:bg-md-primary-container outline-none p-1 col-span-2 text-right" defaultValue={detail.ItemType} >
                                                    <option value={INCOME}>Income</option>
                                                    <option value={EXPENSE}>Expense</option>
                                                </select>
                                                <input {...register(`${detail.BudgetDetailID}_BudgetAmount`)} onKeyDown={handleKeyDown} onBlur={handleBlur} className="border-x hover:bg-md-primary-container outline-none p-1 col-span-2 text-right" defaultValue={detail.BudgetAmount || detail.BudgetAmount === 0 ? Currency(String(detail.BudgetAmount)) : undefined} />
                                                <input {...register(`${detail.BudgetDetailID}_ActualAmount`)} onKeyDown={handleKeyDown} onBlur={handleBlur} className="border-x hover:bg-md-primary-container outline-none p-1 col-span-2 text-right" defaultValue={detail.ActualAmount || detail.ActualAmount === 0 ? Currency(String(detail.ActualAmount)) : undefined} />
                                                <input {...register(`${detail.BudgetDetailID}_Exclude`)} type="checkbox" className="m-auto border-x hover:bg-md-primary-container outline-none p-1 col-span-2 text-right" defaultChecked={detail.Exclude} />
                                                <span></span>
                                            </form>
                                        ))
                                    }
                                </div>
                            </div>

                            {/* expense header */}
                            <div className="border p-3 grid grid-cols-12 cursor-pointer" onClick={() => handleShowData('expense')}>
                                <span >
                                    <svg className={`flex m-auto transition-transform ${showData.expense ? 'rotate-180' : ''} `} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" /></svg>
                                </span>
                                <span className="col-span-3 text-start">Expense</span>
                                <span className="col-span-2 text-end">{totals.expenseBudgetTotal}</span>
                                <span className="col-span-2 text-end">{totals.expenseActualTotal}</span>
                            </div>

                            {/* expense data */}
                            <div className={`grid text-center transition-all ${showData.expense ? `grid-rows-[1fr]` : 'grid-rows-[0fr]'} overflow-hidden`}>
                                <div className="overflow-hidden">
                                    {
                                        data?.expenseData?.map(detail => (
                                            <form key={`income${detail.BudgetDetailID}`} className={`grid grid-cols-12 border`} >
                                                <input {...register(`${detail.BudgetDetailID}_Description`)} className="border-x hover:bg-md-primary-container outline-none p-1 col-span-2 text-start" defaultValue={detail.Description} />
                                                <select {...register(`${detail.BudgetDetailID}_ItemType`)} className=" bg-md-background border-x hover:bg-md-primary-container outline-none p-1 col-span-2 text-right" defaultValue={detail.ItemType} >
                                                    <option value={INCOME}>Income</option>
                                                    <option value={EXPENSE}>Expense</option>
                                                </select>
                                                <input {...register(`${detail.BudgetDetailID}_BudgetAmount`)} onKeyDown={handleKeyDown} onBlur={handleBlur} className="border-x hover:bg-md-primary-container outline-none p-1 col-span-2 text-right" defaultValue={detail.BudgetAmount || detail.BudgetAmount === 0 ? Currency(String(detail.BudgetAmount)) : undefined} />
                                                <input {...register(`${detail.BudgetDetailID}_ActualAmount`)} onKeyDown={handleKeyDown} onBlur={handleBlur} className="border-x hover:bg-md-primary-container outline-none p-1 col-span-2 text-right" defaultValue={detail.ActualAmount || detail.ActualAmount === 0 ? Currency(String(detail.ActualAmount)) : undefined} />
                                                <input {...register(`${detail.BudgetDetailID}_Exclude`)} type="checkbox" className="m-auto border-x hover:bg-md-primary-container outline-none p-1 col-span-2 text-right" defaultChecked={detail.Exclude} />
                                                <span></span>
                                            </form>
                                        ))
                                    }
                                </div>
                            </div>

                            {/* net values */}
                            <div className=" p-1 grid grid-cols-12 text-end items-center">
                                <span className="col-span-3"></span>
                                <span>Net</span>
                                <span className="col-span-2 p-1">{totals.netBudget}</span>
                                <span className="col-span-2 p-1">{totals.netActual}</span>
                            </div>

                            {/* variance */}
                            <div className=" p-1 grid grid-cols-12 text-end items-center">
                                <span className="col-span-3"></span>
                                <span>Variance</span>
                                <span className="col-span-2 p-1">{totals.variance}</span>

                            </div>

                        </div>
                    }
                </div>
            </div>
        </>
    )
}

const ProgressBar = ({ progress, isRefreshing }: { progress: number, isRefreshing: boolean }) => {
    return (
        <div className={` bg-gray-200 rounded-full h-4 transition-all duration-300 `} style={{ opacity: isRefreshing ? '100%' : '0%' }}>
            <div
                className={` ${progress === 100 ? 'bg-emerald-300' : 'bg-md-primary'} h-full rounded-full transition-all duration-300`}
                style={{ width: `${progress}%` }}
            ></div>
        </div>
    );
};