import { monthlyPerformanceCommunity } from './MonthlyPerformanceReport'

export default function ColoredCell({community, month}: { community: monthlyPerformanceCommunity, month: string }) {

    const filteredHouses = community.communityHouses.filter(house => house.monthName === month);

    const hasEstimated = filteredHouses.filter(house => house.eventStatus === 'Estimated').length !== 0;
    const hasScheduled = filteredHouses.filter(house => house.eventStatus === 'Scheduled').length !== 0;
    const hasActual = filteredHouses.filter(house => house.eventStatus === 'Actual').length !== 0;

    const backgroundColor = hasEstimated && !hasScheduled && !hasActual ? 'bg-pale-green' : (
        !hasEstimated && hasScheduled && !hasActual ? 'bg-light-blue' : (
        hasEstimated && hasScheduled && !hasActual ? 'bg-diagonal-split-pale-green-light-blue' : (
        hasEstimated && !hasScheduled && hasActual ? 'bg-diagonal-split-pale-green-white' : (
        !hasEstimated && hasScheduled && hasActual ? 'bg-diagonal-split-white-light-blue' : ''
    
        ))));

    return (
        <span className={`text-center p-2 border-r border-l border-b-2 border-gray-300 ${backgroundColor}`}>
                {community.communityHouses.filter(house => house.monthName === month).length === 0 ? ' ' : community.communityHouses.filter(house => house.monthName === month).length}
        </span>                           
)
}