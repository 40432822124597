import BSSLogo from "../../assets/BSS-Logo.jpg";
import { ChangeEvent, useEffect, useState } from "react";
import APIRequest from "../../helpers/CreateRequest";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

//JSR 6/13/23: Made large changes to make style and value changes with state instead of grabbing element
export default function SignIn(): JSX.Element {
  const [, setCookies] = useCookies();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(" ");
  const [emailInputStlye, setEmailInputStyle] = useState({});
  const [passwordInputStyle, setPasswordInputStyle] = useState({});
  const [errorMessageStyle, setErrorMessageStyle] = useState({});


  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") signIn()
    };

    document.addEventListener("keydown", handleKeyPress);

    //Cleanup after unmount
    return () => document.removeEventListener("keydown", handleKeyPress)

  }, [email, password]);

  function inputHandler(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.name === "email") setEmail(e.target.value)
    else if (e.target.name === "password") setPassword(e.target.value)
  }

  function checkInputs(): boolean {
    let isValid = true;

    if (email === "" || password === "") {
      if (email === "") {
        setEmailInputStyle({ border: "2px solid #f85757" });
      } else {
        setEmailInputStyle({})
      }
      if (password === "") {
        setPasswordInputStyle({ border: "2px solid #f85757", });
      }

      isValid = false;
    } else {
      setEmailInputStyle({});
      setPasswordInputStyle({});
    }

    return isValid;
  }

  async function signIn(): Promise<void> {
    const isValid = checkInputs();
    if (!isValid) return;
    setErrorMessageStyle({ opacity: "0" })
    const result = await new APIRequest("/authenticate", "POST", null, {
      email: email,
      password: password,
    }).GenerateRequest();
  

    if (result.status === 200) {
      //set up user settings
      const body = await result.json()
      setCookies('settings', body.tenantSettings)
      setCookies("token", body.token);

      navigate("/app/home");
    } else if (result.status === 400 || result.status === 401) {
      setErrorMessage("Invalid email or password");
      setErrorMessageStyle({ opacity: "1" });
    } else {
      setErrorMessage("Unable to Process Request");
      setErrorMessageStyle({ opacity: "1" });
    }
  }

  return (
    //  Container
    <div className="h-full w-screen bg-cyan-100 text-center absolute">
      {/* Sign in Box */}
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[500px] w-[500px] bg-white rounded-sm shadow-lg">
        {/* Logo */}
        <img className="m-auto mt-16 mb-4" alt="BSS Logo" src={BSSLogo} height="55px" width="160px" />
        {/* Error Message */}
        <div className=" h-5 text-red-400 text-xs opacity-0 transition-[opacity_ease] duration-500 " style={errorMessageStyle}>
          {errorMessage}
        </div>
        {/* Email input */}
        <input
          className='w-72 h-12 mt-3 border-2 border-black p-2 pl-4  text-gray-700 bg-white rounded-md focus:border-2 focus:border-blue-300 focus:shadow-none outline-none'
          name="email"
          type="text"
          placeholder="Email"
          onChange={(e) => inputHandler(e)}
          style={emailInputStlye}
          autoFocus
        />
        {/* password input */}
        <input
          className='w-72 h-12 mt-3 border-2 border-black p-2 pl-4 text-gray-700 bg-white rounded-md focus:border-2 focus:border-blue-300 focus:shadow-none outline-none'
          name="password"
          type="password"
          placeholder="Password"
          onChange={(e) => {
            inputHandler(e)
          }}
          style={passwordInputStyle}
        />
        {/* submit button */}
        <input
          className=' w-72 h-12 mt-3 p-2 pl-4 text-white  bg-blue-300 rounded-md outline-none hover:bg-blue-400 focus:bg-blue-400 transition-colors cursor-pointer'
          data-testid="continueBtn"
          value="Sign in"
          type="submit"
          onClick={() => signIn()}
        />
        {/* Contact for account text */}
        <div className="m-auto w-72 leading-5 text-center mt-3">
          Please send an email to
          Support@BuildSoftSolutions.com
          to request an account
        </div>
      </div>
    </div>
  );
}
