import {  UseFormRegisterReturn, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { CurrencyToNumber } from "../../helpers/InputValueConverter";
import PercentField from "../../components/atoms/forms/Fields/PercentField";

type GrossMarginType = {
    name: string,
    label: string,
    supportingText?: string,
    disabled?: boolean,
    required?: boolean,
    value: string | undefined,
    formRef: UseFormRegisterReturn<string>
    setValue: UseFormSetValue<any>
    watch: UseFormWatch<any>
}


export default function GrossMargin(props: GrossMarginType) {
    // must use this state as the value since the base field will take the value from the GetObj function which sometimes return NaN
    //It also solved the problem of the value not appearing in certain situations when it should
    const [grossMargin, setGrossMargin] = useState<string | undefined>() 
    const watchSalePrice = props.watch('EstimatedHomeBuildingSalePrice')
    const watchCost = props.watch('EstimatedHomeBuildingCosts')


    useEffect(() => {
        if (!props.disabled || watchSalePrice || watchCost) {
            const salePrice = CurrencyToNumber(watchSalePrice) || 0
            const cost = CurrencyToNumber(watchCost) || 0
            const grossProfit = salePrice - cost

            let calcGrossMargin = 0
            if (grossProfit !== 0) {
                calcGrossMargin = (grossProfit / salePrice) * 100
            }

            calcGrossMargin = parseFloat(calcGrossMargin.toFixed(4))

            setGrossMargin(String(calcGrossMargin))
        } else {
            setGrossMargin(undefined)
        }
    }, [watchSalePrice, watchCost])

    return (
        <PercentField {...props} value={grossMargin}/>
    )
}