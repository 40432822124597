import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import APIRequest from "../../helpers/CreateRequest";
import { FieldValues, useForm } from "react-hook-form";
import CheckBoxField from "../../components/atoms/forms/Fields/CheckboxField";

export default function SelectSalesForecasts({ parentID }: { parentID?: string }) {
    const [salesForecasts, setSalesForecasts] = useState<{ name: string, id: string, selected: boolean, description: string }[]>([])
    const [modifying, setModifying] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [count, setCount] = useState<number>(0)

    const {
        register,
        handleSubmit,
        setValue
    } = useForm()

    const GetSalesForecasts = async () => {
        if (parentID) {
            try {
                const result = await (new APIRequest('/scenarios/salesForecasts', 'POST', null, { id: parentID }).GenerateRequest())
                const body = await result.json()
                if (body.status === 200) {
                    const recordset = body.recordset
                    const tempSalesForecasts: { name: string, id: string, selected: boolean, description: string }[] = []
                    for (let i = 0; i < recordset.length; i++) {
                        tempSalesForecasts.push({ name: `${recordset[i].SalesForecastName} ${recordset[i].CommunityName}`, id: recordset[i].SalesForecastID, description: recordset[i].Description, selected: recordset[i].Selected === 'True' ? true : false })
                    }
                    setSalesForecasts(tempSalesForecasts)
                }
            } catch (err) {
                console.log(err)
            }
        }
    }
    useEffect(() => {
        GetSalesForecasts()
    }, [parentID])

    useEffect(() => {
        let i = 0;
        let tempCount = 0
        while (i < salesForecasts.length) {
            if (salesForecasts[i].selected) tempCount++
            i++
        }
        setCount(tempCount)
    }, [salesForecasts])


    const onSubmit = async (e: FieldValues) => {
        let ids = ''

        let i = 0
        for (const name in e) {
            if (e[name]) {
                ids = (i++ === 0 ? '' : ids.concat(','))
                ids = ids.concat(e[name])
            }
        }

        try {
            const result = await new APIRequest('/scenarios/salesForecasts/selected', 'POST', null, { id: parentID, ids: ids }).GenerateRequest()
            if (result.status === 200) {
                setModifying(false)
                GetSalesForecasts()
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            <div className="w-full flex flex-col">
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* Header */}
                    {
                        parentID &&

                        <div className=" h-[30px] grid grid-cols-[200px_70px_auto]  my-auto">


                            <span className=" label-large my-auto mr-[8px] grid grid-cols-[30px_auto] cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
                                {
                                    parentID && !modifying ?
                                        <svg className={`${isOpen ? 'rotate-180' : ''} transition-all `} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-360 280-560h400L480-360Z" /></svg>
                                        :
                                        <span></span>
                                }
                                <span className="body-large">Sales Forecasts ({count})</span>


                            </span>

                            {
                                modifying ?

                                    <span className="grid grid-cols-2 ">
                                        {/* Cancel */}
                                        <button onClick={() => setModifying(false)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg>
                                        </button>

                                        {/* submit */}
                                        <button type="submit">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" /></svg>
                                        </button>
                                    </span>

                                    :
                                    // edit mode
                                    <button onMouseDown={() => setModifying(!modifying)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" /></svg>
                                    </button>

                            }
                        </div>
                    }

                    {
                        modifying ?

                            <ul className="w-full pl-[40px] grid grid-cols-[max-content_max-content_auto] gap-x-6">
                                <>
                                    <span></span>
                                    <span className="font-bold">Name</span>
                                    <span className="font-bold">Description</span>
                                </>
                                {
                                    salesForecasts.map(salesForecast => (
                                        <>
                                            <span key={`scenarioSalesForecast${salesForecast.id}checkbox`}>
                                                <CheckBoxField
                                                    name={`salesForecastSelectCheckBox${salesForecast.id}`}
                                                    disabled={false}
                                                    required={false}
                                                    value={salesForecast.id}
                                                    formRef={register(`salesForecastSelectCheckBox${salesForecast.id}`)}
                                                    defaultChecked={salesForecast.selected}
                                                    setValue={setValue}
                                                />
                                            </span>
                                            <span key={`scenarioSalesForecast${salesForecast.id}name`}>{salesForecast.name} </span>
                                            <span key={`scenarioSalesForecast${salesForecast.id}description`} className="text-left">{salesForecast.description}</span>
                                        </>
                                    ))
                                }
                            </ul>

                            :

                            isOpen ?

                                <div>
                                    {
                                        parentID ?

                                            salesForecasts.filter(salesForecast => salesForecast.selected).length > 0 ?

                                                <ul className="pl-[40px] grid grid-cols-[16px_max-content_auto] gap-x-6">
                                                    <>
                                                        <span></span>
                                                        <span className="font-bold">Name</span>
                                                        <span className="font-bold">Description</span>
                                                    </>
                                                    {
                                                        salesForecasts.map(salesForecast => (

                                                            salesForecast.selected &&

                                                            <>
                                                                <span key={`${salesForecast.name}${salesForecast.id}placeHolder1`}></span>
                                                                <Link key={`salesForecasts1${salesForecast.id}${salesForecast.name}Link`} to='/app/sales-forecasts' className=" hover:text-md-tertiary" state={{ id: salesForecast.id }}>{salesForecast.name}</Link>
                                                                <span key={`${salesForecast.name}${salesForecast.id}description`} className="text-left">
                                                                    {salesForecast.description}
                                                                </span>
                                                            </>

                                                        ))
                                                    }
                                                </ul>

                                                :

                                                <div className="pl-[40px]">No Sales Forecasts Selected</div>
                                            :

                                            <></>
                                    }
                                </div>

                                :

                                <></>
                    }
                </form>
            </div>
        </>
    )
}