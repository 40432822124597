import { UseFormRegisterReturn, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import NumberField from "../../components/atoms/forms/Fields/NumberField";

type YearType = {
    name: string,
    label: string,
    supportingText?: string,
    disabled?: boolean,
    required?: boolean,
    value: string | undefined,
    formRef: UseFormRegisterReturn<string>
    setValue: UseFormSetValue<any>
    watch: UseFormWatch<any>
}

export default function Year(props: YearType) {
    const fieldYear = useMemo(() => props.name === 'YearOne' ? 1 : 2, [props.name])
    useEffect(() => {
        let year;
        if (props.disabled && !props.value) {
            year = undefined
        } else if (props.value) {
            year = props.value
        } else {
            const tempYear = (
                props.value ? props.value :
                    props.disabled ? undefined :
                        fieldYear === 1 ? new Date().getFullYear() :
                            new Date().getFullYear() + 1
            )
            year = tempYear
        }

        props.setValue(props.name, year)
    }, [props.disabled, fieldYear])


    return (
        <NumberField
            {...props}
        />
    )
}