import { FieldValues, useForm } from 'react-hook-form'
import { useEffect, useMemo } from 'react';
import { field, mode } from "../../Types/FormTypes";
import APIRequest from '../../helpers/CreateRequest';
import Button from '../atoms/Button';
import Field from '../atoms/forms/Field';
import { CurrencyToNumber } from '../../helpers/InputValueConverter';
import DuplicateBudgetDetails from '../../pages/Budgets/DuplicateBudgetDetails';
// The fields componenet should be standardized and include the supporting text space regardless of if there is or not. (i think).


export default function NewForm({ name, mode, setMode, fields, obj, id, setID, emptySet, refresh, setRefresh, extraButtons, snackbarRefresh, setSnackbar, parentState, setParentState, disableEdit }: {
    name: { normal: string, plural: string, alias: string, aliasPlural: string, data: string, dataPlural: string },
    mode: mode,
    setMode: Function,
    fields: field[],
    obj?: any,
    id?: number,
    setID: Function
    emptySet: FieldValues,
    refresh: boolean,
    setRefresh: Function,
    extraButtons?: JSX.Element[]
    snackbarRefresh?: boolean
    setSnackbar?: Function
    parentState?: any
    setParentState?: Function
    disableEdit?: boolean
}) {
    const groups = useMemo(FilterGroups, [fields])

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting, isLoading },
        reset,
        watch,
        getValues,
        setValue,
    } = useForm({
        values: obj,
    })

    function FilterGroups() {
        let groupNames: string[] = []
        //loop through fields
        for (let i = 0; i < fields.length; i++) {
            const group = fields[i].group
            //only go forward if field.group exists
            if (group) {
                if (groupNames.indexOf(group) === -1) groupNames.push(group)
            }
        }
        return groupNames
    }

    const onSubmit = async (e: FieldValues) => {

        try {
            //filter and modify values to match type (mostly number)
            fields.forEach(field => {
                if (field.type === 'number') e[field.sendAs || field.name] = parseFloat(e[field.sendAs || field.name])
                else if (field.type === 'currency') e[field.sendAs || field.name] = CurrencyToNumber(e[field.sendAs || field.name])
                else if (field.type === 'percent') e[field.sendAs || field.name] = parseFloat(e[field.sendAs || field.name]) / 100
                else if (field.type === 'checkbox') e[field.sendAs || field.name] = e[field.sendAs || field.name] === true ? true : undefined
            })

            //Build request
            let request;
            if (mode !== 'delete') {

                if (mode === 'edit') e.id = id

                const method = (mode === 'add' || mode === 'duplicate' ? 'POST' : 'PUT')
                request = new APIRequest(`/${name.dataPlural}`, method, null, e)
            } else {
                request = new APIRequest(`/${name.dataPlural}`, 'DELETE', null, { id: id })
            }
            //Get Result
            const result = await request.GenerateRequest()
            const body = await result.json()
            //If okay, use new id from obj or use current id 
            if (body.status === 200) {
                const newID = mode === 'add' || mode === 'duplicate' ? body.id : (mode === 'delete' ? undefined : id)
                //currently reloading the page on reset... not the best practice in react becasue its meant to react not reload.. also its a single page app thing so reload ing is not ideal
                if (mode === 'delete') window.location.reload()
                if (mode === 'duplicate' && name.data == 'Budget' && id) await DuplicateBudgetDetails(id, newID)

                setID(newID)
                setRefresh(!refresh)
                setMode('view')

                //set snackbar
                if (setSnackbar) {
                    setSnackbar({
                        message: `${name.alias} ${mode === 'delete' ? 'removed' : mode === 'duplicate' ? 'duplicated' : 'saved'}`,
                        refresh: !snackbarRefresh
                    })
                }

            } else {
                // console.log(err)
                if (setSnackbar) {
                    setSnackbar({
                        message: `${name.alias} ${mode === 'delete' ? 'delete' : mode === 'duplicate' ? 'duplicate' : 'save'} failed`,
                        refresh: !snackbarRefresh
                    })
                }
            }

        } catch (err) {
            console.log(err)
            if (setSnackbar) {
                setSnackbar({
                    message: `${name.alias} ${mode === 'delete' ? 'delete' : mode === 'duplicate' ? 'duplicate' : 'save'} failed`,
                    refresh: !snackbarRefresh
                })
            }
        }
    }

    //when mode switches and there is a memory stored, if its add mode the form clears 
    useEffect(() => {
        reset(emptySet)

        if ((mode !== 'add' || !obj)) {
            reset(obj)
        }
    }, [mode])


    return (
        <div className='flex w-full h-full m-auto '>
            <form className='flex flex-col  rounded w-full' onSubmit={handleSubmit(onSubmit)}>

                {/* Inputs */}
                <div className='flex flex-row gap-x-5 gap-y-5'>
                    <div className='flex flex-col gap-x-5 gap-y-5  '>
                        &#x200B;
                        {
                            fields.map(field => (

                                !field.group ?

                                    (<div key={`${field.sendAs}${field.name}${field.alias}`} className='grid grid-cols-[300px_auto] w-full py-0.5'>

                                        <Field
                                            field={field}
                                            disabled={isLoading || isSubmitting || mode === 'view' || mode === 'delete'}
                                            value={getValues(field.sendAs || field.name)}
                                            setValue={setValue}
                                            formRef={register(field.sendAs || field.name, { required: field.required })}
                                            parentState={parentState}
                                            setParentState={setParentState}
                                            getValues={getValues}
                                            watch={watch}
                                        />

                                    </div>
                                    )

                                    :

                                    null
                            ))
                        }
                    </div>


                    {
                        name.normal === "Sales Plan" ?
                            <div className='flex flex-col gap-x-5 gap-y-5'>
                                <div className=' '>&#x200B;</div>
                                <div className='flex h-[48px] text-md-on-surface body-large items-center'>Year</div>
                                <div className='flex h-[48px] text-md-on-surface body-large items-center'>January</div>
                                <div className='flex h-[48px] text-md-on-surface body-large items-center'>February</div>
                                <div className='flex h-[48px] text-md-on-surface body-large items-center'>March</div>
                                <div className='flex h-[48px] text-md-on-surface body-large items-center'>April</div>
                                <div className='flex h-[48px] text-md-on-surface body-large items-center'>May</div>
                                <div className='flex h-[48px] text-md-on-surface body-large items-center'>June</div>
                                <div className='flex h-[48px] text-md-on-surface body-large items-center'>July</div>
                                <div className='flex h-[48px] text-md-on-surface body-large items-center'>August</div>
                                <div className='flex h-[48px] text-md-on-surface body-large items-center'>September</div>
                                <div className='flex h-[48px] text-md-on-surface body-large items-center'>October</div>
                                <div className='flex h-[48px] text-md-on-surface body-large items-center'>November</div>
                                <div className='flex h-[48px] text-md-on-surface body-large items-center'>December</div>
                            </div>

                            :

                            null
                    }

                    {
                        groups.map(group => (
                            <div key={`group${name.alias}${group}`} className='flex flex-col gap-x-5 gap-y-5 w-[200px]'>
                                <div className='text-center'>{group}</div>

                                {
                                    fields.map((field, index) => (

                                        field.group === group ?

                                            (<div key={`${field.sendAs}${field.name}${field.alias}`} className='grid grid-cols-[300px_auto] w-full py-0.5'>

                                                <Field
                                                    field={field}
                                                    disabled={isLoading || isSubmitting || mode === 'view' || mode === 'delete'}
                                                    value={getValues(field.sendAs || field.name)}
                                                    setValue={setValue}
                                                    formRef={register(field.sendAs || field.name, { required: field.required })}
                                                    parentState={parentState}
                                                    setParentState={setParentState}
                                                    getValues={getValues}
                                                    watch={watch}
                                                />

                                            </div>

                                            )

                                            :

                                            null
                                    ))
                                }

                            </div>
                        ))
                    }
                </div>



                {/* Buttons */}
                <div className='w-max mt-[20px] mb-[20px]'>
                    {
                        mode === 'view' ?
                            <div className='flex  gap-x-5'>

                                <Button
                                    label='Add'
                                    styleType='tonal'
                                    type='button'
                                    action={() => setMode('add')}
                                    disabled={false}
                                />

                                <Button
                                    label='Edit'
                                    styleType='tonal'
                                    type='button'
                                    action={() => setMode('edit')}
                                    disabled={!id || disableEdit}

                                />

                                <Button
                                    label='Delete'
                                    styleType='tonal'
                                    type='button'
                                    action={() => setMode('delete')}
                                    disabled={!id}
                                />

                                <Button
                                    label='Duplicate'
                                    styleType='tonal'
                                    type='button'
                                    action={() => setMode('duplicate')}
                                    disabled={!id}
                                />

                                {extraButtons}
                            </div>

                            :

                            <div className='grid grid-cols-2 gap-x-5 w-full'>

                                <Button
                                    label='Cancel'
                                    styleType='outlined'
                                    type='button'
                                    action={() => setMode('view')}
                                    disabled={isLoading || isSubmitting}
                                />

                                <Button
                                    label={
                                        mode === 'delete' ? 'Delete' : mode === 'duplicate' ? 'Duplicate' : 'Save'
                                    }
                                    styleType='filled'
                                    type='button'
                                    action={handleSubmit(onSubmit)}
                                    disabled={isLoading || isSubmitting || (!isDirty && mode !== 'delete')}
                                />

                            </div>
                    }
                </div>
            </form >
        </div >
    )
}
