import { Link } from "react-router-dom";
import { useState } from "react";

type NavSection = { name: string, items?: NavSectionItem[] }
type NavSectionItem = { label: string, to: string, subItems?: NavSectionItem[] }

const ForecastingItems: NavSectionItem[] = [
    { label: 'Business Plans', to: 'business-plans' },
    { label: 'Scenarios', to: 'scenarios' },
    { label: 'Reports', to: 'reports' }
]

const HomeBuildingItems: NavSectionItem[] = [
    { label: 'Regions', to: 'regions' },
    { label: 'Territories', to: 'territories' },
    { label: 'Sales Plans', to: 'sales-plans' },
    { label: 'Sales Forecasts', to: 'sales-forecasts' },
    { label: 'Communities', to: 'communities' },
    { label: 'Backlog', to: 'houses-backlog' },
    { label: 'Budgets', to: 'budgets' },
    { label: 'Tools', to: '', subItems: [{ label: 'Joint Venture Spec Tool', to: 'joint-venture-spec-tool' },] },
];

const RealEstateItems: NavSectionItem[] = [
    { label: 'Developments', to: 'developments' },
    { label: 'Concept Plans', to: 'concept-plans' },
];

const OperationsManagementItems: NavSectionItem[] = [
    { label: 'Positions', to: 'positions' },
    { label: 'Roles', to: 'roles' },
    { label: 'Procedures', to: 'procedures' }
];

const OrganizationSettingsItems: NavSectionItem[] = [
    { label: 'Business Units', to: 'business-units' },
    { label: 'Divisions', to: 'divisions' },
    { label: 'Departments', to: 'departments' },
    { label: 'Employees', to: 'employees' }
];

export default function HeaderNavigation() {
    return (
        <div className="bg-md-background w-full h-[40px] flex justify-center align-middle text-left px-2 border-y-2 border-md-on-background">
            <div className="h-full w-full m-auto max-w-[1280px] label-large items-center flex flex-row ">
                <Link className="mx-auto h-full" to={'/app/home'} ><NavSection name="Home" /></Link>
                <NavSection name="Forecasting" items={ForecastingItems} />
                <NavSection name="Home Building" items={HomeBuildingItems} />
                <NavSection name="Real Estate" items={RealEstateItems} />
                <NavSection name="Operations Management" items={OperationsManagementItems} />
                <NavSection name="Organization Settings" items={OrganizationSettingsItems} />
                <Link className="mx-auto h-full" to={'/app/data-management'} ><NavSection name="Data Management" /></Link>
            </div>
        </div>
    );
}

function NavSection({ name, items }: NavSection) {
    const [hover, setHover] = useState(false);

    const handleMouseEnter = () => {
        setHover(true);
    }
    const handleMouseLeave = () => {
        setHover(false);
    }

    return (
        <div className="relative flex h-full cursor-pointer m-auto" aria-expanded={hover} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <span className={`flex px-3 h-full w-full items-center text-center justify-center ${hover ? 'text-md-primary' : ''}`}>
                {name}
            </span>

            <div className={`top-full w-full z-50 absolute m-auto cursor-auto transition-all bg-md-background flex flex-row shadow-2xl rounded-lg h-max border-x border-b ${hover && items ? '' : 'hidden'}`}>
                <div className="flex w-full flex-col">
                    {items?.map(item => <NavSectionItem key={`navsectionitem${item.label}${item.to}`} {...item} />)}
                </div>
            </div>
        </div>
    );
}

function NavSectionItem({ label, to, subItems }: NavSectionItem) {
    return (
        subItems ?
            <SubNavSection name={label} items={subItems} /> :

            <Link
                to={`/app/${to}`}
                className="w-full min-w-max py-2 pl-2 pr-3 label-medium hover:bg-md-surface-variant hover:text-md-primary"
            >
                <span>{label}</span>
            </Link>
    );
}

function SubNavSection({ name, items }: NavSection) {
    const [hover, setHover] = useState(false);

    const handleMouseEnter = () => {
        setHover(true);
    }
    const handleMouseLeave = () => {
        setHover(false);
    }
    return (
        <div className="relative w-full min-w-max py-2 pl-2 pr-3 label-medium hover:bg-md-surface-variant text-md-on-background hover:text-md-primary" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <span className={`w-full hover:bg-md-surface-variant hover:text-md-primary ${hover ? 'text-md-primary' : ''}`}>
                <span>{name}</span>
                <svg className="-rotate-90 float-right" xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 -960 960 960" width="16"><path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" /></svg>

            </span>
            <div className={`left-full top-0 w-max z-50 absolute cursor-auto overflow-hidden transition-all bg-md-background flex flex-col shadow-2xl rounded-lg border-x border-b text-md-on-background ${hover ? '' : 'hidden'}`}>
                <div className="flex w-full flex-col">
                    {items?.map(item => <NavSectionItem key={`navsectionitemSubSection${item.label}${item.to}`} {...item} />)}
                </div>
            </div>
        </div>
    );

}
