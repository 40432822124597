import { useEffect, useState } from "react"

export default function FormSection(props: { name: string, triggerCollapse: boolean, children: JSX.Element[] | JSX.Element, hasSubSection?: boolean, headers?: { label: string, value: any }[] }) {
    const [open, setOpen] = useState(true)

    useEffect(() => {
        if (props.triggerCollapse !== open) setOpen(props.triggerCollapse)
    }, [props.triggerCollapse])

    return (
        <>
            <div className="w-full h-[36px] py-1 border-2 flex flex-row gap-x-3 px-2 cursor-pointer" onClick={() => setOpen(!open)} >
                <span className="relative my-auto">
                    <svg className={`transition-transform duration-150 ${open ? 'rotate-0' : 'rotate-90'}`} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="--md-sys-color-on-background"><path d="M200-440v-80h560v80H200Z" /></svg>
                    <svg className={`absolute top-0 transition-all duration-150 ${open ? '-rotate-90 opacity-0' : 'opacity-100'}`} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="--md-sys-color-on-background"><path d="M200-440v-80h560v80H200Z" /></svg>
                </span>
                <span className="my-auto flex">{props.name}
                    {
                        props.headers ?
                            <span className="flex">
                                <p>&nbsp;-&nbsp;</p>
                                {props.headers.map((header, index) => <p key={`formSection${props.name}header${header.label}`}>{header.label}: {header.value}{props.headers ? index + 1 < props.headers?.length ? <>;&nbsp;</> : '' : ''}</p>)}
                            </span>
                            :
                            <></>
                    }
                </span>
            </div>
            <div className={`grid ${open ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'}`}>
                <div className={`${open ? 'overflow-visible' : 'overflow-hidden'} `}>

                    <div className={`flex flex-wrap ${!props.hasSubSection ? 'w-[580px] gap-y-[24px]' : 'justify-center gap-y-[4px]'} m-auto gap-x-[40px] py-3  px-5`}>
                        {props.children}
                    </div>
                </div>
            </div>
        </>

    )
}