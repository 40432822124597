import { SelectFieldProps } from "./SelectField"


interface MultiSelectDropdownProps extends SelectFieldProps { selected: string[] }

export default function MultiSelectField(props: MultiSelectDropdownProps){

    const toggleOption = ({ value }: { value: string }) => {
        props.setValue((prevSelected: any) => {
            // if it's in, remove it, if it's not, add it
            let newArray = [...prevSelected[props.name]]
            if (newArray.includes(value)) {
                newArray = newArray.filter(item => item !== value)
            } else {
                newArray.push(value)
            }
            return { ...prevSelected, [props.name]: newArray };

        })
    }


    return (
        <div className={`${props.size === "sm" ? "w-[105px]" : props.size === "md" ? "w-[250px]" : "w-[540px]"} h-[48px] flex relative flex-col max-h-[400px] body-small`}>
            <label
                htmlFor={props.name}
                className={`w-max h-[20px] ${props.required ? "after:content-['*'] after:ml.5" : ""}`
                }
            >
                {props.label}
            </label>
            <div className="font-roboto relative  group">
                <div className="border  text-sm py-1 px-2 h-[28px]  border-md-outline flex justify-between items-center cursor-pointer">
                    <div>{props.selected.length} selected</div>
                </div>
                <ul className="hidden absolute box-border overflow-auto max-h-[200px] z-30 left-0 w-full list-none pl-0 border border-gray-300 p-1 group-hover:block bg-white">
                    {props.options && props.options.length > 0 ? props.options.map(option => {
                        const isSelected = props.selected.includes(option.value);

                        return (
                            <li key={props.name + props.label + option.name + option.value} className="flex items-center p-2 cursor-pointer hover:bg-gray-100" onClick={() => toggleOption(option)}>
                                <input type="checkbox" checked={isSelected} readOnly className="mr-2" />
                                <span>{option.name}</span>
                            </li>
                        );
                    }) : <li className="text-center">No results</li>}
                </ul>

            </div>
        </div >
    )
}