import { Cookies } from "react-cookie"
import APIRequest from "../../helpers/CreateRequest"
import { CurrencyToNumber } from "../../helpers/InputValueConverter"

//Calls insert proc as normal for budget but wit hthe provided id 
export default async function DuplicateBudgetDetails(oldBudgetID: number, newBudgetID: number) {
    let didFail = 0

    try {
        const headers: HeadersInit = new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow": "true",
            Authorization: `Bearer ${new Cookies().get('token')}`,
            'BudgetID': oldBudgetID.toString()
        })
        const result = await (new APIRequest(`/budgetDetails`, 'GET', headers, null).GenerateRequest())

        if (result.status === 200) {
            const body = await result.json()
            if (body.status === 200) {
                const budgetDetails = body.recordset


                for (let i = 0; i < budgetDetails.length; i++) {
                    const details = budgetDetails[i]
                    console.log(details)
                    const body = {
                        BudgetID: newBudgetID,
                        Description: details.Description,
                        ItemType: details.ItemType,
                        BudgetAmount: CurrencyToNumber(details.BudgetAmount),
                        ActualAmount: CurrencyToNumber(details.ActualAmount),
                        Exclude: details.Exclude
                    }

                    const result = await (new APIRequest('/budgetDetails/', 'POST', null, body).GenerateRequest())
                    if (result.status === 200) {
                        const body = await result.json()
                        if (body.status !== 200) throw body.message
                    } else throw result.statusText

                }
            } else throw body.message
        }

    } catch (err) {
        console.log(err)
        didFail++
    }
}