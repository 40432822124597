import { useEffect, useState } from "react"
import { UseFormRegisterReturn, UseFormSetValue } from "react-hook-form"

export interface DateFieldType {
    name: string,
    label: string,
    supportingText?: string,
    disabled?: boolean,
    required?: boolean,
    value: string | undefined,
    formRef: UseFormRegisterReturn<string>,
    setValue: UseFormSetValue<any>
    readonly?: boolean
}

export default function DateField({ name, label, supportingText, disabled, required, value, formRef, setValue, readonly }: DateFieldType) {
    const [isFocused, setIsFocused] = useState(false)
    const [tempValue, setTempValue] = useState<string>('')


    useEffect(() => {
        setTempValue(value || '')
        setValue(name, value || '')
    }, [value, disabled, name, setValue])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const changeValue = e.target.value
        formRef.onChange(e)
        setTempValue(changeValue)
        setValue(name, changeValue, { shouldDirty: true })
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        formRef.onBlur(e)
        setIsFocused(false)
    }

    return (
        <div key={`${name}${label}Input`}>
            <div className="relative">
                <input
                    type="date"
                    disabled={disabled}
                    required={required}
                    className={`h-[40px] w-[200px] rounded-[2px] outline-none outline-[1.5px] text-md-on-surface body-large text-right pr-[14px] pl-[14px]  transition-[color_opacity] duration-300
                    ${disabled ? '  outline-gray-300 dark:outline-gray-300 text-opacity-[.38] cursor-default' : ' outline-md-outline cursor-text '}
                    ${isFocused ? ' outline-[2.5px]  text-black outline-md-primary text-md-on-surface' : ''}
                     ${!disabled && !isFocused ? 'hover:outline-md-on-surface ' : ''}
                     ${(!isFocused && !value) || (!tempValue && value && !isFocused) ? ' text-transparent  text-opacity-0  ' : ' '}
                    `}
                    onFocus={() => setIsFocused(true)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    defaultValue={value}
                    ref={formRef.ref}
                    readOnly={readonly}
                />


                <label
                    htmlFor={name}
                    className={`absolute flex items-center h-[16px] pointer-events-none z-10 transition-[left_top] duration-300
                    ${isFocused || (disabled && value) || tempValue ? ` ${isFocused ? 'text-md-primary' : ''} top-[-12px] bg-white px-1 left-[4px] body-large ` : 'top-[12px] left-[16px] text-md-on-surface-variant bg-transparent'}
                    ${required ? "after:content-['*'] after:ml.5" : ''}
                    ${disabled ? ` ${!value ? 'opacity-[.38]' : ''} text-md-on-surface body-large` : ''}
                     ${!disabled && !isFocused ? 'text-opacity-100 hover:text-md-on-surface body-large' : ''}
                    `}
                >
                    {label}
                </label>



            </div>
            <div className={`font-sans text-[12px] tracking-[0.3px] pt-[4px] px-[16px] 
        ${disabled ? ' text-md-on-surface opacity-[.38]' : 'text-md-on-surface-variant hover:text-md-on-surface-variant'}
        ${isFocused ? ' text-md-on-surface-variant' : ''}
         
        `}>{supportingText}</div>
        </div>


    )
}
