import jsPDF from "jspdf";
import { Currency } from "../../helpers/InputValueConverter";
import { JVSpec } from "./JVSpec";
import ReportCalculations from "./ReportCalculations";

export default function Report({ form }: { form: JVSpec }) {
    const calculations = ReportCalculations(form)

    const handleDownload = () => {
        // remove download svg from html
        const downloadSvg = document.getElementById('downloadSVG')
        if (downloadSvg) {
            downloadSvg.remove()
        }

        // Get the content to download
        const downloadContent = (document.getElementById('report') as HTMLElement).innerHTML;

        // create and save doc
        const doc = new jsPDF()
        doc.html(downloadContent, {
            callback: (doc) => {
                doc.save('report.pdf')

                // add back the downloadSvg
                if (downloadSvg) {
                    document.getElementById('downloadSVGParent')?.appendChild(downloadSvg)
                }
            },
            margin: [10, 10, 10, 10],
            autoPaging: 'text',
            filename: 'JVSpec.pdf',
            x: 0,
            y: 0,
            width: 180,
            windowWidth: 900
        })
    }


    return (
        <div id="report" className="text-sm h-full w-full">
            {/* Deal Name */}
            <div className="mb-4 grid grid-cols-6">
                <h1 className="font-bold border-b-2 py-2 col-span-5">{form.DealName}</h1>
                <span id="downloadSVGParent" title="download" className="flex justify-end border-b-2">
                    <svg id="downloadSVG" className="cursor-pointer" onClick={handleDownload} xlinkTitle="download" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000"><path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" /></svg>
                </span>
            </div>

            {/* Revenue */}
            <div className="mb-4 grid grid-cols-6">
                <SectionRow text="Revenue" />

                <LabelCell text="House Estimated Sell Price" colspan={5} />
                <DataCell text={Currency(form.HouseEstimatedSellPrice || 0)} />
            </div>

            {/* Costing */}
            <div className="mb-4">
                <SectionRow text="Costing" />
                {/* Acquisition */}
                <div className="grid grid-cols-6">
                    <SectionRow text="Acquisition" />

                    <LabelCell text="Land Purchase" colspan={4} />
                    <DataCell text={Currency(form.LandPurchase || 0)} />

                    <SectionRow text="Construction" />

                    <LabelCell text="Site Improvements" colspan={3} />
                    <DataCell text={Currency(form.SiteImprovements || 0)} />

                    <LabelCell text="Engineering" colspan={3} />
                    <DataCell text={Currency(form.Engineering || 0)} />

                    <LabelCell text="Direct Construction Costs" colspan={3} />
                    <DataCell text={Currency(form.DirectConstruction || 0)} />

                    <LabelCell text="Total Construction Funds" colspan={4} />
                    <DataCell text={calculations.TotalConstructionFunds} />
                </div>

                <div className="mt-4 grid grid-cols-6">
                    <SectionRow text="Soft Costs" />

                    <LabelCell text="Builders Fee %" colspan={3} />
                    <DataCell text={form.BuildersFeePercent || 0} isPercent />

                    <LabelCell text="Builders Fee" colspan={3} />
                    <DataCell text={calculations.BuildersFee} />

                    <LabelCell text="Acquisition & Finance %" colspan={3} />
                    <DataCell text={form.AcquisitionAndFinanceTransactionCostsPercent || 0} isPercent />

                    <LabelCell text="Acquisition & Finance" colspan={3} />
                    <DataCell text={calculations.AcquisitionAndFinance} />

                    <LabelCell text="Months Hold Before Construction" colspan={3} />
                    <DataCell text={form.MonthsHoldBeforeConstruction} />

                    <LabelCell text="Months Construction" colspan={3} />
                    <DataCell text={form.MonthsConstruction} />

                    <LabelCell text="Months Hold Until Closing" colspan={3} />
                    <DataCell text={form.MonthsHoldUntilConstruction} />

                    <LabelCell text="Interest Rate" colspan={3} />
                    <DataCell text={form.CommercialBorrowingInterestRatePercent || 0} isPercent />

                    <LabelCell text="Construction Interest" colspan={3} />
                    <DataCell text={calculations.ConstructionInterest} />

                    <LabelCell text="Listing Commission %" colspan={3} />
                    <DataCell text={form.ListingCommissionPercent || 0} isPercent />

                    <LabelCell text="Listing Commission" colspan={3} />
                    <DataCell text={calculations.ListingCommision} />

                    <LabelCell text="Selling Commission %" colspan={3} />
                    <DataCell text={form.SellingCommisionPercent || 0} isPercent />

                    <LabelCell text="Selling Commission" colspan={3} />
                    <DataCell text={calculations.SellingCommision} />

                    <LabelCell text="Total Soft Costs" colspan={4} />
                    <DataCell text={calculations.TotalSoftCosts} />
                </div>
            </div>
            <div className="mb-4 grid grid-cols-6 border-b-2 py-2">
                <LabelCell text="Total Costing" colspan={5} />
                <DataCell text={calculations.TotalCosting} />

            </div>

            {/* Gross Profit */}
            <div className="mb-4 grid grid-cols-6 font-bold border-b-2 py-2">
                <LabelCell text="Gross Profit" colspan={5} />
                <DataCell text={calculations.GrossProfit} />
            </div>

            {/* Capital Stack */}
            <div className="mb-4 grid grid-cols-6">
                <SectionRow text="Capital Stack" />

                <LabelCell text="Loan Guarantee Fee to Investor %" colspan={3} />
                <DataCell text={form.LoanGuaranteeFeeToInvestorPercent || 0} isPercent />

                <LabelCell text="Loan Guarantee Fee to Investor" colspan={3} />
                <DataCell text={calculations.LoanGuaranteeFee} />

                <LabelCell text="Interest on Investment Above Prime" colspan={3} />
                <DataCell text={calculations.InterestOnInvestmentAbovePrime} isPercent />

                <LabelCell text="Interest on Investment Cash to Investor %" colspan={3} />
                <DataCell text={form.InterestOnInvestmentCashToInvestorPercent || 0} isPercent />

                <LabelCell text="Interest on Investment Cash to Investor" colspan={3} />
                <DataCell text={calculations.InterestOnInvestmentCashToInvestor} />

                <LabelCell text="Financier Equity" colspan={3} />
                <DataCell text={Currency(form.InvestorCash)} />

                <LabelCell text="Builder Cash" colspan={3} />
                <DataCell text={Currency(form.BuilderCash)} />

                <LabelCell text="Total Cash" colspan={4} />
                <DataCell text={calculations.TotalCash} />

                <LabelCell text="Loan Amount" colspan={3} />
                <DataCell text={calculations.LoanAmount} />

                <LabelCell text="Loan to Value %" colspan={3} />
                <DataCell text={calculations.LoanToValuePercent} isPercent />

                <LabelCell text="Builder Equity" colspan={3} />
                <DataCell text={calculations.BuilderEquity} />

                <LabelCell text="Builder Equity %" colspan={3} />
                <DataCell text={calculations.BuilderEquityPercent} isPercent />

                <LabelCell text="Minimum IRR" colspan={3} />
                <DataCell text={form.MinimumIRR || 0} isPercent />

                <LabelCell text="Total Equity" colspan={4} />
                <DataCell text={calculations.TotalEquity} />

                <LabelCell text="Net Income" colspan={4} />
                <DataCell text={calculations.NetIncome} />
            </div>


            {/* Distributions */}
            <div className="mb-4">
                <div className="font-bold grid grid-cols-6 border-b-2 py-2">
                    <h2 className="col-span-4">Distributions</h2>
                    <div className="text-center">Investor</div>
                    <div className="text-center">Builder</div>
                </div>
                <div>
                    <div className="grid grid-cols-6 gap-4 border-b-2 pt-3 pb-2">

                        <LabelCell text="Distribution #1" colspan={4} />
                        <DataCell text={calculations.InvestorDistributionOnePercent} isPercent />
                        <DataCell text={calculations.BuilderDistributionOnePercent} isPercent />

                        <LabelCell text="Distribution Amount" colspan={3} />
                        <DataCell text={calculations.DistributionOne} />
                        <DataCell text={calculations.InvestorDistributionOne} />
                        <DataCell text={calculations.BuilderDistributionOne} />

                        <LabelCell text="New Balance" colspan={3} />
                        <DataCell text={calculations.BalanceAfterDistributionOne} />

                        <LabelCell text="New IRR" colspan={3} />
                        <DataCell text={calculations.IRRAfterDistributionOne} isPercent />
                    </div>

                    <div className="grid grid-cols-6 gap-4 border-b-2 pt-3 pb-2">
                        <LabelCell text="Distribution #2" colspan={4} />
                        <DataCell text={calculations.InvestorDistributionTwoPercent} isPercent />
                        <DataCell text={calculations.BuilderDistributionTwoPercent} isPercent />

                        <LabelCell text="Distribution Amount" colspan={3} />
                        <DataCell text={calculations.DistributionTwo} />
                        <DataCell text={calculations.InvestorDistributionTwo} />
                        <DataCell text={calculations.BuilderDistributionTwo} />

                        <LabelCell text="New Balance" colspan={3} />
                        <DataCell text={calculations.BalanceAfterDistributionTwo} />

                        <LabelCell text="New IRR" colspan={3} />
                        <DataCell text={calculations.IRRAfterDistributionTwo} isPercent />
                    </div>

                    <div className="grid grid-cols-6 gap-4 border-b-2 pt-3 pb-2">
                        <LabelCell text="Distribution #3" colspan={4} />
                        <DataCell text={calculations.InvestorDistributionThreePercent} isPercent />
                        <DataCell text={calculations.BuilderDistributionThreePercent} isPercent />

                        <LabelCell text="Distribution Amount" colspan={3} />
                        <DataCell text={calculations.DistributionThree} />
                        <DataCell text={calculations.InvestorDistributionThree} />
                        <DataCell text={calculations.BuilderDistributionThree} />
                    </div>
                </div>

                <div className="grid grid-cols-6 gap-4">
                    <span className="font-bold col-span-4">Totals</span>
                    <span className="font-bold text-center">Investor</span>
                    <span className="font-bold text-center">Builder</span>

                    <span className="col-span-4"></span>
                    <DataCell text={calculations.InvestorTotal} />
                    <DataCell text={calculations.BuilderTotal} />

                    <LabelCell text="ROE" colspan={4} />
                    <DataCell text={calculations.InvestorROE} isPercent />
                    <DataCell text={calculations.BuilderROE} isPercent />

                    <LabelCell text="Final IRR" colspan={4} />
                    <DataCell text={calculations.InvestorFinalIRR} isPercent />
                </div>
            </div>
        </div >
    )
}

function LabelCell({ text, colspan }: { text: string, colspan: number }) {
    const className = `col-span-${colspan}`
    return (
        <span className={className}>{text}</span>
    )
}

function DataCell({ text, isPercent }: { text: string | number, isPercent?: boolean }) {
    return (
        <span className={"text-right "}>{text}{isPercent ? '%' : ''}</span>
    )
}

function SectionRow({ text }: { text: string }) {
    return (
        <h2 className="font-bold border-b-2 col-span-6 pb-2 pt-3">{text}</h2>
    )
}