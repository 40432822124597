import { useEffect, useState } from "react"
import { UseFormRegisterReturn, UseFormSetValue, UseFormWatch } from "react-hook-form"
import NumberField from "../../components/atoms/forms/Fields/NumberField"

export type UnitsRemainingType = {
    name: string,
    label: string,
    supportingText?: string,
    disabled?: boolean,
    required?: boolean,
    value: string | undefined,
    formRef: UseFormRegisterReturn<string>
    setValue: UseFormSetValue<any>
    watch: UseFormWatch<any>
}

export default function UnitsRemaining(props: UnitsRemainingType) {
    const [supportingText, setSupportingText] = useState(props.supportingText)

    const watchUnitFields = props.watch(['YearOneJanuaryUnitCount', 'YearOneFebruaryUnitCount', 'YearOneMarchUnitCount', 'YearOneAprilUnitCount', 'YearOneMayUnitCount', 'YearOneJuneUnitCount', 'YearOneJulyUnitCount', 'YearOneAugustUnitCount', 'YearOneSeptemberUnitCount', 'YearOneOctoberUnitCount', 'YearOneNovemberUnitCount', 'YearOneDecemberUnitCount', 'YearTwoJanuaryUnitCount', 'YearTwoFebruaryUnitCount', 'YearTwoMarchUnitCount', 'YearTwoAprilUnitCount', 'YearTwoMayUnitCount', 'YearTwoJuneUnitCount', 'YearTwoJulyUnitCount', 'YearTwoAugustUnitCount', 'YearTwoSeptemberUnitCount', 'YearTwoOctoberUnitCount', 'YearTwoNovemberUnitCount', 'YearTwoDecemberUnitCount'])
    const watchOriginal = props.watch('UnitsRemainingOriginal')

    useEffect(()=> {
        if(!props.disabled){
            let unitsRemaining = Number(watchOriginal) || 0
            watchUnitFields.forEach(tempField => {
                const fieldValue = Number(tempField) || 0
                unitsRemaining = unitsRemaining - fieldValue
            })
            props.setValue("UnitsRemaining", unitsRemaining)
        }
    }, [watchUnitFields, watchOriginal])

    return (
        <NumberField
            {...props}
            readonly={true}
            supportingText={supportingText}
        />
    )
}
