import { useState, useEffect, useMemo } from 'react'
import APIRequest from "../../../helpers/CreateRequest"
import FormatDate from "../../../helpers/FormatDate";
import Button from '../../../components/atoms/Button';
import { Currency } from '../../../helpers/InputValueConverter';

type house = { LotState: string, CommunityNameCode: string, HouseCode: string, Revenue: number }

type tableData = {
    DevelopedForSale: house[],
    SoldAndStarted: house[],
    UnderContract: house[],
    SoldButNotStarted: house[],
    Spec: house[]
}

export default function InventoryReport({ScenarioID, triggerNewData}: {ScenarioID: string, triggerNewData: boolean}) {
    const [inventory, setInventory] = useState<house[]>()
    const [tableData, setTableData] = useState<tableData>()
    const [date, setDate] = useState(FormatDate(new Date().toLocaleDateString(), true))

    const minDate = useMemo(() => new Date(new Date().setFullYear(new Date().getFullYear() - 5)), [])
    const maxDate = useMemo(() => new Date(new Date().setFullYear(new Date().getFullYear() + 10)), [])
    const maxRange = useMemo(() => {
        const milliseconds = maxDate.valueOf() - minDate.valueOf()
        const days = Math.abs(milliseconds / 86400000) //86400000 milliseconds in a day
        return days
    }, [])

    function DateToRange(date: Date) {
        return Math.round((date.getTime() - minDate.getTime()) / (maxDate.getTime() - minDate.getTime()) * maxRange)
    }

    function RangeToDate(range: number) {
        const time = minDate.getTime() + (maxDate.getTime() - minDate.getTime()) * (range / maxRange)
        return FormatDate(new Date(time).toLocaleDateString(), true)
    }

    const handleDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDate(e.target.value)
    }

    const handleSlider = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        try {
            const date = RangeToDate(Number(e.currentTarget.value))
            setDate(date)
        } catch (err) {
            console.log(err)
        }
    }

    const getInventory = async () => {
        if (ScenarioID && date) {
            try {
                const formattedDate = FormatDate(date, false)
                const result = await new APIRequest('/scenariosSalesForecastEvents/inventory', "POST", null, { scenarioID: ScenarioID, date: formattedDate }).GenerateRequest()

                if (result.status === 200) {
                    const body = await result.json()
                    if (body.status === 200) {
                        console.log(body.recordset)
                        setInventory(body.recordset || [])
                    } else throw body.message
                } else throw result.statusText

            } catch (err) {
                console.log(err)
            }
        }
    }

    // get inventory from DB - set inventory if success
    useEffect(() => {
        getInventory()
    }, [date, ScenarioID, triggerNewData])

    // categorize the inventory by lot state
    useEffect(() => {
        if (inventory) {
            const DevelopedForSale = []
            const SoldAndStarted = []
            const UnderContract = []
            const SoldButNotStarted = []
            const Spec = []

            for (let i = 0; i < inventory.length; i++) {
                switch (inventory[i].LotState) {
                    case "Developed - For Sale":
                        DevelopedForSale.push(inventory[i])
                        break
                    case "Sold and Started":
                        SoldAndStarted.push(inventory[i])
                        break
                    case "Under Contract":
                        UnderContract.push(inventory[i])
                        break
                    case "Sold but Not Started":
                        SoldButNotStarted.push(inventory[i])
                        break
                    case "Spec":
                        Spec.push(inventory[i])
                        break
                }

            }

            setTableData({
                DevelopedForSale: DevelopedForSale,
                SoldAndStarted: SoldAndStarted,
                UnderContract: UnderContract,
                SoldButNotStarted: SoldButNotStarted,
                Spec: Spec
            })

        }
    }, [inventory])

    //change range when date changes
    useEffect(() => {
        try {
            const range = DateToRange(new Date(date))
            const rangeElement = document.getElementById('inventoryReportRange') as HTMLInputElement
            rangeElement.value = String(range)
        } catch (err) {
            console.log(err)
        }
    }, [date])

    return (
        <div className="flex w-full flex-col h-full gap-y-4 max-w-[1600px] mx-auto">
            {/* header */}
            <div className="grid grid-cols-12 w-full gap-4 h-[80px] items-center bep-shadow-xl rounded-lg p-4">
                <span className="col-span-2 headline-large">Inventory</span>

                <input className='m-auto rounded-sm px-2 py-1 col-span-2 flex justify-center bg-md-surface-variant' type="date" min={FormatDate(minDate.toLocaleDateString(), true)} max={FormatDate(maxDate.toLocaleDateString(), true)} onChange={handleDate} value={date} />
                <span className='flex justify-end col-span-5 gap-x-2'>
                    <Button label="Today's Date" styleType='text' type='button' action={() => setDate(FormatDate(new Date().toLocaleDateString(), true))} />
                    <input className="w-full col-span-4 fill-red-400 accent-[--md-ref-palette-primary50]" type="range" min={0} max={maxRange} defaultValue={DateToRange(new Date(date))} onMouseUp={handleSlider} id='inventoryReportRange' />
                </span>
            </div>

            {/* body */}
            <div className="flex flex-grow flex-col">

                <div className=' grid grid-cols-3 gap-y-4 gap-x-4 w-full '>
                    <div className='flex flex-col bep-shadow-xl p-4 rounded-lg gap-y-3'>
                        <span className="pb-4 border-b-2 text-center">Lot Supply</span>
                        <InventoryTables name="Developed - For Sale" houses={tableData?.DevelopedForSale} />
                    </div>

                    <div className='flex flex-col bep-shadow-xl p-4 rounded-lg gap-y-3'>
                        <span className="pb-4 border-b-2 text-center">Backlog</span>
                        <InventoryTables name="Sold and Started" houses={tableData?.SoldAndStarted} />
                        <InventoryTables name="Sold but Not Started" houses={tableData?.SoldButNotStarted} />
                    </div>

                    <div className='flex flex-col bep-shadow-xl p-4 rounded-lg gap-y-3'>
                        <span className="pb-4 border-b-2 text-center">Under Construction</span>
                        <InventoryTables name="Under Contract" houses={tableData?.UnderContract} />
                        <InventoryTables name="Spec" houses={tableData?.Spec} />
                    </div>
                </div>

            </div>
        </div>
    )
}




type communities = { nameCode: string, revenue: number, houses: house[] }[]

function InventoryTables({ name, houses }: { name: string, houses?: house[] }) {
    const [communities, setCommunities] = useState<communities>()
    const [openCommunity, setOpenCommunity] = useState<string>()
    const [totalRevenue, setTotalRevenue] = useState<number>()


    const handleClick = (nameCode: string) => {
        if (openCommunity === nameCode) setOpenCommunity(undefined)
        else setOpenCommunity(nameCode)
    }

    useEffect(() => {
        if (houses) {

            const communitiesTemp: communities = []
            let totalRevenueTemp = 0;
            for (let i = 0; i < houses.length; i++) {
                let communityFound = false
                for (let j = 0; j < communitiesTemp.length; j++) {
                    if (houses[i].CommunityNameCode === communitiesTemp[j].nameCode) {
                        communitiesTemp[j].houses.push(houses[i])
                        communitiesTemp[j].revenue = communitiesTemp[j].revenue + houses[i].Revenue
                        communityFound = true
                        break;
                    }
                }

                if (!communityFound) communitiesTemp.push({ nameCode: houses[i].CommunityNameCode, revenue: houses[i].Revenue, houses: [houses[i]] })
                totalRevenueTemp = totalRevenueTemp + houses[i].Revenue
            }
            setTotalRevenue(totalRevenueTemp)
            setCommunities(communitiesTemp)
        }
    }, [houses])

    return (
        <div className=" bep-shadow-xl   rounded-md max-h-[224px] body-small py-2 px-4 ">


            {/* table */}
            <div className="w-full h-full overflow-auto">
                {/* headers */}
                <div className="grid grid-cols-12 h-min w-full border-b body-medium sticky top-0 bg-md-background z-10">
                    <div className="body-large col-span-12">{name}</div>

                    <span></span>
                    <span className="col-span-6">Community</span>
                    <span className="col-span-3 text-end pr-1">Revenue</span>
                    <span className="col-span-2 text-end pr-1">Units</span>
                </div>

                {/* body */}
                <div className="w-full ">
                    {
                        communities && houses ?


                            <div>
                                {
                                    communities.map((community, index) => (
                                        <div key={`inventory${name}${community.nameCode}`}>
                                            <div className={`w-full grid grid-cols-12 cursor-pointer hover:bg-[--md-ref-palette-primary90] ${index % 2 === 0 ? '' : 'bg-[--md-ref-palette-primary95]'} `} onClick={() => handleClick(community.nameCode)}>
                                                <span>
                                                    <svg className={`flex m-auto transition-transform ${openCommunity === community.nameCode ? 'rotate-180' : ''} `} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" /></svg>
                                                </span>
                                                <span className='my-auto col-span-6'>{community.nameCode}</span>
                                                <span className='my-auto col-span-3 text-end '>{Currency(String(community.revenue))}</span>
                                                <span className='my-auto col-span-2 text-end pr-1'>{community.houses.length}</span>
                                            </div>

                                            <div className={`grid transition-all ${openCommunity === community.nameCode ? `grid-rows-[1fr]` : 'grid-rows-[0fr]'}`}>
                                                <div className='overflow-hidden '>
                                                    {
                                                        community.houses.map((house, index) => (
                                                            <div className='grid grid-cols-12 even:bg-[--md-ref-palette-primary98] ' key={`inventory${name}${community.nameCode}${house.HouseCode}`}>
                                                                <span></span>
                                                                <span className='col-span-6'>
                                                                    {house.HouseCode}
                                                                </span>
                                                                <span className='col-span-3 text-right'>
                                                                    {Currency(String(house.Revenue))}
                                                                </span>
                                                                <span className='col-span-2'></span>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>


                                        </div>
                                    ))
                                }
                            </div>


                            :

                            <div className='flex text-center p-4'>
                                <div className='m-auto label-large'>
                                    Please select a scenario
                                </div>
                            </div>
                    }
                </div>

                {/* footer (total) */}
                <div className='w-full grid grid-cols-12 sticky bottom-0 bg-md-background'>
                    <span></span>
                    <span className='my-auto col-span-7 font-bold'>Total</span>
                    <span className='my-auto col-span-2 font-bold text-end'>{Currency(String(totalRevenue))}</span>
                    <span className='my-auto col-span-2 text-end pr-1 font-bold'>{houses?.length || 0}</span>
                </div>

            </div>
        </div>
    )
}