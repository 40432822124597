import { useEffect, useState } from "react";
import CRUD from "../../templates/CRUD";
import { FieldValues } from "react-hook-form";
import GetObj from "../../helpers/GetObj";
import { field, mode } from "../../Types/FormTypes";
import ComplexSelect from "../../components/molecules/ComplexSelect";
import APIRequest from "../../helpers/CreateRequest";
import Button from "../../components/atoms/Button";
import { Link, useLocation } from "react-router-dom";

const name = {
    normal: 'Business Plan',
    plural: 'Business Plans',
    alias: 'Busines Plan',
    aliasPlural: 'Business Plans',
    data: 'BusinessPlan',
    dataPlural: 'BusinessPlans',
    sectionTitle: 'Forecasting'
}



const fields: field[] = [
    { name: 'BusinessPlanName', alias: 'Name', sendAs: 'Name', type: 'text', required: true, size: 'md' },
    { name: 'Description', type: 'text', size: 'md', },
    { name: 'Code', type: 'text', required: false, size: 'md' },
    { name: 'BusinessUnitID', type: 'select', SelectElement: { name: 'Business Unit', namePlural: 'Business Units' }, required: true, size: 'md', alias: 'Business unit' },
    { name: 'DivisionID', type: 'select', SelectElement: { name: 'Division', namePlural: 'Divisions' }, required: true, size: 'md', alias: 'Division' },
]

const POWERBIREPORT = process.env.REACT_APP_ENV === 'PROD' ?
    'https://app.powerbi.com/reportEmbed?reportId=39808a59-0f55-40a5-b618-ddf20765c3f5&autoAuth=true&ctid=b886e5e8-64d0-4f1a-8c56-dee8e3c912a7'
    :
    process.env.REACT_APP_ENV === 'UAT' ?
        'https://app.powerbi.com/reportEmbed?reportId=6f288bbf-c801-444f-b87b-2b28aa4b7548&autoAuth=true&ctid=b886e5e8-64d0-4f1a-8c56-dee8e3c912a7'
        :
        'https://app.powerbi.com/reportEmbed?reportId=556e5223-79fb-4146-9927-bf1e172a8c1b&autoAuth=true&ctid=b886e5e8-64d0-4f1a-8c56-dee8e3c912a7'


export default function BusinessPlan2() {
    const location = useLocation()
    const stateID = location.state?.id || undefined
    const [obj, setObj] = useState<any>()
    const [objID, setObjID] = useState(stateID)
    const [mode, setMode] = useState<mode>('view')
    const [refresh, setRefresh] = useState(false)

    const emptySet: FieldValues = Object.fromEntries(fields.map(field => [field.sendAs || field.name, null]));
    function GenerateEventsButton({ objID }: { objID: any }) {
        async function HandleSubmit() {

            const result = await (new APIRequest('/businessPlans/generateEvents', 'POST', null, { BusinessPlanID: objID })).GenerateRequest()

            if (result.status === 200) {
                console.log('Business Plan event generated')
            } else {
                console.log(`Failed to generate events. Error number: ${result.status}`)
            }
        }

        return (
            <Button
                label="Generate"
                styleType="tonal"
                type="button"
                action={objID ? HandleSubmit : () => null}
                disabled={!objID}
            />
        )

    }

    function ViewReport() {


        return (
            <Button
                label={
                    <Link to={POWERBIREPORT} target="_blank" rel="noopener noreferrer">View Report</Link>
                }
                styleType="outlined"
                type="button"
                action={() => null}
                disabled={false}
            />
        )
    }

    //Get object when the id changes or a refresh is called 
    useEffect(() => {
        const getObj = async () => {
            if (objID) {
                const obj = await GetObj(objID, name.dataPlural, fields)
                if (obj !== -1) {
                    setObj(obj)
                    setMode('view')
                }
            }
        }
        getObj()
    }, [objID, refresh])


    return (
        <>
            <div className="py-10 px-10 h-full overflow-y-auto">

                <CRUD
                    name={name}
                    fields={fields}
                    obj={obj}
                    id={objID}
                    setID={setObjID}
                    mode={mode}
                    setMode={setMode}
                    emptySet={emptySet}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    extraButtons={[
                        <GenerateEventsButton key='BusinessPlansGenerateEventsButton' objID={objID} />,
                        <ViewReport />
                    ]}
                />


                {/* Details or sections */}
                <div className="flex-grow w-full m-auto">
                    <ComplexSelect name="BusinessPlanConcept" namePlural="BusinessPlanConcepts" alias="Concept" aliasPlural="Concepts" objID={objID} />
                </div>
            </div>
        </>
    )
}