export type field2 = {
  name: string;
  sendAs?: string;
  type: "text" | "currency" | "number" | "percent" | "date" | "checkbox";
};

export const SalesForecastModel: field2[] = [
  { name: "SalesForecastName", sendAs: "Name", type: "text" },
  { name: "Description", type: "text" },
  { name: "Code", type: "text" },
  { name: "CommunityID", type: "number" },
  { name: "SalesForecastTypeID", type: "text" },

  { name: "UnitTypeID", type: "number" },
  { name: "SaleTypeID", type: "number" },
  { name: "BuildingUnitCountAverage", type: "number" },
  { name: "BuildingUnitCountMax", type: "number" },
  { name: "BuildingFractionSoldToStart", type: "percent" },
  { name: "UnsoldUnitCountMax", type: "number" },
  { name: "ModelElevationID", type: "number" },
  { name: "SquareFootageFinancial", type: "number" },

  { name: "LotPremium", type: "currency" },
  { name: "BasePrice", type: "currency" },
  { name: "OptionSalesToBasePriceRatio", type: "percent" },
  { name: "Incentives", type: "currency" },
  { name: "IncentivesDescription", type: "text" },
  { name: "SalePriceTotal", type: "currency" },

  { name: "LotAcquisitionCost", type: "currency" },
  { name: "LotAcquisitionTransactionCost", type: "currency" },
  { name: "TransactionCostToLotAcquisitionCostRatio", type: "percent" },
  { name: "OtherLotItemCost", type: "currency" },
  { name: "OtherLotItemDescription", type: "text" },

  { name: "SiteWorkItemOneCost", type: "currency" },
  { name: "SiteWorkItemOneDescription", type: "text" },
  { name: "SiteWorkItemTwoCost", type: "currency" },
  { name: "SiteWorkItemTwoDescription", type: "text" },

  { name: "BaseDirectCost", type: "currency" },
  { name: "IncludedOptionCosts", type: "currency" },
  { name: "OptionSalesMarginFraction", type: "percent" },
  { name: "OtherDirectOneCost", type: "currency" },
  { name: "OtherDirectOneDescription", type: "text" },
  { name: "OtherDirectTwoCost", type: "currency" },
  { name: "OtherDirectTwoDescription", type: "text" },

  { name: "ListingCommissionCostToBasePriceRatio", type: "percent" },
  { name: "ListingCommissionCost", type: "currency" },
  { name: "TransferStampCostToSalePriceRatio", type: "percent" },
  { name: "TransferStampCost", type: "currency" },
  { name: "SellerAgentSalesFraction", type: "percent" },
  { name: "FinanceCostToLotSiteDirectCostsSumRatio", type: "percent" },
  { name: "FinanceCost", type: "currency" },
  { name: "InternalCommissionCost", type: "currency" },
  { name: "SellingCommissionCostToSalePriceRatio", type: "percent" },
  { name: "SellingCommissionCost", type: "currency" },
  { name: "DispositionTransactionCostToSalePriceRatio", type: "percent" },
  { name: "IndirectOtherCostToSalePriceRatio", type: "percent" },
  { name: "IndirectOtherCosts", type: "currency" },
  { name: "IndirectOtherCostDescription", type: "text" },
  { name: "IndirectCostsTotal", type: "currency" },

  { name: "CostsTotal", type: "currency" },

  { name: "NextSaleDate", type: "date" },
  { name: "UnitsSoldPerMonth", type: "number" },

  { name: "SaleStartIntervalDays", type: "number" },
  { name: "StartSettlementIntervalDays", type: "number" },
  { name: "StartIntervalDays", type: "number" },
  { name: "SettlementIntervalDays", type: "number" },

  { name: "FinanceTypeID", type: "number" },
  { name: "DepositFraction", type: "percent" },
];

export const SalesForecastDefaultValues: SalesForecast = {
  // General
  SalesForecastTypeID: "1",
  Name: "",
  Description: "",
  Code: "",
  CommunityID: "",
  LastRefreshTimeStamp: "",

  // Product Offering
  UnitTypeID: "",
  SaleTypeID: "",
  BuildingUnitCountAverage: "",
  BuildingUnitCountMax: "",
  BuildingFractionSoldToStart: "",
  UnsoldUnitCountMax: "",
  ModelElevationCommunityID: "",
  ModelElevationID: "",
  SquareFootageFinancial: "",

  // Pricing
  LotPremium: "",
  BasePrice: "",
  OptionSalesToBasePriceRatio: "",
  Incentives: "",
  IncentivesDescription: "",
  SalePriceTotal: "",
  
  // Costs
  LotAcquisitionCost: "",
  TransactionCostToLotAcquisitionCostRatio: "",
  LotAcquisitionTransactionCost: "",
  OtherLotItemCost: "",
  OtherLotItemDescription: "",
  SiteWorkItemOneCost: "",
  SiteWorkItemOneDescription: "",
  SiteWorkItemTwoCost: "",
  SiteWorkItemTwoDescription: "",
  BaseDirectCost: "",
  IncludedOptionCosts: "",
  OptionSalesMarginFraction: "",
  OtherDirectOneCost: "",
  OtherDirectOneDescription: "",
  OtherDirectTwoCost: "",
  OtherDirectTwoDescription: "",
  ListingCommissionCostToBasePriceRatio: "",
  ListingCommissionCost: "",
  TransferStampCostToSalePriceRatio: "",
  TransferStampCost: "",
  SellerAgentSalesFraction: "",
  FinanceCostToLotSiteDirectCostsSumRatio: "",
  FinanceCost: "",
  InternalCommissionCost: "",
  SellingCommissionCostToSalePriceRatio: "",
  SellingCommissionCost: "",
  DispositionTransactionCostToSalePriceRatio: "",
  IndirectOtherCostToSalePriceRatio: "",
  IndirectOtherCosts: "",
  IndirectOtherDescription: "",
  IndirectCostsTotal: "",
  CostsTotal: "",

  // Absoprtion
  NextSaleDate: "",
  UnitsSoldPerMonth: "",
  UnitsRemaining: "",

  // Timing
  SaleStartIntervalDays: "",
  StartSettlementIntervalDays: "",
  StartIntervalDays: "",
  SettlementIntervalDays: "",
  FinanceTypeID: "",
  DepositFraction: "",


  // EXTRAS
  Override: 'False',
};

export type SalesForecast = {
  Name: string;
  Description: string;
  Code: string;
  CommunityID: string;
  SalesForecastTypeID: string;
  LastRefreshTimeStamp: string;
  UnitTypeID: string;
  SaleTypeID: string;
  ModelElevationCommunityID: string;
  BuildingUnitCountAverage: string;
  BuildingUnitCountMax: string;
  BuildingFractionSoldToStart: string;
  UnsoldUnitCountMax: string;
  ModelElevationID: string;
  SquareFootageFinancial: string;
  LotPremium: string;
  BasePrice: string;
  OptionSalesToBasePriceRatio: string;
  Incentives: string;
  IncentivesDescription: string;
  SalePriceTotal: string;
  LotAcquisitionCost: string;
  LotAcquisitionTransactionCost: string;
  TransactionCostToLotAcquisitionCostRatio: string;
  OtherLotItemCost: string;
  OtherLotItemDescription: string;
  SiteWorkItemOneCost: string;
  SiteWorkItemOneDescription: string;
  SiteWorkItemTwoCost: string;
  SiteWorkItemTwoDescription: string;
  BaseDirectCost: string;
  IncludedOptionCosts: string;
  OptionSalesMarginFraction: string;
  OtherDirectOneCost: string;
  OtherDirectOneDescription: string;
  OtherDirectTwoCost: string;
  OtherDirectTwoDescription: string;
  ListingCommissionCostToBasePriceRatio: string;
  ListingCommissionCost: string;
  TransferStampCostToSalePriceRatio: string;
  TransferStampCost: string;
  SellerAgentSalesFraction: string;
  FinanceCostToLotSiteDirectCostsSumRatio: string;
  FinanceCost: string;
  InternalCommissionCost: string;
  SellingCommissionCostToSalePriceRatio: string;
  SellingCommissionCost: string;
  DispositionTransactionCostToSalePriceRatio: string;
  IndirectOtherCostToSalePriceRatio: string;
  IndirectOtherCosts: string;
  IndirectOtherDescription: string;
  IndirectCostsTotal: string;
  CostsTotal: string;
  UnitsRemaining: string;
  NextSaleDate: string;
  UnitsSoldPerMonth: string;
  SaleStartIntervalDays: string;
  StartSettlementIntervalDays: string;
  StartIntervalDays: string;
  SettlementIntervalDays: string;
  FinanceTypeID: string;
  DepositFraction: string;
  Override: string;
};
