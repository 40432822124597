import { Cookies } from "react-cookie";

export function Currency(input: string | number, parenthesisWrap: boolean = false ) {
  let newInput = input?.toString() || "";
  try {
    if (newInput === "") {
    } else if (newInput === "$") {
      newInput = "";
    } else {
      newInput = newInput.replaceAll("$", "");
      newInput = newInput.replaceAll(",", "");
      newInput = newInput === "NaN" ? "" : newInput;
      newInput = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(Number(newInput));
      newInput = newInput === "$NaN" ? "" : newInput;

      const hideDecimal = new Cookies().get("settings")?.CurrencyDecimalHidden;
      if (hideDecimal) {
        newInput = newInput.split(".")[0];
      }

      if (parenthesisWrap) {
        const isNegative = newInput.includes("-");
        if (isNegative) {
          newInput = newInput.replaceAll("-", "");
          newInput = `(${newInput})`;  
        }
      }
      
    }
  } catch (err) {
    // console.log(err);
  }
  return newInput;
}

export function CurrencyToNumber(input?: string) {
  try {
    if (!input) throw "No input";
    let inputString = String(input);
    inputString = inputString.replaceAll("$", "");
    inputString = inputString.replaceAll(",", "");
    inputString = inputString === "NaN" ? "" : inputString;

    const hideDecimal = new Cookies().get("settings")?.CurrencyDecimalHidden;
    if (hideDecimal) {
      inputString = inputString.split(".")[0];
    }

    let newInput = Number(inputString);
    return newInput;
  } catch (err) {
    // console.log(err);
    return false;
  }
}
