import { useEffect, useState } from "react";
import NewForm from "../components/organisms/NewForm";
import APIRequest from "../helpers/CreateRequest";
import { field, mode } from "../Types/FormTypes";
import { FieldValues } from 'react-hook-form'
import Search from "../components/molecules/Search";
import SnackBar from "../components/molecules/SnackBar";


export default function CRUD({
    name,
    obj,
    id,
    setID,
    fields,
    mode,
    setMode,
    emptySet,
    refresh,
    setRefresh,
    extraButtons,
    parentState,
    setParentState,
    disableEdit
}: {
    name: { normal: string, plural: string, alias: string, aliasPlural: string, data: string, dataPlural: string, sectionTitle?: string }
    obj: any,
    id: number | undefined
    setID: Function
    fields: field[]
    mode: mode,
    setMode: Function,
    emptySet: FieldValues,
    refresh: boolean,
    setRefresh: Function
    extraButtons?: JSX.Element[]
    parentState?: any
    setParentState?: Function,
    disableEdit?: boolean
}

) {
    const [options, setOptions] = useState<{ name: string, value: number }[]>()
    const [snackbarMessage, setSnackbarMessage] = useState<{ message: string, refresh: boolean }>({ message: '', refresh: false })

    //Get the list of options for the search bar
    useEffect(() => {
        const getOptions = async () => {
            try {
                const result = await ((await new APIRequest(`/${name.dataPlural}/names`, "GET", null, null).GenerateRequest()).json())
                const recordset: any[] = result.recordset
                const newOptions = recordset.map(record => ({ name: String(record[`${name.data}Name`] || record[`${name.data}NameCode`] || record[`${name.data}FullName`]), value: Number(record[`${name.data}ID`]) }))

                setOptions(newOptions)
            } catch (err) {
                console.error(err)
                setOptions([])
            }

        }
        getOptions()
    }, [refresh, name])


    return (
        <>
            <div className=" flex flex-col w-full px-10">

                {
                    <>
                        {/* Menu Section Title */}
                        <div className="font-bold text-xl mb-[10px]">
                            <span>{name.sectionTitle}</span>
                            <span> {">"} </span>
                            <span>{name.aliasPlural}</span>
                        </div>

                        {/* Search */}
                        <div className="flex h-20 items-center mb-[10px]">
                            <Search options={options} setID={setID} />
                        </div>

                        {/* Form */}
                        <div className="w-full mb-[10px]">
                            <NewForm
                                name={name}
                                mode={mode}
                                setMode={setMode}
                                obj={obj}
                                setID={setID}
                                id={id}
                                fields={fields}
                                emptySet={emptySet}
                                refresh={refresh}
                                setRefresh={setRefresh}
                                extraButtons={extraButtons}
                                snackbarRefresh={snackbarMessage.refresh}
                                setSnackbar={setSnackbarMessage}
                                parentState={parentState}
                                setParentState={setParentState}
                                disableEdit={disableEdit}
                            />
                        </div>

                        <SnackBar message={snackbarMessage} />
                    </>

                }
            </div >

        </>
    )
}