import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import APIRequest from "../helpers/CreateRequest";



export default function HelpOffcanvas({ isOpen, setIsOpen }: { isOpen: boolean, setIsOpen: React.Dispatch<React.SetStateAction<boolean>> }) {
    const [isLoading, setIsLoading] = useState(true)
    const [content, setContent] = useState<string>()
    const location = useLocation().pathname.split('/app')[1]

    const getContent = async () => {
        try {
            setIsLoading(true)
            if (isOpen) {
                const result = await new APIRequest('/help', 'POST', null, { location }).GenerateRequest()
                if (result.status === 200) {
                    const body = await result.json()
                    if (body.status === 200) {
                        setContent(body.recordset[0].Content)
                    } else throw body.message
                } else throw result.statusText
            }
        } catch (err) {
            console.log(err)
            setContent(`Error - Server error or no help content for this page was found`)
        }
        setIsLoading(false)
    }

    useEffect(() => { getContent() }, [isOpen, location])

    return (
        <div className={`${isOpen ? 'px-5' : 'w-[0px] px-0 text-transparent '} bg-md-surface shadow-[0px_2px_6px_-3px]  h-full overflow-y-auto  py-3 `}>
            {!isLoading && content ?
                <>
                    <div className="headline-small grid grid-cols-12 h-[64px]">
                        <span className="col-span-10 font-bold items-center flex" >BuildExecPro Help</span>

                        <span className="cursor-pointer col-span-2 m-auto" onClick={() => setIsOpen(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg>
                        </span>
                    </div>
                    {/* Help content from database */}
                    <div id="HelpContent" dangerouslySetInnerHTML={{ __html: content }}></div>
                </>

                :

                <div className="h-full flex items-center justify-center gap-4 ">
                    <svg className={`animate-bounce w-5 h-5  rounded-full bg-md-tertiary`} style={{ animationDelay: '0ms' }} viewBox="0 0 24 24" />
                    <svg className={`animate-bounce w-5 h-5  rounded-full bg-md-tertiary`} style={{ animationDelay: '100ms' }} viewBox="0 0 24 24" />
                    <svg className={`animate-bounce w-5 h-5  rounded-full bg-md-tertiary`} style={{ animationDelay: '200ms' }} viewBox="0 0 24 24" />
                </div>
            }
        </div >
    )
}





