import { UseFormGetValues, UseFormRegisterReturn, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { field } from "../../../Types/FormTypes";
import { useEffect, useState } from "react";

import CheckBoxField from "./Fields/CheckboxField";
import CurrencyField from "./Fields/CurrencyField";
import DateField from "./Fields/DateField";
import NumberField from "./Fields/NumberField";
import SelectField from "./Fields/SelectField";
import TextField from "./Fields/TextField";
import PercentField from "./Fields/PercentField";

import UnitsRemaining from "../../../pages/SalesPlans/UnitsRemaining";
import CommunityID from "../../../pages/SalesPlans/CommunityID";
import EstimatedUnitsSold from "../../../pages/SalesPlans/EstimatedUnitsSold ";
import Year from "../../../pages/SalesPlans/Year";
import GrossProfit from "../../../pages/SalesPlans/GrossProfit";
import GrossMargin from "../../../pages/SalesPlans/GrossMargin";
import UnitTotal from "../../../pages/Communities/UnitTotal";
import SaletoStart from "../../../pages/SalesPlans/SaletoStart";
import LastRefresh from "../../../pages/SalesPlans/LastRefresh";

export type FieldType = {
    field: field
    value: string | number | boolean | undefined
    formRef: UseFormRegisterReturn<string>
    setValue: UseFormSetValue<any>
    disabled?: boolean
    parentState?: any
    setParentState?: Function
    getValues?: UseFormGetValues<any>
    watch?: UseFormWatch<any>
}


export default function Field(props: FieldType) {
    const [standardProps, setStandardProps] = useState({
        name: props.field.sendAs || props.field.name,
        label: props.field.alias || props.field.name,
        type: props.field.type,
        supportingText: props.field.supportingText,
        disabled: props.disabled,
        required: props.field.required,
        formRef: props.formRef,
        setValue: props.setValue,
        hidden: props.field.hidden,
        readonly: props.field.readonly
    })

    useEffect(() => {
        setStandardProps({
            name: props.field.sendAs || props.field.name,
            label: props.field.alias || props.field.name,
            type: props.field.type,
            supportingText: props.field.supportingText,
            disabled: props.disabled,
            required: props.field.required,
            formRef: props.formRef,
            setValue: props.setValue,
            hidden: props.field.hidden,
            readonly: props.field.readonly
        })
    }, [props])

    if (props.field.SpecialField) {

        //turn this into a call to a map whic hreturn the component based on the name
        switch (props.field.SpecialField) {
            case 'SalesPlansUnitsRemaining':
                return (
                    props.watch ? <UnitsRemaining {...standardProps}
                        value={props.value !== undefined && props.value !== null ? props.value.toString() : undefined}
                        watch={props.watch}
                    /> : <></>
                )
            case 'SalesPlanCommunityID': return (
                <CommunityID {...standardProps}
                    fieldNames={props.field.SelectElement || { name: '', namePlural: '' }}
                    staticOptions={props.field.SelectElement?.staticOptions}
                    value={props.value !== undefined && props.value !== null ? props.value.toString() : undefined}
                    watch={props.watch}
                />
            )
            case 'SalesPlansEstimatedUnitsSold': return (
                props.watch ? <EstimatedUnitsSold {...standardProps}
                    value={props.value !== undefined && props.value !== null ? props.value.toString() : undefined}
                    parentState={props.parentState}
                    setParentState={props.setParentState}
                    getValues={props.getValues}
                    watch={props.watch}
                /> : <></>
            )
            case 'SalesPlansYear': return (
                props.watch ? <Year {...standardProps}
                    value={props.value !== undefined && props.value !== null ? props.value.toString() : undefined}
                    watch={props.watch}
                /> : <></>
            )
            case 'SalesPlansGrossProfit': return (
                props.watch ? <GrossProfit
                    {...standardProps}
                    value={props.value !== undefined && props.value !== null ? props.value.toString() : undefined}
                    watch={props.watch}
                /> : <></>
            )

            case 'SalesPlansGrossMargin': return (
                props.watch ? <GrossMargin
                    {...standardProps}
                    value={props.value !== undefined && props.value !== null ? props.value.toString() : undefined}
                    watch={props.watch}
                /> : <></>
            )
            case 'SalesPlanSaletoStart': return (
                props.watch ? <SaletoStart
                    {...standardProps}
                    value={props.value !== undefined && props.value !== null ? String(props.value) : undefined}
                    watch={props.watch}
                /> : <></>
            )
            case 'SalesPlanStarttoSettlement': return (
                props.watch ? <SaletoStart
                    {...standardProps}
                    value={props.value !== undefined && props.value !== null ? String(props.value) : undefined}
                    watch={props.watch}
                /> : <></>
            )
            case 'SalesPlanLastRefresh': return (
                <LastRefresh
                    {...standardProps}
                    value={props.value !== undefined && props.value !== null ? String(props.value) : undefined}
                />
            )
            case 'CommunityUnitTotal': return (
                props.watch ? <UnitTotal
                    {...standardProps}
                    value={props.value !== undefined && props.value !== null ? props.value.toString() : undefined}
                    watch={props.watch}
                />
                    : <></>
            )
            default: return <></>
        }
    }

    switch (props.field.type) {
        case 'text': return (
            <TextField {...standardProps} value={props.value !== undefined && props.value !== null ? props.value.toString() : undefined} />
        )
        case 'number':
        case 'int':
        case 'float': return (
            <NumberField {...standardProps} value={props.value !== undefined && props.value !== null ? props.value.toString() : undefined} />
        )
        case 'currency': return (
            <CurrencyField {...standardProps} value={props.value !== undefined && props.value !== null ? props.value.toString() : undefined} />
        )
        case 'select': return (
            <SelectField
                {...standardProps}
                fieldNames={props.field.SelectElement || { name: '', namePlural: '' }}
                staticOptions={props.field.SelectElement?.staticOptions}
                value={props.value !== undefined && props.value !== null ? props.value.toString() : undefined}
            />
        )
        case 'date': return (
            <DateField {...standardProps} value={props.value !== undefined && props.value !== null ? props.value.toString() : undefined} />
        )
        case 'checkbox': return (
            <CheckBoxField {...standardProps} defaultChecked={props.value ? true : false} value={props.value?.toString()} />
        )
        case 'percent': return (
            <PercentField {...standardProps} value={props.value !== undefined && props.value !== null ? props.value.toString() : undefined} />
        )
        default: return <></>
    }


}