import { useEffect, useRef, useState } from "react"
import ellipsis from '../../assets/ellipsis.svg'

export default function DropDown(props: { BindingElement?: React.MutableRefObject<HTMLElement> , options: { name: string, action: () => void }[] }) {
    const [open, setOpen] = useState(false)

    const dropdownOptionsRef = useRef<HTMLDivElement | null>(null);
    const dropdownButton = useRef<HTMLDivElement | null>(null);


    function DropdownOptions() {
        useEffect(() => {
            //If the clicked element is either inside the buttons parent div or the dropdown box itself, then close the dropdown
            const handleClick = (event: MouseEvent) => {
                if (!dropdownOptionsRef.current?.contains(event.target as Node) && !(dropdownButton.current?.contains(event.target as Node))) {
                    setOpen(false)
                }
            };

            // Attach the event listener to the window or a parent element
            window.addEventListener('click', handleClick);

            // Clean up the event listener when the component unmounts
            return () => window.removeEventListener('click', handleClick);

        }, [])


        useEffect(() => {
            // Calculate the position of the dropdown button
            if (dropdownButton.current && dropdownOptionsRef.current) {
                const buttonRect = dropdownButton.current.getBoundingClientRect();
                const windowHeight = window.innerHeight;

                // Check if there is enough space below the button with a little extra space added, otherwise display above
                if (windowHeight - buttonRect.bottom - 30 < dropdownOptionsRef.current.clientHeight) {
                    dropdownOptionsRef.current.style.bottom = '100%'; // Display above the button
                } else {
                    dropdownOptionsRef.current.style.bottom = 'auto'; // Display below the button                
                }
            }
        }, [open]);


        let options: JSX.Element[] = []
        props.options.forEach((option) => {
            options.push(
                <a key={`dropdownOption${option.name}`} onClick={option.action} className="cursor-pointer block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white">
                    {option.name}
                </a>
            )
        })

        return <>{options}</>
    }

    return (
        <>
            {/* dropdown button */}
            <div className=" relative flex  justify-center" ref={dropdownButton} >
                <img src={ellipsis} alt="options" onClick={() => setOpen(!open)} className="w-4 h-4 relative z-10 cursor-pointer bg-white focus:outline-none" />
                {/* Dropdown options */}
                {open && (
                    <div
                        className="absolute right-0 my-6 py-2 w-48 bg-white rounded-md shadow-xl z-20"
                        ref={dropdownOptionsRef}>
                        <DropdownOptions />
                    </div>
                )}
            </div>


        </>
    )
}