import { useEffect, useState } from "react";
import InventoryReport from "./AnalyticsReport/InventoryReport";
import BusinessPlanReport from "./AnalyticsReport/BusinessPlanReport";
import GrossProfitReport from "./AnalyticsReport/GrossProfitReport/GrossProfitReport";
import MonthlyPerformanceReport from "./AnalyticsReport/MonthlyPerformanceReport/MonthlyPerformanceReport";
import HouseHistory, { option } from "./AnalyticsReport/HouseHistoryReport/HouseHistory";
import SelectField from "../../components/atoms/forms/SimpleFields/SelectField";
import APIRequest from "../../helpers/CreateRequest";
import Button from "../../components/atoms/Button";
import ProjectedPerformanceReport from "./AnalyticsReport/ProjectedPerformanceReport/ProjectedPerformanceReport";

const ACTUALPERFORMANCE = 0;
const PROJECTEDPERFORMANCE = 1;
const MONTHLYPERFORMANCE = 2;
const INVENTORY = 3;
const GROSSPROFIT = 4;
const BUSINESSPLAN = 5;
const HOUSEHISTORY = 6;

type ReportIndexType = 0 | 1 | 2 | 3 | 4 | 5 | 6

export default function AppMain(): JSX.Element {
  const [form, setForm] = useState({ ScenarioID: '' })
  const [selectedTab, setSelectedTab] = useState<ReportIndexType>(ACTUALPERFORMANCE)
  const [scenarioOptions, setScenarioOptions] = useState<option[]>([])
  const [refreshing, setRefreshing] = useState(false)
  const [triggerNewData, setTriggerNewData] = useState(false)

  // TODO : add triggeers to chldrten to do something if refresh is complete ( usestate eatching refreshing state and only dies something if it is false (meaning complete))

  const handleRefreshBtn = async () => {
    try {
      setRefreshing(true)
      const result = await new APIRequest('/scenariosSalesForecastEvents/generate', 'POST', null, { scenarioID: form.ScenarioID }).GenerateRequest()

      if (result.status === 200) {
        const body = await result.json()
        if (body.status === 200) {
          // TODO: add success message
        } else throw body.message
      } else throw result.statusText

    } catch (err) {
      console.log(err)
    } finally {
      setRefreshing(false)
      setTriggerNewData(!triggerNewData)
    }

  }

  useEffect(() => {
    async function GetScenarios() {
      const tempOptions: option[] = []
      try {
        const result = await (new APIRequest(`/scenarios/names`, 'GET', null, null).GenerateRequest())
        if (result.status !== 200) throw result.statusText
        const body = await result.json()
        if (body.status !== 200) throw body.message
        const recordset: any[] = body.recordset || []
        if (recordset.length <= 0) throw 'No scenarios found'
        for (let i = 0; i < recordset.length; i++) {
          tempOptions.push({ name: recordset[i].ScenarioNameCode, value: recordset[i].ScenarioID })
        }

        setScenarioOptions(tempOptions)
      } catch (err) {
        console.log(err)
      }
    }
    GetScenarios()
  }, [])

  return (
    <>
      <div className='width-full flex flex-col h-full m-auto py-3 pb-0 overflow-auto'>
        <header className="py-3 px-4 mx-5 flex bep-shadow-xl rounded-lg ">
          <SelectField name="ScenarioID" label="Scenario" value={form.ScenarioID} setValue={setForm} options={scenarioOptions} size="md" />
          <span className="m-auto mr-0">
            <Button label="Refresh" styleType='tonal' type='button' action={handleRefreshBtn} disabled={refreshing} />
          </span>
        </header>
        {/* content */}
        <div className="flex flex-grow w-full  py-5 px-5" >
          {
            selectedTab === ACTUALPERFORMANCE ? <></> :
              selectedTab === PROJECTEDPERFORMANCE ? <ProjectedPerformanceReport ScenarioID={form.ScenarioID} triggerNewData={triggerNewData} /> :
                selectedTab === MONTHLYPERFORMANCE ? <MonthlyPerformanceReport ScenarioID={form.ScenarioID} triggerNewData={triggerNewData} /> :
                  selectedTab === INVENTORY ? <InventoryReport ScenarioID={form.ScenarioID} triggerNewData={triggerNewData} /> :
                    selectedTab === GROSSPROFIT ? <GrossProfitReport ScenarioID={form.ScenarioID} triggerNewData={triggerNewData} /> :
                      selectedTab === BUSINESSPLAN ? <BusinessPlanReport ScenarioID={form.ScenarioID} triggerNewData={triggerNewData} /> :
                        selectedTab === HOUSEHISTORY ? <><HouseHistory ScenarioID={form.ScenarioID} triggerNewData={triggerNewData} /></> : <div>Error</div>
          }
        </div>

        {/* tabs */}
        <div className=" w-full  bg-md-background grid grid-cols-7 grid-rows-1 px-10 max-w-[1600px] m-auto body-medium">
          <Tab name="Actual Performance" index={ACTUALPERFORMANCE} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          <Tab name="Projected Performance" index={PROJECTEDPERFORMANCE} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          <Tab name="Monthly Performance" index={MONTHLYPERFORMANCE} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          <Tab name="Inventory" index={INVENTORY} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          <Tab name="Gross Profit" index={GROSSPROFIT} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          <Tab name="Business Plan" index={BUSINESSPLAN} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          <Tab name="House History" index={HOUSEHISTORY} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        </div>
      </div>
    </>
  );
}



function Tab({ name, index, selectedTab, setSelectedTab }: { name: string, index: ReportIndexType, selectedTab: ReportIndexType, setSelectedTab: React.Dispatch<React.SetStateAction<ReportIndexType>> }) {
  return (
    <div className={`text-center cursor-pointer border border-b-0 border-t-0 first:border-l-2 last:border-r-2 p-1  ${index === selectedTab ? 'shadow-[inset_0px_-2px_0px_0px_var(--md-ref-palette-primary70)] font-semibold bg-[--md-ref-palette-primary98]' : ''}`} onClick={() => setSelectedTab(index)}>
      {name}
    </div>
  )
}