import { useEffect } from "react"
import { UseFormRegisterReturn, UseFormSetValue } from "react-hook-form"


export type BaseFieldType = {
    name: string,
    label: string,
    type?: string,
    supportingText?: string,
    disabled?: boolean,
    required?: boolean,
    value: string | undefined,
    formRef: UseFormRegisterReturn<string>
    setValue: UseFormSetValue<any>
    isFocused: boolean
    setIsFocused: React.Dispatch<React.SetStateAction<boolean>>
    tempValue: string | undefined,
    setTempValue: React.Dispatch<React.SetStateAction<string>>
    hidden?: boolean
    readonly?: boolean

    handleChange: React.ChangeEventHandler<HTMLInputElement>
    handleKeyDown: React.KeyboardEventHandler<HTMLInputElement>
    handleFocus: React.FocusEventHandler<HTMLInputElement>
    handleBlur: React.FocusEventHandler<HTMLInputElement>

    trailingIcon?: JSX.Element
}

export default function BaseField({
    name, label, type, supportingText, disabled, required, value, formRef, setValue, hidden, readonly,
    isFocused, setIsFocused, tempValue, setTempValue, handleChange, handleFocus, handleKeyDown, handleBlur,
    trailingIcon
}: BaseFieldType) {

    useEffect(() => {
        setTempValue(value || '');
        setValue(name, value || '')
    }, [value, disabled])

    return (
        <div key={`${name}${label}Input`} hidden={hidden}>
            <div className="relative">
                {/* real input */}
                <input ref={formRef.ref} hidden />

                {/* visual input */}
                <input
                    type={type === 'date' ? 'date' : 'text'}
                    disabled={disabled}
                    required={required}
                    className={`h-[40px] w-[200px] rounded-[2px] bg-md-background outline-none outline-[1.5px] text-md-on-surface body-large ${trailingIcon ? 'pr-[32px]' : 'pr-[16px]'} pl-[14px] py-[16px] transition-opacity duration-300
                    ${disabled ? '  outline-gray-300 dark:outline-gray-300 text-opacity-[.38] cursor-default' : ' outline-md-outline cursor-text '}
                    ${isFocused ? ' outline-[2.5px]   outline-md-primary text-md-on-surface' : ''}
                     ${!disabled && !isFocused ? 'hover:outline-md-on-surface hover:text-md-on-surface' : ''}
                     ${type === 'int' || type === 'currency' || type === 'number' || type === 'percent' || type === 'date' ? 'text-right' : ''}
                      ${!isFocused && !value && (disabled || !tempValue) ? ' text-transparent  text-opacity-0  ' : ' '}
                    `}
                    onKeyDown={handleKeyDown}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    value={tempValue}
                    onBlur={handleBlur}
                    readOnly={readonly}

                />

                {trailingIcon && (
                    <span className={`absolute pointer-events-none cursor-default flex items-center top-[8px] left-[172px] body-large transition-opacity duration-300
                 ${isFocused || (disabled && (value)) || tempValue ? 'opacity-100' : 'opacity-0'}
                 ${disabled && (isFocused || (disabled && (value)) || tempValue) ? ` ${!value ? 'opacity-[.38]' : ''}` : ''}
                 `}
                    >
                        {trailingIcon}
                    </span>
                )}

                {/* label */}
                <label
                    htmlFor={name}
                    className={`absolute flex items-center transition-all duration-300 h-[16px] pointer-events-none z-10 text-nowrap
                    ${isFocused || (disabled && value) || tempValue ? ` ${isFocused ? 'text-md-primary' : ''} top-[-12px] bg-md-background px-1 left-[4px] body-large ` : 'top-[12px] left-[16px] text-md-on-surface-variant bg-transparent'}
                    ${required ? "after:content-['*'] after:ml.5" : ''}
                    ${disabled ? ` ${!value ? 'opacity-[.38]' : ''} text-md-on-surface body-large` : ''}
                     ${!disabled && !isFocused ? 'text-opacity-100 hover:text-md-on-surface body-large' : ''}
                    `}
                >
                    {label}
                </label>
            </div>

            {/* supporting text */}
            <div className={`font-sans text-[12px] tracking-[0.3px] pt-[4px] px-[16px] 
            ${disabled ? ' text-md-on-surface opacity-[.38]' : 'text-md-on-surface-variant hover:text-md-on-surface-variant'}
            ${isFocused ? ' text-md-on-surface-variant' : ''}
            `}>
                {supportingText}
            </div>

        </div>
    )
}