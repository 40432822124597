import BaseField, { BaseFieldProps } from "./BaseField";

interface NumberFieldProps extends BaseFieldProps {
    max?: number;
    min?: number;
}

export default function NumberField(props: NumberFieldProps) {

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const EXCEPTION = e.ctrlKey || e.altKey || e.metaKey || e.key === 'Backspace' || e.key === 'Delete' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Tab' || (e.key === '.' && !e.currentTarget.value.includes('.'))
        const exceedsMax = props.max && (Number(e.currentTarget.value + e.key) > props.max)
        const exceedsMin = props.min && (Number(e.currentTarget.value + e.key) < props.min)

        //only digits and the excepts above will work
        if ((!/^\d$/.test(e.key) && !EXCEPTION) || e.key === ' ' || exceedsMax || exceedsMin) {
            e.preventDefault()
        }
    }

    return (
        <BaseField {...props} type="number" onKeydown={handleKeyDown} />
    );
}
