import { useEffect, useState } from "react"
import APIRequest from "../../helpers/CreateRequest"
import { FieldValues, useForm } from "react-hook-form"
import Button from "../../components/atoms/Button"
import { Currency } from "../../helpers/InputValueConverter"

type TableStructure = Map<
    string,
    {
        CommunityNameCode: string,
        HouseCode: string,
        HouseID: string,
        SaleCostEstimated: number,
        SalePriceEstimated: number,
        ConstructionStartCostEstimated: number,
        ConstructionStartSalePriceEstimated: number,
        [name: string]: any
    }[]
>

type HouseBackupType = Map<string, {
    CommunityNameCode: string,
    HouseCode: string,
    HouseID: string,
    SaleCostEstimated: number,
    SalePriceEstimated: number,
    ConstructionStartCostEstimated: number,
    ConstructionStartSalePriceEstimated: number,
    [name: string]: any
}>

//array of community ids and the table contains a map where communityID gives an array of house data
type dataType = {
    keys: { key: string, CommunityNameCode: string }[],
    table: TableStructure
}

export default function HousesBacklog() {
    const [data, setData] = useState<dataType>()
    const [shownData, setShownData] = useState(new Map<string, boolean>())
    const [housesBackup, setHousesBackup] = useState<HouseBackupType>(new Map())
    const [submission, setSubmission] = useState<{ isSubmitting: boolean, unitsToSubmit?: number, unitsSubmitted?: number, unitsSuccessful?: number, progress: number }>({ isSubmitting: false, progress: 0 })

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting, isLoading },
        reset,
        getValues,
        setValue
    } = useForm()

    async function GetCommunitiesAndHousesBacklog() {
        try {
            const result = await (new APIRequest('/houses/communitiesAndHousesBacklog', 'GET', null, null).GenerateRequest())
            if (result.status === 200) {
                const body = await result.json()
                const recordset = body.recordset
                const tempTableData: dataType = { keys: [], table: new Map() }
                const tempHouseBackup: HouseBackupType = new Map()

                for (let i = 0; i < recordset.length; i++) {
                    const tempTableDataValue = tempTableData.table.get(recordset[i].CommunityID)
                    //if id has been used, add new data to existing array. Else add it as a new entry and place the key inside the keys array
                    if (tempTableDataValue) {
                        tempTableDataValue.push({ ...recordset[i] })
                    } else {
                        tempTableData.table.set(recordset[i].CommunityID, [{ ...recordset[i] }])
                        tempTableData.keys.push({ key: recordset[i].CommunityID, CommunityNameCode: recordset[i].CommunityNameCode })
                    }
                    tempHouseBackup.set(recordset[i].HouseID, { ...recordset[i] })

                }

                setData(tempTableData)
                setHousesBackup(tempHouseBackup)
                console.log(tempTableData)
            } else {
                console.log(result)
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        GetCommunitiesAndHousesBacklog()
    }, [])

    const handleShowData = (key: string) => {
        if (shownData.delete(key)) {
            setShownData(new Map(shownData))
        } else {
            setShownData(new Map(shownData.set(key, true)))
        }
    }

    const onSubmit = async (e: FieldValues) => {
        setSubmission({ isSubmitting: true, progress: 0 })
        // if there is no original data (you shouldnt be able to save but just to clean up code)
        if (!data) return

        //set up array of houses to change
        const housesToUpdate: {
            id: string,
            SaleCostEstimated?: number,
            SalePriceEstimated?: number,
            ConstructionStartCostEstimated?: number,
            ConstructionStartSalePriceEstimated?: number,
            [name: string]: any
        }[] = []

        //init map of grouped houses
        const houses = new Map<string, {
            SaleCostEstimated?: number,
            SalePriceEstimated?: number,
            ConstructionStartCostEstimated?: number,
            ConstructionStartSalePriceEstimated?: number,
            [name: string]: any
        }
        >()

        //loop through field values and add to grouped houses array
        for (const name in e) {
            const id = name.split('_')[0]
            const dataName = name.split('_')[1]
            // set the id as the id, and set the field values to be whatever they were beforew with the new data coming in
            const house = houses.get(id)
            //get value and remove all extra $ and ,
            let value = e[name]
            value = value.replaceAll('$', '')
            value = value.replaceAll(',', '')
            value = value === 'NaN' ? '' : value
            value = value === '' ? undefined : Number(value)

            //if house exists add new value with existing
            if (house) {
                houses.set(id, { ...house, [dataName]: value })
            } else {
                houses.set(id, { [dataName]: value })
            }
        }

        //compare each key here with its matching from the original db call. if anything is different, then add that group to the array of houses to be updated
        const keys = houses.keys()

        for (const key of keys) {
            const originalValues = housesBackup.get(key)
            const newValues = houses.get(key)
            if (originalValues && newValues) {
                let didChange = false
                //loop through each name in the neaValues...
                for (const name in newValues) {
                    const newValue = newValues[name]
                    const originalValue = originalValues[name] === null || originalValues[name] === undefined ? undefined : originalValues[name]

                    if (newValue !== originalValue) {
                        didChange = true
                        break;
                    }
                }
                if (didChange) housesToUpdate.push({ id: key, ...newValues })
            }
        }

        setSubmission(prev => { return { ...prev, unitsToSubmit: housesToUpdate.length, unitsSubmitted: 0 } })

        // now call api for each house to update
        let didFail = 0
        for (let i = 0; i < housesToUpdate.length; i++) {
            try {
                const result = await (new APIRequest('/housesBacklog/', 'PUT', null, { ...housesToUpdate[i] }).GenerateRequest())
                if (result.status === 200) {
                    const body = await result.json()
                    if (body.status !== 200) {
                        didFail++
                    }

                    setSubmission(prev => { return { ...prev, unitsSubmitted: i + 1, progress: i + 1 / (housesToUpdate.length) } })
                }
            } catch (err) {
                console.log(err)
                didFail++
            }
        }

        await GetCommunitiesAndHousesBacklog()

        setSubmission({ isSubmitting: false, progress: 0 })
    }


    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setValue(e.target.name, Currency(e.target.value || ''))
    }

    // This only works since all are curreny fields
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const EXCEPTION = e.ctrlKey || e.altKey || e.metaKey || e.key === 'Backspace' || e.key === 'Delete' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Tab' || (e.key === '.' && !e.currentTarget.value.includes('.'))
        const DECIMALINVALID = e.key === '.' ? (e.currentTarget.value.includes('.') ? (e.currentTarget.value.split('.')[1].split('').length + 1 === 3 ? true : false) : false) : false

        //only digits and the exceptions above will work
        if (!/^\d$/.test(e.key) && (!EXCEPTION || e.key === ' ') || (DECIMALINVALID && !EXCEPTION)) {
            e.preventDefault()
        }
    }


    return (
        <>
            <div className="">
                <div className="py-10 px-20 font-bold text-xl mb-[10px]">
                    <span>Home Building</span>
                    <span> {">"} </span>
                    <span>Backlog</span>
                </div>

                {/* table headers */}
                <div className="text-center border-b  body-large">
                    <div className="grid grid-cols-12 text-center items-center">
                        <span className="col-span-1"></span>
                        <span className="border-b border-r col-span-1">Community</span>
                        <span className="col-span-4 border-b border-x">Time of Sale</span>
                        <span className="col-span-4 border-b border-l">Time of Start</span>
                        <span className="col-span-2"></span>

                        <span className="col-span-1"></span>
                        <span className="col-span-1 border-r">House Code</span>
                        <span className="col-span-2 border-x">Sale Price</span>
                        <span className="col-span-2 border-x">Cost</span>
                        <span className="col-span-2 border-x">Sale Price</span>
                        <span className="col-span-2 border-x">Cost</span>
                        <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 gap-x-3 m-auto col-span-2">
                            <Button label="Save" styleType="tonal" action={() => null} type="submit" disabled={!isDirty} />
                        </form>
                    </div>
                </div>


                {/* Table body */}
                <div className=" text-center  body-large">
                    {
                        data && data?.keys.length > 0 ?

                            data.keys.map(key => {
                                const houses = data.table.get(key.key)

                                return (
                                    <div key={`community${key.CommunityNameCode}${key.key}`}>
                                        <div className="border p-3 grid grid-cols-12 cursor-pointer" onClick={() => handleShowData(key.key)}>
                                            <span className="">
                                                <svg className={`flex m-auto transition-transform ${shownData.get(key.key) ? 'rotate-180' : ''} `} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" /></svg>
                                            </span>
                                            <span className="col-span-11 text-start">{key.CommunityNameCode}</span>
                                        </div>
                                        {
                                            houses ?
                                                <div className={`grid text-center transition-all ${shownData.get(key.key) ? `grid-rows-[1fr]` : 'grid-rows-[0fr]'}`}>
                                                    <div className="overflow-hidden">
                                                        {
                                                            houses.map(house => (
                                                                <form key={`house${house.CommunityNameCode}${house.HouseID}`} className={`grid grid-cols-12 border`} >
                                                                    <span className="col-span-1"></span>
                                                                    <span className="border-r  items-center flex justify-center">{house.HouseCode}</span>
                                                                    <input {...register(`${house.HouseID}_SalePriceEstimated`)} onKeyDown={handleKeyDown} onBlur={handleBlur} className="border-x hover:bg-md-primary-container outline-none p-1 col-span-2 text-right" defaultValue={house.SalePriceEstimated || house.SalePriceEstimated === 0 ? Currency(String(house.SalePriceEstimated)) : undefined} />
                                                                    <input {...register(`${house.HouseID}_SaleCostEstimated`)} onKeyDown={handleKeyDown} onBlur={handleBlur} className="border-x hover:bg-md-primary-container outline-none p-1 col-span-2 text-right" defaultValue={house.SaleCostEstimated || house.SaleCostEstimated === 0 ? Currency(String(house.SaleCostEstimated)) : undefined} />
                                                                    <input {...register(`${house.HouseID}_ConstructionStartSalePriceEstimated`)} onKeyDown={handleKeyDown} onBlur={handleBlur} className="border-x hover:bg-md-primary-container outline-none p-1 col-span-2 text-right" defaultValue={house.ConstructionStartSalePriceEstimated || house.ConstructionStartSalePriceEstimated === 0 ? Currency(String(house.ConstructionStartSalePriceEstimated)) : undefined} />
                                                                    <input {...register(`${house.HouseID}_ConstructionStartCostEstimated`)} onKeyDown={handleKeyDown} onBlur={handleBlur} className="border-x hover:bg-md-primary-container outline-none p-1 col-span-2 text-right" defaultValue={house.ConstructionStartCostEstimated || house.ConstructionStartCostEstimated === 0 ? Currency(String(house.ConstructionStartCostEstimated)) : undefined} />
                                                                    <span></span>
                                                                </form>
                                                            ))
                                                        }
                                                    </div>
                                                </div>

                                                :

                                                <span>No houses in backlog</span>
                                        }
                                    </div>
                                )
                            })

                            :

                            <span className="col-span-6">No data found</span>
                    }
                </div>
            </div>

            {/* Loading screen */}
            {
                submission.isSubmitting &&
                <div >

                    {/* blur */}
                    <div className="bg-gray-400 bg-opacity-60 fixed w-full h-full top-0 left-0"></div>

                    {/* container */}
                    <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-md-secondary-container w-[400px] h-[120px] rounded-3xl">
                        {/* progress bar */}

                        <div className="flex h-[70px]">
                            <progress value={submission.progress} className="rounded-full w-[300px] m-auto flex items-center transition-all " />
                        </div>


                        {/* feedback */}
                        <div className="flex m-auto w-full">
                            {

                                submission.unitsSubmitted && submission.unitsToSubmit ?

                                    <div className="m-auto flex">{submission.unitsSubmitted} of {submission.unitsToSubmit} completed</div>

                                    :

                                    <div className="m-auto flex">Calculating...</div>
                            }
                        </div>
                    </div>

                </div>
            }

        </>
    )
}