import { useEffect, useMemo, useState } from "react";
import CRUD from "../../templates/CRUD";
import { FieldValues } from "react-hook-form";
import GetObj from "../../helpers/GetObj";
import { field, mode } from "../../Types/FormTypes";
import SelectSalesPlan from "./SelectSalesPlan";
import { useLocation } from "react-router-dom";
import SelectBudgets from "./SelectBudgets";
import SelectSalesForecasts from "./SelectSalesForecasts";

const name = {
    normal: 'Scenario',
    plural: 'Scenarios',
    alias: 'Scenario',
    aliasPlural: 'Scenarios',
    data: 'Scenario',
    dataPlural: 'Scenarios',
    sectionTitle: 'Forecasting'
}

const fields: field[] = [
    { name: 'ScenarioName', alias: 'Name', sendAs: 'Name', type: 'text', required: true, size: 'md' },
    { name: 'Description', type: 'text', size: 'md', },
    { name: 'Code', type: 'text', required: true, size: 'md' },
    { name: 'DivisionID', type: 'select', SelectElement: { name: 'Division', namePlural: 'Divisions' }, required: true, size: 'md', alias: 'Division' },
]

export default function Scenarios() {
    const location = useLocation()
    const stateID = location.state?.id || undefined
    const [obj, setObj] = useState<any>()
    const [objID, setObjID] = useState(stateID)
    const [mode, setMode] = useState<mode>('view')
    const [refresh, setRefresh] = useState(false)

    const emptySet: FieldValues = useMemo(() => Object.fromEntries(fields.map(field => [field.sendAs || field.name, null])), [])

    //Get object when the id changes or a refresh is called 
    useEffect(() => {
        const getObj = async () => {
            if (objID) {
                const obj = await GetObj(objID, name.dataPlural, fields)
                if (obj !== -1) {
                    setObj(obj)
                    setMode('view')
                }
            }
        }
        getObj()
    }, [objID, refresh])


    //useeffect to get sales plan and budget nfo when obj/iD exist and/or changes 

    return (
        <>
            <div className="py-10 px-10 h-full overflow-y-auto">

                <CRUD
                    name={name}
                    fields={fields}
                    obj={obj}
                    id={objID}
                    setID={setObjID}
                    mode={mode}
                    setMode={setMode}
                    emptySet={emptySet}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    extraButtons={[]}
                />

                {/* Details or sections */}
                <div className="w-full m-auto">

                    {/* sales plan info */}
                    <div>
                        <SelectSalesPlan parentID={objID} />
                    </div>

                    {/* sales forecast info */}
                    <div>
                        <SelectSalesForecasts parentID={objID} />
                    </div>

                    {/* budgets info */}
                    <div>
                        <SelectBudgets parentID={objID} />
                    </div>

                </div>
            </div>
        </>
    )
}