import { useEffect, useRef, useState } from "react"


type SnackBarType = {
    message: {message: string, refresh: boolean},
    action?: Function
    closeBtn?: boolean
}

//action and close button not implemented

export default function SnackBar({ message, action, closeBtn }: SnackBarType) {
    const [firstRender, setFirstRender] = useState(true)
    const [visible, setVisible] = useState(false)

    useEffect(()=>{
        if(firstRender){
            setFirstRender(false)
        }else{
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
            }, 4000);
        }
    }, [message])


    return (
        // container
        <div className={`absolute bottom-[30px] right-[calc(50%_-_240px)]  h-[36px] min-w-[200px]  max-w-full px-4 py-1 transition-opacity duration-500
        bg-md-inverse-surface shadow-md rounded-sm
        
        ${visible ? 'opacity-100 z-50' : 'opacity-0 -z-50'}`} >
            {/* Label */}
            <span className="label-large text-md-inverse-primary m-auto ml-0">{message.message}</span>

            {/* action */}
            {
                action &&
                <span>

                </span>
            }

            {/* Close Button */}
            {
                closeBtn &&
                <button>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg>
                </button>
            }
        </div>
    )
}