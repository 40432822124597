import { useEffect, useState } from "react";
import CRUD from "../../templates/CRUD";
import { FieldValues } from "react-hook-form";
import GetObj from "../../helpers/GetObj";
import { field, mode } from "../../Types/FormTypes";

const name = {
    normal: 'Development',
    plural: 'Developments',
    alias: 'Development',
    aliasPlural: 'Developments',
    data: 'Development',
    dataPlural: 'Developments',
    sectionTitle: 'Real Estate'
}

const fields: field[] = [
    { name: 'DevelopmentName', alias: 'Name', sendAs: 'Name', type: 'text', required: true, size: 'md' },
    { name: 'Description', type: 'text', size: 'md', },
    { name: 'Code', type: 'text', required: true, size: 'md' },
    { name: 'DivisionID', type: 'select', SelectElement: { name: 'Division', namePlural: 'Divisions' }, required: true, size: 'md', alias: 'Division' },
]

export default function Developments2() {
    const [obj, setObj] = useState<any>()
    const [objID, setObjID] = useState()
    const [mode, setMode] = useState<mode>('view')
    const [refresh, setRefresh] = useState(false)

    const emptySet: FieldValues = Object.fromEntries(fields.map(field => [field.sendAs || field.name, null]));

    //Get object when the id changes or a refresh is called 
    useEffect(() => {
        const getObj = async () => {
            if (objID) {
                const obj = await GetObj(objID, name.dataPlural, fields)
                if (obj !== -1) {
                    setObj(obj)
                    setMode('view')
                }
            }
        }
        getObj()
    }, [objID, refresh])

    return (
        <>
            <div className="py-10 px-10 h-full overflow-y-auto">

                <CRUD
                    name={name}
                    fields={fields}
                    obj={obj}
                    id={objID}
                    setID={setObjID}
                    mode={mode}
                    setMode={setMode}
                    emptySet={emptySet}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    extraButtons={[]}
                />

                <div className="flex-grow w-full m-auto">
                </div>

            </div>
        </>
    )
}