import TextField from "../../components/atoms/forms/SimpleFields/TextField";
import { useEffect } from "react";
import { BaseFieldProps } from "../../components/atoms/forms/SimpleFields/BaseField";

export default function DateTimeField(props: BaseFieldProps) {

    const setNewDateTimeString = () => {
        if (props.value?.endsWith('M') || !props.value) { }
        else if (props.value) {
            const fullDate = new Date(props.value).toISOString()
            if (fullDate) {
                const date = new Date(fullDate).toLocaleDateString().split('T')[0]

                const time = fullDate.split('T')[1].split('.')[0].split(':')
                const hour = Number(time[0])
                const minutes = time[1]
                const seconds = time[2]
                const convertedHour = hour - 12 <= 0 ? hour === 0 ? 12 : hour : hour - 12

                const meridiem = hour < 12 ? 'AM' : 'PM'

                props.setValue((prev: any) => ({...prev, [props.name]: `${date} ${convertedHour}:${minutes}:${seconds} ${meridiem}`} ))
            }
        } else props.setValue((prev: any) => ({...prev, [props.name]: ''} ))
    }

    useEffect(() => setNewDateTimeString(), [props.value])

    return (
        <TextField value={props.value?.endsWith('M') ? props.value : ''} {...props} />
    )
}