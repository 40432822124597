
import APIRequest from "../../helpers/CreateRequest";
import GenerateBusinessUnitReport from "./GenerateBusinessUnitReport";
import { useEffect, useMemo, useState } from "react";
import CRUD from "../../templates/CRUD";
import { FieldValues } from "react-hook-form";
import GetObj from "../../helpers/GetObj";
import { field, mode } from "../../Types/FormTypes";
import { useLocation } from "react-router-dom";


const name = {
    normal: 'BusinessUnit',
    plural: 'BusinessUnits',
    alias: 'Business Unit',
    aliasPlural: 'Business Units',
    data: 'BusinessUnit',
    dataPlural: 'BusinessUnits',
    sectionTitle: 'Organization Settings'
}

const fields: field[] = [
    { name: 'Name', alias: 'Name', sendAs: 'Name', type: 'text', required: true, size: 'md' },
    { name: 'Description', type: 'text', size: 'md', },
    { name: 'Code', type: 'text', required: true, size: 'md' },
]

export type BusinessUnitReport = {
    BusinessUnitNameCode: string,
    DivisionNameCode: string
    DepartmentNameCode: string
    PositionNameCode: string
    RoleNameCode: string
    ProcedureName: string
}

export default function BusinessUnits() {
    const location = useLocation()
    const stateID = location.state?.id || undefined
    const [obj, setObj] = useState<any>()
    const [objID, setObjID] = useState(stateID)
    const [mode, setMode] = useState<mode>('view')
    const [refresh, setRefresh] = useState(false)

    const emptySet: FieldValues = useMemo(() => Object.fromEntries(fields.map(field => [field.sendAs || field.name, null])), [])

    //////////////////////////////////////////////////////////////////
    // Generates a report down to procedures for all business units //
    //////////////////////////////////////////////////////////////////
    async function FullBusinessUnitReport() {
        try {
            const result = await (new APIRequest('/procedures/businessUnit', 'POST', null, { id: obj.BusinessUnitID }).GenerateRequest())
            const recordset: BusinessUnitReport[] = await result.json().then(res => res.recordset)
            const title = "Business Unit Procedures"
            const colNames = [['Procedure Name']]
            GenerateBusinessUnitReport(title, colNames, recordset)
        } catch (err) {
            console.log(err)
        }

    }

    //Get object when the id changes or a refresh is called 
    useEffect(() => {
        const getObj = async () => {
            if (objID) {
                const obj = await GetObj(objID, name.dataPlural, fields)
                if (obj !== -1) {
                    setObj(obj)
                    setMode('view')
                }
            }
        }
        getObj()
    }, [objID, refresh])


    return (
        <>
            <div className="py-10 px-10 h-full overflow-y-auto">

                <CRUD
                    name={name}
                    fields={fields}
                    obj={obj}
                    id={objID}
                    setID={setObjID}
                    mode={mode}
                    setMode={setMode}
                    emptySet={emptySet}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    extraButtons={[]}
                />

                {/* Sections */}
                <div className="flex-grow w-full m-auto">
                    {obj && obj.BusinessUnitID ?

                        <button className="formBtn bg-gray-300" onClick={FullBusinessUnitReport}>Generate Report</button>

                        :
                        <></>
                    }
                </div>
            </div>
        </>

    )
}



