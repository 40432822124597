import { ReactNode, useEffect, useRef } from "react";


export default function Modal(props: { className: string, onClose: () => void, children: ReactNode }) {
    const notModalRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        //If the clicked element is the modal or inside the modal, then close
        const handleClick = (event: MouseEvent) => {
            if (notModalRef.current?.contains(event.target as Node)) { props.onClose() }
        };

        // Attach the event listener to the window or a parent element
        window.addEventListener('click', handleClick);

        // Clean up the event listener when the component unmounts
        return () => window.removeEventListener('click', handleClick);

    }, [])

    return (
        <div className="fixed inset-0 flex items-center justify-center z-40">
            <div className="fixed inset-0 bg-black opacity-50" ref={notModalRef}>

            </div>
            <div className={props.className + ' z-50'}>
                {props.children}
            </div>
        </div>
    )
}