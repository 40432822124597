import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import SelectField from "../../components/atoms/forms/Fields/SelectField"
import { useEffect, useMemo, useState } from "react";
import APIRequest from "../../helpers/CreateRequest";

type CommunityIDType = {
    name: string,
    label: string,
    supportingText?: string,
    disabled?: boolean,
    required?: boolean,
    value: string | undefined,
    setValue: UseFormSetValue<any>
    fieldNames: {
        name: string;
        namePlural: string;
    }
    staticOptions?: {
        name: string,
        value: any,
    }[]
    watch?: UseFormWatch<any>
}

export default function CommunityID(props: CommunityIDType) {
    const [id, setID] = useState(props.value)
    const watchUnitTypeID = props.watch ? props.watch("UnitTypeID") : undefined
    const shouldDisable = useMemo(() => {
        if (props.disabled) return true
        else if (!watchUnitTypeID) return true
        else return false
    }, [props.disabled, watchUnitTypeID])


    const getUnitsRemaining = async () => {
        if (id && watchUnitTypeID) {
            try {
                const result = await new APIRequest('/communities/unitsRemaining', 'POST', null, { id: id, unitTypeID: watchUnitTypeID }).GenerateRequest()
                const body = await result.json()
                if (body.status === 200) {
                    const currentMonthUnits = Number(body.recordset[0].CurrentMonthActualSalesUnitCount)
                    props.setValue('UnitsRemainingOriginal', body.recordset[0].UnitsRemaining)
                    props.setValue('CurrentMonthActualSalesUnitCount', currentMonthUnits || undefined)

                } else {
                    console.error('Failed to get units remaining')
                }
            } catch (err) {
                console.log(err)
            }
        }
    }

    useEffect(() => { getUnitsRemaining() }, [id, watchUnitTypeID])

    useEffect(() => setID(props.value), [props.value])

    return (
        <SelectField {...props} supportingText={!watchUnitTypeID ? props.supportingText : undefined} setWrapperState={setID} disabled={shouldDisable} />
    )
}
