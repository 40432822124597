import { task } from "./Procedures"
import { field, mode } from "../../Types/FormTypes"
import TaskTable from "./TaskTable"
import ModifyTask from "./ModifyTask"
import { FieldValues } from "react-hook-form"

export const fields: field[] = [
    { name: "Description", type: "text", size: 'lg' },
    { name: "DurationMinutes", type: "number", size: 'sm' },
    { name: "PerformerRoleID", type: "select", size: 'sm', alias: 'Performed By' },
]

type TasksType = {
    procedureID?: string,
    task?: task,
    tasks?: task[],
    setTask: Function,
    refreshProcedure: boolean,
    setRefreshProcedure: Function,
    mode: mode,
    setMode: Function,
}

export default function Tasks(props: TasksType) {
    const emptySet: FieldValues = Object.fromEntries(fields.map(field => [field.sendAs || field.name, null]));

    return (
        <>{
            props.procedureID && props.tasks &&
            <>
                <TaskTable {...props} fields={fields} procedureID={props.procedureID} emptySet={emptySet} />

                <ModifyTask {...props} emptySet={emptySet} procedureID={props.procedureID} />
            </>
        }

        </>
    )
}