import Button from "../../components/atoms/Button"
import APIRequest from "../../helpers/CreateRequest"

export default function RefreshButton({ id, override, refreshData }: { id?: string, override: string, refreshData: Function }) {

    const handleClick = async () => {
        try {
            const result = await new APIRequest('/salesPlans/refresh', 'POST', null, { id: id }).GenerateRequest()
            if (result.status === 200) refreshData()
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <Button
            label="Restore"
            type="button"
            styleType="text"
            action={handleClick}
            disabled={!id}
        />
    )
}
