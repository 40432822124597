import { UseFormRegisterReturn, UseFormSetValue, UseFormWatch } from "react-hook-form"
import TextField from "../../components/atoms/forms/Fields/TextField"

type LastRefreshType = {
    name: string,
    label: string,
    supportingText?: string,
    disabled?: boolean,
    required?: boolean,
    value: string | undefined,
    formRef: UseFormRegisterReturn<string>
    setValue: UseFormSetValue<any>
}

export default function LastRefresh(props: LastRefreshType) {

    const setNewDateString = () => {

        if(props.value?.endsWith('M')){
            return props.value
        }
        else if (props.value) {
            const fullDate = new Date(props.value).toISOString()
            if(fullDate){
                const date = new Date(fullDate).toLocaleDateString().split('T')[0]

                const time = fullDate.split('T')[1].split('.')[0].split(':')
                const hour = Number(time[0])
                const minutes = time[1]
                const seconds = time[2]
                const convertedHour = hour - 12 <= 0 ? hour === 0 ? 12 :  hour : hour -  12
    
                const meridiem = hour < 12 ? 'AM' : 'PM'
    
                return (`${date} ${convertedHour}:${minutes}:${seconds} ${meridiem}`)
            }
        } 

        return undefined
    }


    return (
        <TextField
            {...props}
            value={setNewDateString()}
        />
    )
}