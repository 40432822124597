export function GetMonthByIndex(index: number) {
  const month =
    index === 0
      ? "January"
      : index === 1
      ? "February"
      : index === 2
      ? "March"
      : index === 3
      ? "April"
      : index === 4
      ? "May"
      : index === 5
      ? "June"
      : index === 6
      ? "July"
      : index === 7
      ? "August"
      : index === 8
      ? "September"
      : index === 9
      ? "October"
      : index === 10
      ? "November"
      : index === 11
      ? "December"
      : "invalid";

  return month;
}

export function GetMonthIndexByNameSubstring(name: string) {
  const index = name.toLowerCase().includes("january")
    ? 0
    : name.toLowerCase().includes("february")
    ? 1
    : name.toLowerCase().includes("march")
    ? 2
    : name.toLowerCase().includes("april")
    ? 3
    : name.toLowerCase().includes("may")
    ? 4
    : name.toLowerCase().includes("june")
    ? 5
    : name.toLowerCase().includes("july")
    ? 6
    : name.toLowerCase().includes("august")
    ? 7
    : name.toLowerCase().includes("september")
    ? 8
    : name.toLowerCase().includes("october")
    ? 9
    : name.toLowerCase().includes("november")
    ? 10
    : name.toLowerCase().includes("december")
    ? 11
    : -1;
  return index;
}

export function GetMonthIndexByName(month: string) {
  const mon = month.toLowerCase();
  const index =
    mon === "january"
      ? 0
      : mon === "february"
      ? 1
      : mon === "march"
      ? 2
      : mon === "april"
      ? 3
      : mon === "may"
      ? 4
      : mon === "june"
      ? 5
      : mon === "july"
      ? 6
      : mon === "august"
      ? 7
      : mon === "september"
      ? 8
      : mon === "october"
      ? 9
      : mon === "november"
      ? 10
      : mon === "december"
      ? 11
      : -1;

  return index;
}
