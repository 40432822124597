import { useEffect, useRef, useState } from "react"

type SearchType = {
    options?: { name: string, value: number }[],
    setID: Function
}

export default function Search({ options, setID }: SearchType) {
    const [value, setValue] = useState<string>('')
    const [isOpen, setIsOpen] = useState(false)
    const [filteredOptions, setFilteredOptions] = useState<JSX.Element[]>([])

    const search = useRef<HTMLDivElement | null>(null);
    const searchInput = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (options) {
            const filteredArray = options.filter(option => option.name.toLowerCase().includes(value.toLowerCase()));

            setFilteredOptions(
                filteredArray.map(option => (
                    <li
                        key={`dropdownOption${option.value}`}
                        onMouseDown={() => handleSelect(option)}
                        className="cursor-pointer w-full px-4 py-2 hover:bg-gray-300 break-words rounded-full text-md-on-surface body-large"
                    >
                        {option.name}
                    </li>
                ))
            );
        }
    }, [value, options]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        if (!(value.includes('?') || value.includes('*') || value.includes('~'))) setValue(e.target.value)
    }

    const handleSelect = (option: { name: string, value: number }) => {
        setValue(option.name)
        setID(option.value)
        setIsOpen(false)
    }

    const handleClose = () => {
        setValue('')
        searchInput.current?.focus()
    }

    const handleSearchFocus = () => {
        setIsOpen(true)
        searchInput.current?.focus()
    }

    const handleSearchBlur = (e: React.FocusEvent<HTMLDivElement, Element>) => {
        const inSearchBar = search.current?.contains(e.relatedTarget)
        if (inSearchBar) searchInput.current?.focus()
        else {
            setIsOpen(false)
            searchInput.current?.blur()
        }

    }


    return (
        <>
            <div onBlur={handleSearchBlur} tabIndex={-1} ref={search} className={`relative h-[56px] w-auto min-w-[360px] max-w-[720px] grid grid-cols-[56px_auto_56px] bg-md-surface-variant rounded-full 
           ${isOpen ? 'focus-within:rounded-t-2xl focus-within:rounded-b-none' : ''} `}
            >
                {/* leading icon */}
                <span className="px-[16px] m-auto cursor-pointer " onClick={handleSearchFocus}>
                    <svg xmlns="http://www.w3.org/2000/svg" className=" text-md-on-surface" height="24" viewBox="0 -960 960 960" width="24"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" /></svg>
                </span>

                {/* input field */}
                <input type="text"
                    onChange={handleSearch}
                    value={value}
                    placeholder="Search"
                    className={` bg-md-surface-variant outline-none text-md-on-surface body-large`}
                    onFocus={handleSearchFocus}
                    tabIndex={1}
                    ref={searchInput}
                />

                {/* trailing icon */}
                {((isOpen && value) || value) ? <span tabIndex={0} className="m-auto cursor-pointer px-[16px] opacity-40" >
                    <svg onMouseUp={handleClose} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="--md-ref-palette-neutral50"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                </span>

                    : <span></span>}


                {/* results */}
                {isOpen &&

                    <ul className='absolute col-span-2 top-full z-50 rounded-b-2xl bg-md-surface-variant w-full mt-0 max-h-52 overflow-auto  p-1 border-gray-700 text-md-on-surface body-large'>
                        {
                            options ?

                                filteredOptions.length > 0 ?

                                    filteredOptions

                                    :

                                    <li key={'dropdownOptionNoneMatch'} className="w-full px-4 py-2">
                                        No results found
                                    </li>


                                :

                                <li key={'dropdownOptionNoneMatch'} className="w-full px-4 py-2">
                                    Loading...
                                </li>
                        }
                    </ul>
                }
            </div >
        </>
    )
}

